import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'
import Icon from '../../elements/Icon.jsx';
import IntentSlideUpModal from '../../elements/experience/IntentSlideUpModal.jsx';
import MethodSlideUpModal from '../../elements/experience/MethodSlideUpModal.jsx';
import MeasureSlideUpModal from '../../elements/experience/MeasureSlideUpModal.jsx';

//import { actionSetNoScroll } from '../../redux/actions/main';

function mapStateToProps(state) {
  return {
    viewport: state.main.viewport,
    no_scroll: state.main.no_scroll,
    intents: state.experience.intents,
    measures: state.cultivar.measures,
  };
}

class ExperienceSubHeader extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      modal:null
    }

    this.resizeListener = null;
  }

  resize(){
    let modal = window.jQuery('main').offset();
    modal['width'] = window.jQuery('main').width()+10;
    modal['bottom'] = window.jQuery('#dock').height();
    window.jQuery('.modal-slideup').css({left:(modal.left+10)+"px",width:modal.width+"px",bottom:(modal.bottom+16)+"px"});

  }

  setIntent(id){
    this.props.parent.setIntent(id);
    this.closeModal();
  }

  setMethod(id){
    this.props.parent.setMethod(id);
    this.closeModal();
  }

  setMeasure(id,amount){
    this.props.parent.setMeasure(id,amount);
    this.closeModal();
  }

  showModal(which,e){
    //console.log(which)
    let that = this;
    this.setState({modal:which},function(){ that.resize() })
  }

  closeModal(){
    let that = this;
    this.setState({modal:null},function(){that.forceUpdate()})
  }

  componentDidMount(){
    this.resize();
    let that = this;
    window.addEventListener('resize',function(){ that.resize() });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.viewport !== this.props.viewport)
      this.resize();
  }

  componentWillUnmount(){
    let that = this;
    window.removeEventListener('resize',function(){ that.resize() });    
  }

  render() {
    return (
      <React.Fragment>
          <div className="row">
            <div className="col-4 text-center measure_icon_box">
              <div className="d-inline-flex justify-content-center align-items-center cursor-link" style={{height:"80px",marginTop:"-15px",marginBottom:"-15px"}} onClick={this.showModal.bind(this,'intent')}>
                { this.props.experience.intents && this.props.experience.intents.length > 0 

                  ?

                    <Icon icon={this.props.experience.intents[0].icon} title={this.props.experience.intents[0].name+" Intent"} transparent={true} tiny={true} size={60} />

                  :

                    <span>set intent</span>
                }
                </div>
            </div>
            <div className="col-4 text-center measure_icon_box">
              <div className="d-inline-flex justify-content-center align-items-center cursor-link" style={{height:"80px",marginTop:"-15px",marginBottom:"-15px"}} onClick={this.showModal.bind(this,'method')}>
                { this.props.experience.consumed[0].method 

                  ?

                    <Icon icon={this.props.experience.consumed[0].method.icon} title={this.props.experience.consumed[0].method.name+" Method"} transparent={true} tiny={true} size={60} />

                  :

                    <span>set method</span>
                }
              </div>
            </div>
            <div className="col-4 text-center measure_icon_box">
              <div className="d-inline-flex justify-content-center align-items-center cursor-link" style={{height:"80px",marginTop:"-15px",marginBottom:"-15px"}} onClick={this.showModal.bind(this,'measure')}>
                { this.props.experience.consumed[0].measure && this.props.experience.consumed[0].amount ? parseFloat(this.props.experience.consumed[0].amount)+" "+this.props.experience.consumed[0].measure[1].toUpperCase() : "set amount"}
              </div>
            </div>
          </div>
          { (!this.props.hideMoods && (this.props.experience.moods || this.props.experience.pairings)) &&
            <div className="my-1">
              { this.props.experience.moods &&

                  this.props.experience.moods.map(
                    (mood,i) => {  

                      return (
                        <span key={i} className="badge badge-info mr-2 my-1 p-1">{mood.name}</span>
                      );
                    }
                  )
              }

              { this.props.experience.pairings &&

                  this.props.experience.pairings.map(
                    (pairing,i) => {  

                      return (
                        <span key={i} className="badge badge-success mr-2 my-1 p-1">{pairing.name}</span>
                      );
                    }
                  )
              }
            </div>
          }

          { this.state.modal && this.state.modal === 'intent' &&
            <IntentSlideUpModal parent={this} experience={this.props.experience} />
          }
          { this.state.modal && this.state.modal === 'method' &&
            <MethodSlideUpModal parent={this} experience={this.props.experience} />
          }
          { this.state.modal && this.state.modal === 'measure' &&
            <MeasureSlideUpModal parent={this} experience={this.props.experience} />
          }
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ExperienceSubHeader);
