
var cordovaCamera = {

	onDeviceReady: function(){
		//console.log('cordovaCamera deviceready');
		cordovaCamera.enable();
	},

	enable: function(){

	},

	disable: function(){

	}
	
}

export default cordovaCamera;