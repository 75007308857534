import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { formatPercent, fractionToDecimal } from '../../helpers';

function mapStateToProps(state) {
  return {
    cultivar_measures: state.cultivar.measures,    
  };
}

class CultivarMeasures extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      measure: (this.props.selectedMeasure ? this.props.selectedMeasure : this.props.measures[0]),
      cbd_measure_id: 500,
      thc_measure_id: 500
    }

  }

  formatPercent(e){
    if(e){
      e.preventDefault();
      if(e.target.value){
        let percent = formatPercent(e.target.value);
        e.target.value = percent;
      }
    }
  }

  fractionToDecimal(e){
    if(e){
      e.preventDefault();
      if(e.target.value){
        e.target.value = fractionToDecimal(e.target.value);
      }
    }
  }

  validate(e){
    this.props.parent.validate(null);
  }

  setMeasure(id,name,e){
    e.preventDefault();
    //console.log(id,name);
    if(id && name && parseInt(id) > 0){
      let that = this;
      this.setState({measure:[id,name]},function(){ 
        that.props.parent.validate(null);
      });
    }
    else
      this.setState({measure:this.props.measures[0]});
  }  

  render() {
    //console.log(this.props.cultivar_measures)
    if(!this.props.cultivar_measures || typeof(this.props.cultivar_measures.thc[500]) === 'undefined'){
      //console.log('no measures yet')
      return (<React.Fragment></React.Fragment>)
    }

    return (
      <div className="row">
        <div className="col-6 col-md-3">
          <div className="form-group">
            <label>THC</label>
            <div className="input-group">
              <input type="text" id="cultivar_thc_percent" className="form-control text-right" autoComplete="off" defaultValue={formatPercent(this.props.selectedTHC)} onBlur={this.formatPercent.bind(this)} />
              <div className="input-group-append" style={{border:'none'}}>
                <span className="input-group-text bg-white" style={{borderLeft:'none'}}>{ this.props.cultivar_measures.thc[this.state.thc_measure_id][1]}</span>
              </div>
            </div>
            <div className="small mt-2">Enter % without decimals</div>
          </div>
        </div>

        <div className="col-6 col-md-3">
          <div className="form-group">
            <label>CBD</label>
            <div className="input-group">
              <input type="text" id="cultivar_cbd_percent" className="form-control text-right" autoComplete="off" defaultValue={formatPercent(this.props.selectedCBD)} onBlur={this.formatPercent.bind(this)} />
              <div className="input-group-append" style={{border:'none'}}>
                <span className="input-group-text bg-white" style={{borderLeft:'none'}}>{ this.props.cultivar_measures.thc[this.state.cbd_measure_id][1]}</span>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="form-group">
            <input type="hidden" id="cultivar_measure_id" value={this.state.measure[0]} />
            <label>Amount *</label>
            <div className="input-group">
              <input type="text" id="cultivar_amount" className="form-control text-right" autoComplete="off" defaultValue={this.props.selectedAmount ? this.props.selectedAmount*1 : ''} onKeyUp={this.validate.bind(this)} onBlur={this.fractionToDecimal.bind(this)} />
              <div className="input-group-append">
                <button className={"dropdown-toggle text-right btn btn-outline-secondary "+(!this.state.measure[0] ? "" : "text-dark")} type="button" data-toggle="dropdown" aria-expanded="false" style={{fontSize:"12px",minWidth:"90px"}}>{this.state.measure[1]}</button>
                <div className="dropdown-menu">
                  { this.props.measures &&
                    this.props.measures.map(
                      (measure,i)=>{
                        return (
                          <Link key={i} to={"#"} onClick={this.setMeasure.bind(this,measure[0],measure[1])} className="dropdown-item" href="#">{measure[1]}</Link>
                        );
                      }
                    )
                  }
                </div>
              </div>
            </div>
            <div className="small">{this.props.measureLabel}</div>
          </div>
        </div>
      </div>
    );
  }

}

export default connect(mapStateToProps)(CultivarMeasures);
