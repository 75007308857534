import axios from 'axios';
import { apiUrl, defaultParams } from '../vars.js';

import { actionSetExperience, actionSetExperiences, actionSetFeelings, actionSetMethods, actionSetPairings, actionSetIntents, actionSetMoods } from '../redux/actions/experience';
import { getCollection } from './collection';

//import Analytics from '../analytics';
//import Echo from '../echo.js';

export const getExperienceOptions = (that) => {
    axios.get(apiUrl+"/experience/options",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getExperienceOptions response',response.data);
        localStorage.setItem('feelings',JSON.stringify(response.data.feelings));
        localStorage.setItem('methods',JSON.stringify(response.data.methods));
        localStorage.setItem('pairings',JSON.stringify(response.data.pairings));
        localStorage.setItem('intents',JSON.stringify(response.data.intents));
        localStorage.setItem('moods',JSON.stringify(response.data.moods));
        that.props.dispatch(actionSetFeelings(response.data.feelings)); 
        that.props.dispatch(actionSetMethods(response.data.methods)); 
        that.props.dispatch(actionSetPairings(response.data.pairings)); 
        that.props.dispatch(actionSetIntents(response.data.intents)); 
        that.props.dispatch(actionSetMoods(response.data.moods)); 
      });
}

export const getMethods = (that) => {
    axios.get(apiUrl+"/experience/methods",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getMethods',response.data);
        localStorage.setItem('methods',JSON.stringify(response.data.moods));
        that.props.dispatch(actionSetMethods(response.data.methods)); 
      });
}

export const getFeelings = (that) => {
    axios.get(apiUrl+"/experience/feelings",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getFeelings',response.data);
        localStorage.setItem('feelings',JSON.stringify(response.data.feelings));
        that.props.dispatch(actionSetFeelings(response.data.feelings)); 
      });
}

export const getExperiences = (that,id) => {
    axios.get(apiUrl+"/experiences/get",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getExperiences',response.data);
        localStorage.setItem('experiences_total',response.data.experiences.length);
        localStorage.setItem('day_streak',response.data.streak);
        localStorage.setItem('unique_cultivars',response.data.unique);
        that.props.dispatch(actionSetExperiences(response.data)); 
      });
}

export const getExperience = (that,id,edit) => {
    axios.get(apiUrl+"/experience/"+id,{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getExperience',response.data);
        that.setState({experience:response.data}); 
        if(!response.data)
          that.props.history.push('/');
        if(!response.data.ended_at && !edit)
          that.props.history.push('/experience/active');
        //Echo.connectExperience(response.data.id);
      });
}

export const getCollectionExperience = (that,id,edit) => {
    axios.get(apiUrl+"/experience/collection/"+id,{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getCollectionExperience',response.data);
        if(response.data)
          that.setState({past_experience:response.data}); 
      });
}

export const getCultivarExperience = (that,id,edit) => {
    axios.get(apiUrl+"/experience/cultivar/"+id,{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getCultivarExperience',response.data);
        if(response.data)
          that.setState({past_experience:response.data}); 
      });
}

export const getCurrentExperience = (that,data) => {
    axios.get(apiUrl+"/experience/current",{params:{...defaultParams(),...data}})
      .then(response => { 
        //console.log('getCurrentExperience',response.data);
        localStorage.setItem('current_experience',JSON.stringify(response.data))
        that.props.dispatch(actionSetExperience(response.data)); 
      });
}

export const createExperience = (that,data) => {
    that.setState({submitting:true});

    if (that.axiosRequest ) {
        that.axiosRequest.cancel(); 
    }

    that.axiosRequest = axios.CancelToken.source();  

    data.created_at = (new Date()).toISOString();

    axios.post(apiUrl+"/experience/create",{...defaultParams(),...data},{cancelToken: that.axiosRequest.token, timeout:10000})
      .then(response => { 
        //console.log('createExperience',response.data);
        localStorage.setItem('current_experience',JSON.stringify(response.data))
        that.setState({submitting:false});
        that.props.dispatch(actionSetExperience(response.data)); 
      })
      .catch((err) => {
        that.setState({submitting:false});
        if (axios.isCancel(err)) {
          //console.log('Previous request canceled, new request is send', err.message);
        } else {
               // handle error
          //console.log(err.code,err.message,err.stack);
        }
      });
}

export const updateExperience = (that,id,data) => {
    that.setState({submitting:true});
    if (that.axiosRequest ) {
        that.axiosRequest.cancel(); 
    }

    that.axiosRequest = axios.CancelToken.source();  

    axios.post(apiUrl+"/experience/update/"+id,{...defaultParams(),...data},{cancelToken: that.axiosRequest.token, timeout:10000})
      .then(response => { 
        //console.log('updateExperience',response.data);
        if(response.data){
          localStorage.setItem('current_experience',JSON.stringify(response.data))
          that.props.dispatch(actionSetExperience(response.data)); 
          that.props.history.push('/experience/active');
        }
        else{
          that.props.history.push('/experience/'+id);
        }
      })
      .catch((err) => {
        that.setState({submitting:false});
        if (axios.isCancel(err)) {
          //console.log('Previous request canceled, new request is send', err.message);
        } else {
               // handle error
          //console.log(err.code,err.message,err.stack);
        }
      });
}

export const quickUpdateExperience = (that,experience,data) => {
    if(experience.ended_at === null){
      that.props.dispatch(actionSetExperience(experience))
      localStorage.setItem('current_experience',JSON.stringify(experience))
    }
    else{
        that.setState({experience:experience})
    }

    if(that.props.experiences){
      let experiences = []
      for(var i=0;i<that.props.experiences.length;i++){
        if(that.props.experiences[i].id === experience.id)
          experiences.push(experience)
        else
          experiences.push(that.props.experiences[i])
      }
      that.props.dispatch(actionSetExperiences({experiences:experiences}))
    }

    axios.post(apiUrl+"/experience/update/quick/"+experience.id,{...defaultParams(),...data},{timeout:10000})
      .then(response => { 
        //console.log('quickUpdateExperience',response.data);
      })
      .catch((err) => {
        //console.log(err.code,err.message,err.stack);
      });
}

export const deleteExperience = (that,id,token) => {
    that.setState({delete_submitting:true});
    axios.post(apiUrl+"/experience/delete/"+id,{...defaultParams(),delete_token:token})
      .then(response => { 
        //console.log('deletedExperience',response.data);
        if(response.data.success){
          //console.log('is success');
          if(that.props.current_experience && that.props.current_experience.id === parseInt(id)){
            //console.log('current_experience');
            localStorage.removeItem('current_experience')
            that.props.dispatch(actionSetExperience(null)); 
          }
          getExperiences(that);

          that.props.history.push('/');
        }
        else{
          //console.log('not success');
          that.props.history.push('/experience/'+id);
        }
      })
      .catch((err) => {
        that.setState({delete_submitting:false});
        //console.log('delete err',err);
      });
}

export const createCheckin = (data) => {
    axios.post(apiUrl+"/experience/checkin",{...defaultParams(),...data})
      .then(response => { 
        //console.log('createCheckin',response.data);
      });
}

export const endExperience = (that,data) => {
    axios.post(apiUrl+"/experience/end",{...defaultParams(),...data})
      .then(response => { 
        //console.log('endExperience',response.data);
        if(response.data)

          if(that.props.experiences){
            let experiences = []
            let experience = null;
            for(var i=0;i<that.props.experiences.length;i++){
              experience = that.props.experiences[i];
              if(experience.id === that.props.current_experience.id)
                experience.ended_at = (new Date()).toISOString();
//              console.log('experience',experience)
              experiences.push(experience)
            }
            that.props.dispatch(actionSetExperiences({experiences:experiences}))
          }

          localStorage.removeItem('current_experience');
          that.props.dispatch(actionSetExperience(null)); 

          that.props.history.push("/experience/"+data.experience_id);
          getCollection(that);
      });
}
