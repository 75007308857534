import { SET_INITIALIZED, SET_DISPLAY_DATA, SET_DEVICE, SET_VIEWPORT, SET_NAME, SET_BODY_CLASS, SET_HEADER_TITLE, SET_HEADER_EDIT, SET_HAS_INTERNET, SET_NO_SCROLL, SET_FEEDBACK_MODAL } from '../actions/main';

const initialState = {
	initialized: true,
	device_id: localStorage.getItem('device_id'),
	device_uuid: localStorage.getItem('device_uuid'),
	device_name: null,
	display_data: null,
	viewport: "",
	alerts: [],
	body_class: "",
	header_title: null,
	header_edit: null,
	has_internet: 'wifi',
	no_scroll: false,
	feedback_modal: false,
};

export default function(state = initialState, action){
	switch(action.type) {
	    case SET_INITIALIZED:
			return { ...state, initialized: action.initialized };
	    case SET_DISPLAY_DATA:
			return { ...state, display_data: action.display_data };
	    case SET_DEVICE:
			return { ...state, device_id: action.device.id, device_uuid: action.device.uuid, device_name: action.device.name };
	    case SET_NAME:
			return { ...state, device_name: action.device_name };
	    case SET_VIEWPORT:
			return { ...state, viewport: action.viewport };
	    case SET_BODY_CLASS:
			return { ...state, body_class: action.body_class };
	    case SET_HEADER_TITLE:
			return { ...state, header_title: action.header_title };
	    case SET_HEADER_EDIT:
			return { ...state, header_edit: action.header_edit };
	    case SET_HAS_INTERNET:
			return { ...state, has_internet: action.has_internet };
	    case SET_NO_SCROLL:
			return { ...state, no_scroll: action.no_scroll };
	    case SET_FEEDBACK_MODAL:
			return { ...state, feedback_modal: action.feedback_modal };
		default:
			return state;
	}
}