import { DateTime } from "luxon";

export const formatPhone = (phone) => {
	phone = phone.toString();
	return "("+phone.substring(0,3)+") "+phone.substring(3,6)+"-"+phone.substring(6,10);
}

export const fractionToDecimal = (input) => {
	//console.log('fractionToDecimal',input);
	let values = input.split('/');
	//console.log(values);
	if(typeof(values[1]) !== 'undefined' && values[1] !== "")
		return Math.floor( (parseInt(values[0])/parseInt(values[1])) * 1000) / 1000;
	return values[0];
}

export const formatPercent = (input) => {
	let percent;
	if(!input || input < 0)
		return "";
	if(typeof input === 'string' && input.includes('/'))
		percent = fractionToDecimal(input);
	else
		percent = parseFloat(input);
	if( (percent === "NaN" || !percent) && percent !== 0)
		return "";

	if(percent < 1)
		percent = percent*100;
	let converted = Math.round(Math.floor(percent*100) / 100);
	if(converted > 100)
		converted = 100;
	else if (converted < 0)
		converted = 0;
	return converted;
}

export const elapsedTime = (start,end,absolute) => {

	//let utcdate = new Date(); 
	let now_utc =  new DateTime.now();
    // Using Date objects
    if(!start && !end)
    	return null;
    let startDate = (!start ? now_utc : new DateTime.fromISO(start));
    let endDate = (!end ? now_utc : new DateTime.fromISO(end));
    let elapsed = "";

    if(absolute){
    	let days = Math.floor( (endDate - startDate) / 1000 / 60 / 60 / 24 );
    	let hours = Math.floor( (endDate - startDate - (days * 24 * 60 * 60 * 1000) ) / 1000 / 60 / 60 );
    	let minutes = Math.floor( (endDate - startDate - (days * 24 * 60 * 60 * 1000) - (hours * 60 * 60 * 1000)) / 1000 / 60 );

    	if(days >= 1)
    		elapsed += (days+" day"+(days !== 1 ? "s" : "")+" ");
    	if(hours >= 1)
    		elapsed += (hours+" hour"+(hours !== 1 ? "s" : "")+" ");
    	if(minutes >= 1)
    		elapsed += (minutes+" minute"+(minutes !== 1 ? "s" : "")+" ");
    	if(elapsed === "")
			elapsed = "less than a minute";
	}
	else{
		if(Math.floor( (endDate - startDate) / 1000 / 60 / 60 / 24 ) >= 1)
			elapsed = Math.floor( (endDate - startDate) / 1000 / 60 / 60 / 24 )+" days";
		else if(Math.floor( (endDate - startDate) / 1000 / 60 / 60 ) >= 1)
			elapsed = Math.floor( (endDate - startDate) / 1000 / 60 / 60 )+" hours";
		else if(Math.floor( (endDate - startDate) / 1000 / 60 ) >= 1)
			elapsed = Math.floor( (endDate - startDate) / 1000 / 60 )+" minutes";
		else
			elapsed = "less than a minute";
	}	
    return elapsed;
}

export const formatDate = (date,just_date) => {
	if(!date || date === "")
		return "";
	if(just_date)
	    return DateTime.fromISO(date).toFormat('DD')
    return DateTime.fromISO(date).toFormat('ff')
}

// now is 27.11.2019

export const isTwentyOne = date => {
  return DateTime.fromFormat(date, 'yyyy-MM-dd')
    .diffNow('years')
    .years <= -21;
}

export const isEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateInputs = (that,required,allow_zero=false) => {
    let validated = true;
    for(let i=0;i<required.length;i++){
    	if(document.getElementById(required[i]).type === 'checkbox' && !document.getElementById(required[i]).checked)
        	validated = false;
      	else if(document.getElementById(required[i]).value === "" || (!allow_zero && document.getElementById(required[i]).value === "0"))
        	validated = false;
    }
    that.setState({can_submit:validated});	
    return validated;
}

export const slugify = (string) => {
	const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
	const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
	const p = new RegExp(a.split('').join('|'), 'g')

	return string.toString().toLowerCase()
	    .replace(/\s+/g, '-') // Replace spaces with -
	    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
	    .replace(/&/g, '-and-') // Replace & with 'and'
	    .replace(/[^\w-]+/g, '') // Remove all non-word characters
	    .replace(/--+/g, '-') // Replace multiple - with single -
	    .replace(/^-+/, '') // Trim - from start of text
	    .replace(/-+$/, '') // Trim - from end of text
}

export const getUrlParameter = (name) => {
	name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
	var results = regex.exec(window.location.search);
	return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const isJsonString = (str) => {
    try { JSON.parse(str); } 
    catch (e) { return false; }
    return true;
}

export const getCultivarImage = (source) => {
	let base = 'https://eighthnote.s3.us-east-2.amazonaws.com/images/cultivars/';
	if(typeof (source.image) !== 'undefined' && source.image)
		return base+source.image;
	if(typeof (source.cultivar) !== 'undefined' && source.cultivar.image)
		return base+source.cultivar.image;
	if(typeof (source.form) !== 'undefined' && source.form && source.form.image)
		return base+source.form.image;
	if(typeof (source.cultivar) !== 'undefined' && typeof (source.cultivar.form) !== 'undefined' && source.cultivar.form.image)
		return base+source.cultivar.form.image;
	return base+'cultivar_default.jpg';
}

export const getStrain = (that,source_strains,set_strain,strain_id) => {
    if(source_strains && !set_strain){
      	for(var i=0;i<source_strains.length;i++){
        	if(source_strains[i].id === strain_id){
	          	that.setState({strain:source_strains[i]})
          		return
        	}
      	}
      	that.setState({strain:{id:0,name:'Strain Unavailable'}})
    }
}

export const getMeasure = (that,source_measures,set_measure,measure_id,amount) => {
    if(source_measures && !set_measure){
		for(var i=0;i<source_measures.length;i++){
        	if(source_measures[i][0] === measure_id){
          		that.setState({measure:[source_measures[i][0],(parseInt(amount) === 1 ? source_measures[i][1] : source_measures[i][2])]})
          		return
        	}
      	}
    }
}

export const getForm = (that,source_forms,set_form,form_id) => {
    if(source_forms && !set_form){
		for(var i=0;i<source_forms.length;i++){
			if(source_forms[i].id === form_id){
				that.setState({form:source_forms[i]})
          		return
			}
		}
    }
}

export const getMethod = (that,source_methods,set_method,method_id) => {
    if(source_methods && !set_method){
		for(var i=0;i<source_methods.length;i++){
			if(source_methods[i].id === method_id){
				that.setState({method:source_methods[i]})
          		return
			}
		}
    }
}

export const bindLinks = () => {
	//console.log('bindLinks');
	window.jQuery('a[target="_blank"]').click(function(e){
		e.preventDefault();
		window.open(window.jQuery(this).attr('href'), '_blank', 'location=yes');		
	})
}
