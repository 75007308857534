import { SET_COLLECTION } from '../actions/collection';

const initialState = {
	collection: JSON.parse(localStorage.getItem('collection')),
};

export default function(state = initialState, action){
	switch(action.type) {
	    case SET_COLLECTION:
			return { ...state, collection: action.collection };
		default:
			return state;
	}
}