import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'

import Icon from '../../elements/Icon.jsx';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
  	intents: state.experience.intents
  };
}

class IntentSlideUpModal extends React.Component {

	constructor(props) {
		super(props);
	    this.closeModal = this.closeModal.bind(this);

		this.state = {
			selected_intents: {}
		}
	}

	componentDidMount(){
		//console.log('componentDidMount');
		let intents = {}
	    this.props.experience.intents.map(
	      (intent,i) => {  
	      	intents[intent.id] = true;
	      	return "";
	      }
	    )
	    this.setState({selected_intents:intents});

    	Analytics.log('/experience/intents');
	}

	closeModal(){
		this.props.parent.closeModal();
	}

	selectIntent(selected_id,e){
		e.preventDefault();
		let that = this;
		Object.entries(that.props.intents).map(
			(value,id) => {
				//console.log(value,id);
				if(parseInt(value[1].id) === parseInt(selected_id))
					that.props.parent.setIntent(selected_id);
				return "";
			}
		);
	}

	render() {
		let that = this;
	    return (
              <div className={"modal-slideup slidingUp"}>
                <div className="modal-guts p-4 text-dark">
                   <span onClick={this.closeModal.bind(this)} className="float-right">Close</span>
                  <h5>Select Your Intent</h5>
                  <br/>                  
                  { 
                    this.props.intents.map(
                      (intent,i) => {  
                        return (
                        <div key={i} className="row" onClick={this.selectIntent.bind(this,intent.id)}>
                          <div className="col-12 py-0 my-0">
                            <hr className="my-0 py-0" />
                          </div>
                          <div className={"col-3 col-md-2 text-center d-inline-flex justify-content-center align-items-center"}>
                            <Icon icon={intent.icon} transparent={true} size={60} active={typeof(that.state.selected_intents[intent.id]) !== 'undefined'} />
                          </div>
                          <div className={"col-9 col-md-10 text-left d-inline-flex justify-content-left align-items-center"}>
                            <div className={"p-0 my-auto text-uppercase " + (typeof(that.state.selected_intents[intent.id]) !== 'undefined' ? 'text-success' : '') }>{intent.name}</div>
                          </div>
                        </div>
                        );
                      }
                    )
                  }
                </div>
              </div>
	    );
	}
}

export default connect(mapStateToProps)(IntentSlideUpModal);
