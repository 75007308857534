import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'
import Loading from '../elements/Loading.jsx';
import PageHeader from '../elements/headers/PageHeader.jsx';

function mapStateToProps(state) {
  return {
    initialized: state.main.initialized,
    viewport: state.main.viewport,
    device_id: state.main.device_id,
    device_uuid: state.main.device_uuid,
    body_class: state.main.body_class,
    has_internet: state.main.has_internet
  };
}

class LoggedOutLayout extends React.Component {

  goBack() {
    window.history.back();
  }

  render() {
    return (
      <div className={"app loggedout bg-gradient viewport-"+this.props.viewport+" "+(this.props.has_internet ? "has_internet has_internet_"+this.props.has_internet : "has_no_internet")}>

        { this.props.body_class !== 'start' &&
          <PageHeader />
        }

        <div id="pageContainer" className="container">

          <div id="pageContent" className="row">
            {
              !this.props.initialized

              ?
                <Loading/>
              :
                this.props.children
            }
          </div>
        </div>

        <div id="viewportXS" className="d-block d-sm-none" />
        <div id="viewportSM" className="d-none d-sm-block d-md-none" />
        <div id="viewportMD" className="d-none d-md-block d-lg-none" />
        <div id="viewportLG" className="d-none d-lg-block d-xl-none" />
        <div id="viewportXL" className="d-none d-xl-block" />
      </div>
    );
  }
}

export default connect(mapStateToProps)(LoggedOutLayout);
