import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'
import { createCheckin } from '../../api/experience';
import { actionSetExperience } from '../../redux/actions/experience';
import Analytics from '../../analytics';

import { ResponsiveCirclePacking } from '@nivo/circle-packing'
import CircularSlider from '@fseehawer/react-circular-slider';

function mapStateToProps(state) {
  return {
    current_experience: state.experience.experience,
  };
}

class FeelingsCirclePack extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      active_feeling:null
    }
/*
    this.colors = [
      "#FFF",
      "rgb(230,55,0)",
      "rgb(230,55,0)",
      "rgb(230,55,0)",
      "rgb(220,130,105)",
      "rgb(210,210,210)",
      "rgb(210,210,210)",
      "rgb(199,202,150)",
      "rgb(187,194,75)",
      "rgb(187,194,75)",
      "rgb(187,194,75)",
    ]
*/
  }

  applySaturation(amount,color) {
        color = color.replace('#', '').split('');
        var letters = '0123456789ABCDEF'.split('');
        for(var i = 0; i < color.length; i++){
            var newSaturation = 0;
            if(letters.indexOf(color[i]) + amount > 15) newSaturation = 15;
            else if(letters.indexOf(color[i]) + amount < 0) newSaturation = 0;
            else newSaturation = letters.indexOf(color[i]) + amount;
            color[i] = letters[newSaturation];
        }
        return "#" + color.join('');
  }

  editFeeling(feeling){
    this.setState({active_feeling:feeling},function(){
      window.jQuery('#feelingsSliderModal').modal('show');
    });    
  }

  saveFeeling(){
    if(this.state.active_feeling){
      let data = {
        feeling_id: this.state.active_feeling.id,
        feeling_strength: (this.state.active_feeling.value < 1 ? 1 : this.state.active_feeling.value),
        experience_id: this.props.current_experience.id,
      }
      //console.log('saveFeeling',data);


      let experience = {...this.props.current_experience};
      experience.updated_at = (new Date()).toISOString();
      data.created_at = (new Date()).toISOString();

      let new_data = {...data,updated_at:data.created_at}

      if(data.feeling_id > 0){
        experience.current_feelings.data[this.state.active_feeling.index]['base_value']=data.feeling_strength;
        experience.current_feelings.data[this.state.active_feeling.index]['value']=(Math.pow(data.feeling_strength,2)+10);
        experience.current_feelings.data[this.state.active_feeling.index]['color_index']=data.feeling_strength;
        //experience.feelings_check_ins.push(new_data);
        Analytics.log('/experience/checkin/feeling');
      }
      experience.check_ins.push(new_data);
      localStorage.setItem('current_experience',JSON.stringify(experience))
      this.props.dispatch(actionSetExperience(experience)); 

      createCheckin(data);
    }
    this.closeModal();
  }

  closeModal(){
    this.setState({active_feeling:null})
    window.jQuery('#feelingsSliderModal').modal('hide');
  }

  componentDidMount(){
    this.colors = this.props.feelings.colors;
  }

  render() {
    let that =this;

    let data = null;
    if(typeof(this.props.feelings) !== 'undefined' && typeof(this.props.feelings.data) !== 'undefined'){
      data = {"color": "#009900","children": this.props.feelings.data};
    }
    if(!data || typeof(data.children[0]) === 'undefined' || typeof(data.children[0].value) === 'undefined')
      return ( <React.Fragment /> );

    return (
      <React.Fragment>
        <div id="feelingsCirclePack">
          <ResponsiveCirclePacking
              data={data}
              margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              id="name"
              leavesOnly={true}
              colors={({id, data}) => this.colors[data['color_index']]}
              padding={10}
              enableLabels={true}
              labelsSkipRadius={5}
              labelTextColor="#333"
              borderWidth={2}
              borderColor="rgba(0,0,0,.2)"
              tooltip={({ id, value, color }) => ( <span/> )}
              onClick={(node, event) => {that.editFeeling(node.data)}}
              isInteractive={this.props.editable ? true : false}
          />
        </div>

        <div className="modal fade" data-backdrop="static" data-keyboard="false" id="feelingsSliderModal" tabIndex="-1">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body text-center px-2 pt-4">
                {this.state.active_feeling &&
                  <CircularSlider
                      label={this.state.active_feeling.name}
                      labelColor="#999"
                      knobColor="#999"
                      progressColorFrom="#bbc24b"
                      progressColorTo="#bbc24b"
                      progressSize={24}
                      trackColor="#eeeeee"
                      trackSize={24}
                      data={["",1,2,3,4,5,6,7,8,9,10]} //...
                      min={1}
                      max={10}
                      dataIndex={this.state.active_feeling.base_value}
                      onChange={ value => { that.setState({active_feeling:{...that.state.active_feeling,value:value}},function(){ }); window.jQuery('stop').attr('stop-color',that.colors[value] ); } }
                  />
                }
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-success btn-block mt-2" onClick={this.saveFeeling.bind(this)}>Save</button>
                <button type="button" className="btn btn-sm mt-2" onClick={this.closeModal.bind(this)}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(FeelingsCirclePack);
