import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { deleteCollection } from '../../api/collection';

import { actionSetHeaderTitle, actionSetHeaderEdit } from '../../redux/actions/main';

import CultivarExperiencesList from '../../elements/cultivar/CultivarExperiencesList.jsx'
import CultivarHeader from '../../elements/headers/CultivarHeader.jsx';
import Loading from '../../elements/Loading.jsx';
//import Icon from '../../elements/Icon.jsx';
import Rating from '../../elements/Rating.jsx';

import Analytics from '../../analytics';
import { getCultivarImage, getStrain, getForm, getMeasure } from '../../helpers.js';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    device_id: state.main.device_id,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
    strains: state.cultivar.strains,
    forms: state.cultivar.forms,
    measures: state.cultivar.measures,
    collection: state.collection.collection,
    current_experience: state.experience.experience
  };
}

class CollectionView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      can_submit: false,
      collection: null,
      delete_submitting:false,
      delete_token:false,
      strain:null,
      measure:null,
      form:null
    }

  }

  confirmDelete(confirm,e){
    e.preventDefault();
    //console.log('confirmDelete',confirm);
    if(confirm){
      this.setState({delete_token:this.state.collection.action_token},()=>{ window.scrollTo(0,window.innerHeight) });
    }
    else
      this.setState({delete_token:null});
  }

  deleteCollection(id,e){
    e.preventDefault();
    //console.log('deleteCollection',id);
    deleteCollection(this,id);
    Analytics.log('/collection/delete');
  }   

  componentDidMount(){
    this.props.dispatch(actionSetHeaderTitle('Collection'));

    let that = this;

    if(this.props.collection && this.props.strains && this.props.measures && this.props.forms){
      let is_record = false;
      this.props.collection.map((collection,i)=>{
        if(collection.id === parseInt(that.props.match.params.id)){
          that.setState({collection:collection});
          that.props.dispatch(actionSetHeaderEdit('/collection/'+this.props.match.params.id+'/edit'));
          is_record = true;
        }
        return true;
      });
      if(!is_record)
        this.props.history.push('/');
      Analytics.log('/collection/view');
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;
    if(prevProps.collection !== this.props.collection && this.props.collection){
      let is_record = false;
      this.props.collection.map((collection,i)=>{
        if(collection.id === parseInt(that.props.match.params.id)){
          that.setState({collection:collection});
          that.props.dispatch(actionSetHeaderEdit('/collection/'+this.props.match.params.id+'/edit'));
          is_record = true;
        }
        return true;
      });
      if(!is_record)
        this.props.history.push('/');
      Analytics.log('/collection/view');
    }

    if(this.state.collection && this.props.measures){
      getStrain(this,this.props.strains,this.state.strain,this.state.collection.cultivar.strain_id)
      getForm(this,this.props.forms,this.state.form,this.state.collection.cultivar.form_id)
      getMeasure(this, this.props.measures.collection, this.state.measure, this.state.collection.measure_id, this.state.collection.amount)
    }

  }

  componentWillUnmount(){
    this.props.dispatch(actionSetHeaderEdit(null));
  }

  render() {
    let collection = this.state.collection;
    if(collection){
      collection.cultivar.strain = this.state.strain;
      collection.cultivar.form = this.state.form;
      collection.measure = this.state.measure;
    }
    return (
      <main className="col-12 bg-white">
        { !this.state.collection || !this.state.strain || !this.state.measure

          ? 

            <Loading/>

          :

            <React.Fragment>
              <CultivarHeader cultivar={{
                strain:collection.cultivar.strain.name,
                amount:(this.state.collection.status === 1 && parseInt(collection.amount) !== 0 ? (parseFloat(collection.amount)+" "+collection.measure[1]) : "You're out of it"),
                form:collection.cultivar.form.name,
                image:getCultivarImage(collection),
                method:null,
                brand:(collection.brand ? collection.brand.name : null),
                batch:(collection.batch ? collection.batch.identifier : null),
                thc_percent:collection.thc_percent,
                cbd_percent:collection.cbd_percent
              }} />

              <hr className=""/>
              <br/>
              <div className="row justify-content-center">
                { this.state.collection.rating
                  ?
                    <React.Fragment>
                      <div className="d-flex justify-content-between col-11 col-md-9">
                        <Rating rating={this.state.collection.rating} />
                      </div>
                      <div className="text-center col-11 col-md-9 pt-2 pb-0">
                        Average rating of sessions with this cultivar
                      </div>
                    </React.Fragment>

                  :
                    <div className="text-center">No ratings yet for this cultivar</div>
                }
              </div>
              <br/>

              { !this.props.current_experience &&
                <div className="my-2 text-center">
                  { this.state.collection.status === 1 &&
                    <Link to={"/experience/create/collection/"+this.state.collection.id} className="btn btn-lg btn-block text-uppercase small btn-success py-1">start session</Link>
                  }
                </div>
              }

              <hr className="wide" />

              { this.state.collection.experiences &&

                this.state.collection.experiences.length > 0

                ?
                  <div className="py-2">
                    <h4>Your Sessions</h4>
                    <CultivarExperiencesList experiences={this.state.collection.experiences} />
                  </div>
                :

                  <div className="py-2 text-center">
                    <p>You haven't used this cultivar in a session, yet.</p>
                  </div>
              }

              <hr className="wide" />

              <div className="row">
                <div className="col-12 text-center py-4">
                  <Link to={"#"} onClick={this.confirmDelete.bind(this,true)} className="text-uppercase btn btn-lg btn-outline-danger btn-block">remove from collection</Link>
                </div>
                { this.state.delete_token &&
                  <React.Fragment>
                    <div className="col-7 text-center">
                      <button onClick={this.deleteCollection.bind(this,this.state.collection.id)} className="btn btn-lg btn-danger btn-block text-uppercase" disabled={this.state.delete_submitting}>{this.state.delete_submitting ? 'please wait...' : 'Yes, Remove'}</button>
                    </div>
                    <div className="col-5 text-center">
                      <button onClick={this.confirmDelete.bind(this,false)} className="btn btn-lg btn-outline-secondary btn-block text-uppercase">Cancel</button>
                    </div>
                  </React.Fragment>
                }
              </div>

            </React.Fragment>

          }

      </main>
    );
  }
}

export default connect(mapStateToProps)(CollectionView);
