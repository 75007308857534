import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

//import LoginBox from '../../elements/account/LoginBox.jsx';
import SignupBox from '../../elements/account/SignupBox.jsx';

import { actionSetBodyClass } from '../../redux/actions/main.js';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
  };
}

class Start extends React.Component {

  constructor(props) {
    super(props);

    this.setModal = this.setModal.bind(this);

    this.state={
      view: "start",
      modal: null
    }

  }

  setModal(){
    this.setState({modal:'SignUp'});
  }

  componentDidMount(){
    Analytics.log();
    this.props.dispatch(actionSetBodyClass('start'));
  }
  componentWillUnmount(){
    this.props.dispatch(actionSetBodyClass(''));    
  }

  render() {
    return (
        <React.Fragment>
          {this.state.modal === 'SignUp' &&
            <SignupBox parent={this} />
          }
          <main className="col-12 col-md-8 offset-md-2 d-flex flex-column justify-content-center" style={{height:"100%"}}>

            <div style={{height:"calc(50vh)"}}>
              <h3 className="text-center sr-only">Eighthnote</h3>
              <div className="logo-full-box" />
            </div>


            <div className="text-center" style={{position:"absolute",bottom:"0",left:"0",width:"100%"}}>
              <div className="py-2 px-4">
                <Link to={'#'} onClick={this.setModal} className="btn btn-lg btn-success btn-block text-uppercase">Sign Up</Link>
              </div>
              <div className="py-2 px-4">
                <Link to={'/login'}  className="text-white text-uppercase">Login</Link>
              </div>        
            </div>   

          </main>
        </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Start);
