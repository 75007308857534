import axios from 'axios';
import { apiUrl, defaultParams } from '../vars.js';
import { getStrains } from './cultivar.js';

import { actionSetCollection } from '../redux/actions/collection';
import { actionSetStrains } from '../redux/actions/cultivar';

export const getCollection = (that,id) => {
    axios.get(apiUrl+"/collection/get",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getCollection',response.data);
        if(response.data.success){
          localStorage.setItem('collection',JSON.stringify(response.data.collection));
          that.props.dispatch(actionSetCollection(response.data.collection)); 
        }
      });
}

export const addToCollection = (that,data) => {
    that.setState({submitting:true});
    axios.post(apiUrl+"/collection/add",{...defaultParams(),...data})
      .then(response => { 
        //console.log('addToCollection',response.data);
        if(response.data.success){
          let collection = that.props.collection;
          collection.push(response.data.collection);
          localStorage.setItem('collection',JSON.stringify(collection));
	        that.props.dispatch(actionSetCollection(collection));
          getStrains(that);
	        that.props.history.push("/");
        }
        else
          that.setState({submitting:false});
      });
}

export const updateCollection = (that,id,data) => {
    that.setState({submitting:true});
    axios.post(apiUrl+"/collection/"+id,{...defaultParams(),...data})
      .then(response => { 
        if(response.data.success){
          let collection = [];
          for(let x=0;x<that.props.collection.length;x++){
            let item = that.props.collection[x];
            if(parseInt(item.id) === parseInt(id))
              collection.push(response.data.collection);
            else
              collection.push(item);
          }
          localStorage.setItem('collection',JSON.stringify(collection));
          that.props.dispatch(actionSetCollection(collection)); 
          getStrains(that);
          that.props.history.push("/");
        }
        else
          that.setState({submitting:false});
      });
}

export const deleteCollection = (that,id) => {
    //console.log('deleteCollection',id);

    axios.post(apiUrl+"/collection/delete/"+id,{...defaultParams()})
          .then(response => { 
            console.log(response.data);
          });
;

    // just delete it, don't wait for axios response
    let collection = [];
    for(let x=0;x<that.props.collection.length;x++){
      let item = that.props.collection[x];
      if(parseInt(item.id) !== parseInt(id))
        collection.push(item);
    }

    localStorage.setItem('collection',JSON.stringify(collection));
    that.props.dispatch(actionSetCollection(collection));
    that.props.history.push("/");

}
