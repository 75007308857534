import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'

import { actionSetHeaderTitle, actionSetHeaderEdit } from '../../redux/actions/main';

import Loading from '../../elements/Loading.jsx';
import CultivarHeader from '../../elements/headers/CultivarHeader.jsx';
import ExperienceSubHeader from '../../elements/headers/ExperienceSubHeader.jsx';
//import FeelingsChart from '../../elements/experience/FeelingsChart.jsx';
import HealthDataChart from '../../elements/experience/HealthDataChart.jsx';
import FeelingsCirclePack from '../../elements/experience/FeelingsCirclePack.jsx';
import Notes from '../../elements/experience/Notes.jsx';
import Rating from '../../elements/Rating.jsx';

import { getExperience, getExperienceOptions, quickUpdateExperience } from '../../api/experience';

import { formatDate, getCultivarImage, getStrain, getMeasure, getForm, getMethod } from '../../helpers';

import Analytics from '../../analytics';
//import Echo from '../../echo.js';

function mapStateToProps(state) {
  return {
    logged_in_user: state.account.user,
    display_data: state.main.display_data,
    strains: state.cultivar.strains,
    forms: state.cultivar.forms,
    measures: state.cultivar.measures,
    feelings: state.experience.feelings,
    methods: state.experience.methods,
    intents: state.experience.intents,
    experiences: state.experience.experiences,
    current_experience: state.experience.experience,
  };
}

class Experience extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      experience:null,
      submitting:false,
      strain:null,
      measure:null,
      form:null,
      method:null
    }
  }

  componentDidMount(){
    Analytics.log('/experience/view');
    getExperience(this,this.props.match.params.id);
    getExperienceOptions(this);
    this.props.dispatch(actionSetHeaderTitle('Session Details'));    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // clicked a new ad, or entered the ad view directly and ads array isn't loaded yet
    if(prevProps.match.params.id !== this.props.match.params.id){
      this.setState({experience:null,strain:null,measure:null,form:null,method:null})
      getExperience(this,this.props.match.params.id);
      Analytics.log('/experience/view');
    }

    if(this.state.experience && this.props.strains && this.props.measures && this.props.forms && this.props.methods){
      getMethod(this,this.props.methods,this.state.method,this.state.experience.consumed[0].method_id)
      getStrain(this,this.props.strains,this.state.strain,this.state.experience.consumed[0].cultivar.strain_id)
      getForm(this,this.props.forms,this.state.form,this.state.experience.consumed[0].cultivar.form_id)
      getMeasure(this, this.props.measures.experience, this.state.measure, this.state.experience.consumed[0].measure_id, this.state.experience.consumed[0].amount)
    }

    if(prevState.strain !== this.state.strain && this.state.strain)
      this.props.dispatch(actionSetHeaderEdit('/experience/'+this.props.match.params.id+'/edit'));

  }

  componentWillUnmount(){
    this.props.dispatch(actionSetHeaderEdit(null));
  }

  setIntent(id){
    //console.log('setIntent',id);
    for(var i=0;i<this.props.intents.length;i++){
      if(this.props.intents[i].id === id){
        let experience = {...this.state.experience};
        experience.intents = []
        experience.intents.push(this.props.intents[i])
        quickUpdateExperience(this,experience,{intent_id:this.props.intents[i].id})
      }
    }

  }

  setMethod(id){
    //console.log('setMethod',id);
    for(var i=0;i<this.props.methods.length;i++){
      if(this.props.methods[i].id === id){
        let experience = {...this.state.experience};
        experience.consumed[0].method_id = id;
        quickUpdateExperience(this,experience,{method_id:this.props.methods[i].id})
        this.setState({method:null})
      }
    }
  }

  setMeasure(id,amount){
    //console.log('setMeasure',id,amount);
    for(var i=0;i<this.props.measures.experience.length;i++){
      if(this.props.measures.experience[i][0] === id){
        let experience = {...this.state.experience};
        experience.consumed[0].measure_id = id;
        experience.consumed[0].amount = amount;
        quickUpdateExperience(this,experience,{measure_id:this.props.measures.experience[i][0],amount:amount})
        this.setState({measure:null})
      }
    }
  }

  render() {

    if(!this.state.strain || !this.state.measure || !this.state.form || !this.state.method)
      return (<Loading/>);

    let consumed = this.state.experience.consumed[0];
    if(consumed){
      consumed.cultivar.strain = this.state.strain;
      consumed.cultivar.form = this.state.form;
      consumed.measure = this.state.measure;
      consumed.method = this.state.method;
    }
    let i;
    let feelings = {colors:this.state.experience.current_feelings.colors, data:[]};
    for(i=0;i<this.state.experience.current_feelings.data.length;i++){
      if(this.state.experience.current_feelings.data[i].color_index > 0)
        feelings.data.push(this.state.experience.current_feelings.data[i])
    }

    let experience = this.state.experience

    let notes_check_ins = [];
    for(i=0;i<experience.check_ins.length;i++){
      if(typeof(experience.check_ins[i].notes) !== 'undefined' && experience.check_ins[i].notes && experience.check_ins[i].notes !== "")
        notes_check_ins.push(experience.check_ins[i]);
    }


    return (
        <main className="col-12 bg-white d-block">

          <CultivarHeader cultivar={{
            strain:consumed.cultivar.strain.name,
            form:consumed.cultivar.form.name,
            image:getCultivarImage(consumed),
            method:consumed.method,
            brand:(consumed.brand ? consumed.brand.name : null),
            batch:(consumed.batch ? consumed.batch.identifier : null),
            thc_percent:consumed.thc_percent,
            cbd_percent:consumed.cbd_percent,
          }} />

          <ExperienceSubHeader experience={experience} parent={this} />

          <div>{formatDate(experience.created_at_offset)}</div>

          <hr className="wide" />

          <h4 className="text-uppercase">Your Experience</h4>

          { feelings.data.length > 0

            ?

              <FeelingsCirclePack feelings={feelings} editable={false} />
            :

              <div className="text-center text-lowercase py-4"><em>You did not update how you were feelings during this session</em></div>
          }

          <hr className="wide" />

          <div className="row">
            <div className="col-12">
              <h4 className="text-uppercase text-no-weight">Experience Rating</h4>
              { experience.intents.length > 0 &&
                <p>How well did this help you {experience.intents[0].name.toUpperCase()}?</p>
              }
              { experience.rating 

                ?

                  <div className="d-flex justify-content-between">
                    <Rating rating={experience.rating} />
                  </div>

                :

                  <div className="text-center text-lowercase py-4"><em>No rating given for this session</em></div>

              }
            </div>
          </div>

          { typeof(experience.health_plots) !== 'undefined' && experience.health_plots &&
            <div className="row">
              <div className="col-12">
                { typeof(experience.health_plots.heart_rate) !== 'undefined' &&
                  <div className="py-0">
                    <hr className="wide" />
                    <HealthDataChart title="Heart Rate" label={"Heart Rate ("+experience.health_plots.heart_rate.unit+")"} chart_plots={experience.chart_plots.heart_rate} />
                    <hr/>
                    <div>
                      <span>Max { experience.health_plots.heart_rate.plots.linear.reduce((max, a) => { if(a > max) max = a; return max}, 0) }</span>
                      <span> | Min { experience.health_plots.heart_rate.plots.linear.reduce((min, a) => { if(a < min && a > 10) min = a; return min}, 200) }</span>
                    </div>
                    <hr/>
                  </div>
                }
                { typeof(experience.health_plots.feelings) !== 'undefined' &&
                  <div className="py-0">
                    <hr className="wide" />
                    <HealthDataChart title="Experience" label={"Feeling Strength"} chart_plots={experience.chart_plots.feelings} legend={false} />
                    <hr/>
                    <div className="row">
                    { experience.chart_plots.feelings.datasets.map((row,index)=>{
                        if(row.label === "")
                          return "";
                        return (
                          <div key={index} className="col-4 col-sm-4 col-md-3 col-lg-2 small"><span className="badge px-2" style={{backgroundColor:row.backgroundColor}}>&nbsp;</span> {row.label}</div>
                        );
                      })
                    }
                    </div>
                    <hr/>
                  </div>
                }
                { typeof(experience.health_plots.steps) !== 'undefined' &&
                  <div className="py-0">
                    <hr className="wide" />
                    <HealthDataChart title="Movement" label={"Steps ("+experience.health_plots.steps.unit+")"} chart_plots={experience.chart_plots.steps} />
                    <hr/>
                    <div>
                      <span>Total Steps { experience.health_plots.steps.plots.linear.reduce((partialSum, a) => (partialSum + (a===1 ? 0 : a)), 0) }</span>
                      { typeof(experience.health_plots.distance) !== 'undefined' &&
                        <span> | Distance { Math.floor(experience.health_plots.distance.plots.linear.reduce((partialSum, a) => (partialSum + (a===1 ? 0 : a)), 0)*3.28084) } Ft</span>
                      }
                    </div>
                    <hr/>
                  </div>
                }
              </div>
            </div>
          }

          { notes_check_ins.length > 0 &&
            <React.Fragment>
              <hr className="wide" />
              <Notes parent={this} experience={experience} editable={false} />
            </React.Fragment>
          }

          <br/><br/>
        </main>
    );
  }
}

export default connect(mapStateToProps)(Experience);
