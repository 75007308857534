import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import cordovaHealth from '../../cordova/cordovaHealth.js';

import { saveOption } from '../../api/account';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
  	user_options: state.account.options,
    cordova_initialized: state.cordova.cordova_initialized,
  };
}

class HealthDataModal extends React.Component {

	constructor(props) {
		super(props);
		this.toggleHealthSync = this.toggleHealthSync.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	closeModal(){
		this.setState({'error':null});
		this.props.parent.setState({modal:null});
	}

	toggleHealthSync(e){
		e.preventDefault();
		let health_sync = !this.props.health_sync;
		saveOption(this,'health_sync',health_sync);
		//console.log('health_sync',health_sync);
		cordovaHealth.initialize(health_sync);
	}

	componentDidMount(){
	    Analytics.log('/account/healthdata');
	}

	render() {
	    return (
            <div className={"modal-slideout slidingOut"}>
              <div className="modal-guts p-4 text-dark">
                <Link to={"#"} className="float-right" onClick={this.closeModal}>X</Link>
                <h4 className="text-uppercase">Health Integrations</h4>
                <br/>
                <h5 className="text-uppercase">General</h5>
				<div className="setting-option">
					<div>Track health metric during session with { window.device.platform.toLowerCase() === 'android' ? 'Google Fit' : 'HealthKit'}  <i onClick={this.toggleHealthSync} className={"fas fa-toggle-on toggle-switch float-right " + (this.props.user_options['health_sync'] ? 'text-success' : 'text-grey')}></i></div>
				</div>
              </div>
            </div>
	    );
	}
}

export default connect(mapStateToProps)(HealthDataModal);
