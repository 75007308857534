import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'

import Icon from '../../elements/Icon.jsx';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
  	methods: state.experience.methods
  };
}

class MethodSlideUpModal extends React.Component {


	constructor(props) {
		super(props);
	    this.closeModal = this.closeModal.bind(this);
	}

	componentDidMount(){
/*		console.log('componentDidMount');
		let methods = {}
		let that = this;
	    this.props.methods.map(
	      (method,i) => {  
	      	methods[method.id] = (that.props.parent.state.methods && typeof(that.props.parent.state.methods[method.id]) !== 'undefined' ? true : false);
	      	return "";
	      }
	    )
	    this.setState({methods:methods});

*/    	Analytics.log('/experience/intents');
	}

	closeModal(){
		this.props.parent.closeModal();
	}

	selectMethod(selected_id,e){
		e.preventDefault();
		let that = this;
		Object.entries(that.props.methods).map(
			(value,id) => {
				//console.log(value,id);
				if(parseInt(value[1].id) === parseInt(selected_id))
					that.props.parent.setMethod(selected_id);
				return "";
			}
		);
	}

	render() {
		let that = this;
	    return (
              <div className={"modal-slideup slidingUp"}>
                <div className="modal-guts p-4 text-dark">
                   <span onClick={this.closeModal.bind(this)} className="float-right">Close</span>
                  <h5>Select Your Method</h5>
                  <br/>                  
                  { 
                    this.props.methods.map(
                      (method,i) => {  
                        return (
                        <div key={i} className="row" onClick={this.selectMethod.bind(this,method.id)}>
                          <div className="col-12 py-0 my-0">
                            <hr className="my-0 py-0" />
                          </div>
                          <div className={"col-3 col-md-2 text-center d-inline-flex justify-content-center align-items-center"}>
                            <Icon icon={method.icon} transparent={true} size={60} active={that.props.experience.consumed[0].method_id === method.id} />
                          </div>
                          <div className={"col-9 col-md-10 text-left d-inline-flex justify-content-left align-items-center"}>
                            <div className={"p-0 my-auto text-uppercase " + (that.props.experience.consumed[0].method_id === method.id ? 'text-success' : '')}>{method.name}</div>
                          </div>
                        </div>
                        );
                      }
                    )
                  }
                </div>
              </div>
	    );
	}
}

export default connect(mapStateToProps)(MethodSlideUpModal);
