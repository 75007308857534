import React from 'react';
import { connect } from 'react-redux';

import cordovaHealth from '../../cordova/cordovaHealth.js';
import cordovaPush from '../../cordova/cordovaPush.js';

import { saveOption } from '../../api/account';

function mapStateToProps(state) {
  return {
    cordova_initialized: state.cordova.initialized,
    health_available: state.cordova.health_available,
    user_options: state.account.options,
  };
}

class FirstLaunchModal extends React.Component {

  constructor(props) {
    super(props);
    this.enableEmails = this.enableEmails.bind(this);
    this.enableHealthSync = this.enableHealthSync.bind(this);
    this.enablePushNotifications = this.enablePushNotifications.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  closeModal(){
    window.jQuery('#firstLaunchModal').modal('hide');
  }

  enableEmails(e){
    e.preventDefault();
    saveOption(this,'email_news',true);
  }

  enableHealthSync(e){
    e.preventDefault();
    saveOption(this,'health_sync',true);
    cordovaHealth.initialize(true);
  }

  enablePushNotifications(e){
    e.preventDefault();
    saveOption(this,'push_sessions',true);
    let that = this;
    setTimeout(function(){saveOption(that,'push_announcements',true);},1000);
    cordovaPush.initialize(true);
  }

  componentDidMount(){
    window.jQuery('#firstLaunchModal').modal('show');
    localStorage.setItem('app_been_launched',true);
  }  

  render() {
    return (
        <div className="modal fade" style={{marginTop:"75px"}}  data-backdrop="static" data-keyboard="false" id="firstLaunchModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <h4>Thank You For Using Eighthnote</h4>
                <p>We just sent you an email to verify your account.</p>
                <p>Remember that every person responds to cannabis differently. Stay safe, have fun, and always consume responsibly. </p>
                { this.props.user_options && !this.props.user_options['email_news'] && 
                  <div className="my-2">
                    <hr className="wide" />
                    Receive news and announcements in my email inbox<br/>
                    <button onClick={this.enableEmails} className={"btn btn-xs " + (this.props.user_options['email_news'] ? 'btn-success' : 'btn-outline-success')}>enable</button>
                  </div>
                }

                { this.props.cordova_initialized && this.props.user_options &&

                  <div>
                    { !this.props.user_options['health_sync'] && 
                      <div className="my-2">
                        <hr className="wide" />
                        Track health metric during sessions with { window.device.platform.toLowerCase() === 'android' ? 'Google Fit' : 'HealthKit'}<br/>
                        <button onClick={this.enableHealthSync} className={"btn btn-xs " + (this.props.user_options['health_sync'] ? 'btn-success' : 'btn-outline-success')}>enable</button>
                      </div>
                    }
                    { !this.props.user_options['push_sessions'] &&
                      <div className="my-2">
                        <hr className="wide" />
                        Receive push notifications?<br/>
                        <button onClick={this.enablePushNotifications} className={"btn btn-xs " + (this.props.user_options['push_sessions'] ? 'btn-success' : 'btn-outline-success')}>enable</button>
                      </div>
                    }
                    <br/><br/>
                  </div>

                }
                <hr className="wide" />
                <button type="button" className="btn btn-success" onClick={this.closeModal.bind(this)}>Close</button>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(FirstLaunchModal);
