import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'
import { createCheckin } from '../../api/experience';
import { actionSetExperience } from '../../redux/actions/experience';
import Analytics from '../../analytics';
import { elapsedTime } from '../../helpers';

//import { ResponsiveCirclePacking } from '@nivo/circle-packing'
//import CircularSlider from '@fseehawer/react-circular-slider';

function mapStateToProps(state) {
  return {
  };
}

class Notes extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      notes_submit: false,
      notes_submitting: false
    }

  }

  createNotes(e){
    e.preventDefault();
    let notes = document.getElementById('checkin_notes').value;

    if(notes !== ''){
      let data = {
        feeling_id: null,
        feeling_strength: null,
        experience_id: this.props.experience.id,
        notes: notes
      }
      //console.log(data);

      let experience = {...this.props.experience};
      experience.updated_at = (new Date()).toISOString();
      data.created_at = (new Date()).toISOString();

      let new_data = {...data,updated_at:data.created_at}

      Analytics.log('/experience/checkin/notes');

      experience.check_ins.push(new_data);
      localStorage.setItem('current_experience',JSON.stringify(experience))
      this.props.dispatch(actionSetExperience(experience)); 

      createCheckin(data);

      this.setState({notes_submit:false});
      this.props.parent.setState({can_submit:true});
      document.getElementById('checkin_notes').value = '';
    }
    this.closeModal();
  }

  notesFocus(e){
    e.preventDefault();
    this.setState({notes_submit:true});
  }

  notesBlur(e){
    e.preventDefault();
    let notes = document.getElementById('checkin_notes').value;
    if(notes === ''){
      this.setState({notes_submit:false});
    }
  }

  openModal(){
    window.jQuery('#notesModal').modal('show');
    setTimeout(function(){document.getElementById("checkin_notes").focus()},500);
    this.props.parent.setState({can_submit:false});
  }

  closeModal(){
    window.jQuery('#notesModal').modal('hide');
    this.props.parent.setState({can_submit:true});
  }

  render() {
    let that =this;
    //console.log('notes',this.props.experience)
    return (
      <React.Fragment>
        <h4 className="text-uppercase text-no-weight">Notes</h4>
        { this.props.intro && 
          <div className="mb-3">{ this.props.intro }</div>
        }
        
        { this.props.experience.check_ins.map(
            (checkin,i) => {  
              if(typeof(checkin.notes) !== 'undefined' && checkin.notes && checkin.notes !== ""){
                let elapsed = elapsedTime(that.props.experience.created_at,checkin.created_at,true)

                return (
                  <div key={i} className="">
                    <div>
                    { 
                      checkin.notes.split("\n").map(
                        (note,i) => {  
                          return (
                            <div key={i} className="pt-1">{note}</div>
                          );
                        }
                      )
                    }
                    </div>
                    <small><em>{"@ "+elapsed}</em></small>
                    <hr/>
                  </div>
                );
              }
              return ("");
            }
          )
        }
        { this.props.editable &&
          <React.Fragment>
            <div><button className="btn text-success" onClick={this.openModal.bind(this)}><i className="fas fa-plus"></i> Add Note</button></div>
            <div className="modal fade" data-backdrop="static" data-keyboard="false" id="notesModal" tabIndex="-1">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                  <div className="modal-body text-center">
                    <h4>Enter Notes</h4>
                    <div className="d-block" style={{position:"relative"}}>
                      <textarea id="checkin_notes" className="form-control" rows='3' onFocus={this.notesFocus.bind(this)} onBlur={this.notesBlur.bind(this)}></textarea>
                    </div>
                    <br/>
                    <div className="d-block">
                      <div className="row">
                        <div className="col-8">
                          <button type="button" className="btn btn-success btn-block" disabled={!this.state.notes_submit} onClick={this.createNotes.bind(this)}>Save</button>
                        </div>
                        <div className="col-4">
                          <button type="button" className="btn btn-outline-secondary btn-block" onClick={this.closeModal.bind(this)}>Cancel</button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        }

      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Notes);
