import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    experiences_total: state.experience.total,
    day_streak: state.experience.streak,
    unique_cultivars: state.experience.unique
  };
}

class ProfileSnapshotHeader extends React.Component {

  render() {
    return (
      <React.Fragment>
        <div className="row" style={{fontSize:"12px",lineHeight:"14px"}}>
          <div className="col text-left py-2">
            <div className="d-inline-block pr-3 pr-md-4 text-left profile-snapshot-item">
              <div className="d-inline-block" style={{fontSize:"56px",lineHeight:"42px"}}>{this.props.experiences_total}</div>
              <div className="d-inline-block ml-2">total<br/>sessions</div>
            </div>
            <div className="d-inline-block px-3 px-md-4 text-left profile-snapshot-item">
              <div className="d-inline-block" style={{fontSize:"56px",lineHeight:"42px"}}>{this.props.day_streak}</div>
              <div className="d-inline-block ml-2">day<br/>streak</div>
            </div>
            <div className="d-none pl-3 pl-md-4 text-left profile-snapshot-item">
              <div className="d-inline-block" style={{fontSize:"56px",lineHeight:"42px"}}>{this.props.unique_cultivars}</div>
              <div className="d-inline-block ml-2">different<br/>cultivars</div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ProfileSnapshotHeader);
