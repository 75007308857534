import React from 'react';
import { connect } from 'react-redux';

import { actionSetHeaderTitle } from '../../redux/actions/main';

import { topCultivars } from '../../api/cultivar';
import ExploreCultivarCard from '../../elements/cultivar/ExploreCultivarCard.jsx';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    device_id: state.main.device_id,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
    experiences: state.experience.experiences,
    collection: state.collection.collection
  };
}

class Explore extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      cultivars: null
    }
  }

  componentDidMount(){
    this.props.dispatch(actionSetHeaderTitle('Explore'));

    topCultivars(this);

  }

  componentWillUnmount(){
    this.props.dispatch(actionSetHeaderTitle(null));    
  }


  render() {
    return (
      <React.Fragment>
        <main className="col-12 bg-white">

          <div className="row mx-0">
            { this.state.cultivars &&

                this.state.cultivars.map(
                  (cultivar,i) => {  
                    return (
                      <ExploreCultivarCard key={i} cultivar={cultivar} />
                    );
                  }
                )
            }
          </div>

        </main>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Explore);
