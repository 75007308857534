import { SET_CORDOVA_INITIALIZE, SET_PUSH_AVAILABLE, SET_HEALTH_AVAILABLE } from '../actions/cordova';

const initialState = {
	initialized: false,
	push_available: false,
	health_available: false
};

export default function(state = initialState, action){
	switch(action.type) {
	    case SET_CORDOVA_INITIALIZE:
			return { ...state, initialized: action.initialized };		
	    case SET_PUSH_AVAILABLE:
			return { ...state, push_available: action.available };		
	    case SET_HEALTH_AVAILABLE:
			return { ...state, health_available: action.available };
		default:
			return state;
	}
}