import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'

import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function mapStateToProps(state) {
  return {
    logged_in_user: state.account.user,
    current_experience: state.experience.experience,
  };
}

class HealthDataChart extends React.Component {

  chartRef = React.createRef();

  constructor(props) {
    super(props);

    this.state={
      chartDrawn:false
    }

    this.feeling_names = [];
    this.feeling_ids = [];
  }

  createChart(chartRef, chartData) {
    new Chart(chartRef, {
      type: chartData.type,
      data: chartData.data,
      options: chartData.options,
    })
  }

  componentDidMount(){
    //window.jQuery('#feelingsSelect').select2({theme:"bootstrap4"}).on('change',function(){console.log('select2 changed'); that.changeFeeling(this)});
    if(!this.state.chartDrawn){
      const myChartRef = this.chartRef.current.getContext("2d");

      this.setState({chartDrawn:true},function(){
          let plotdata = null;
          new Chart(myChartRef, 
            {
              type: this.props.type,
              data: {
                labels: this.props.chart_plots.labels,
                datasets: this.props.chart_plots.datasets
              },
              options: {
                responsive:false,
                showLine: true,
                spanGaps: true,
                //pointRadius:(this.props.chart_plots.radius ? this.props.chart_plots.radius : 0),
                layout: {
                  autoPadding: false,
                  padding:5
                },
                scales: {
                  y: {
                    min:0,
                    max:(this.props.chart_plots.max ? this.props.chart_plots.max : null),
                    grid: {
                      display: true
                    },
                    title: {
                      display:false,
                      text: this.props.label
                    },
                    ticks:{
                      stepSize:(this.props.chart_plots.step ? this.props.chart_plots.step : 20),
                      padding:10
                    }
                  },
                  x: {
                    type: 'linear',
                    min:-30,
                    max:240,
                    ticks:{
                      stepSize:30,
                    },
                    offset:true,
                    grid: {
                      display: true,
                      offset:true
                    },
                    title: {
                      display:true,
                      text: "Elapsed Minutes"
                    }
                  },
                  heart: {
                    grid: {
                      display: false
                    },
                    title: {
                      display:false,
                      text: 'Heart Rate'
                    },
                    min:0,
                    max:240,
                    ticks:{
                      stepSize:(this.props.chart_plots.step ? this.props.chart_plots.step : 20),
                      label:"",
                      display:false,
                      padding:10
                    },
                    position: 'right',
                  },
                },
                plugins: {
                  legend:{
                    display:(this.props.legend ? true : false),
//                    position:'chartArea',
                    labels:{
                      usePointStyle: true,
                      boxWidth:8,
                      boxHeight:8,
                      filter: function(legend_item,chart_data){
                        if(legend_item.text === "")
                          return false;
                        return true;
                      }
                    }
                  },
                  tooltip:{
                    enabled:false
                  },
                },
              }
          })
        }
      )
    }

  }

  render() {
    //console.log(this.props.title,this.props.chart_plots)
    return (
      <div className="py-0 px-0 chart_js_container" style={{position:"relative",height:"55vh"}}>
        { this.props.title &&
          <h4 className="text-uppercase text-no-weight">{this.props.title}</h4>
        }
        <canvas id="chartJSContainer" className="chart_js" ref={this.chartRef} style={{height:"calc(100% - 36px)",width:"100%"}}></canvas>
        { this.props.plots && 1===0 &&
          this.props.plots.linear.map((plot,index)=>{
            return (
              <span key={index} className="m-1 px-1 badge badge-info">x: {index} -- y: {plot}</span>
            );
          })
        }
      </div>
    );
  }
}

export default connect(mapStateToProps)(HealthDataChart);
