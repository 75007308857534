import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

//import Icon from '../../elements/Icon.jsx';
import { fractionToDecimal } from '../../helpers';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
  	measures: state.cultivar.measures
  };
}

class MeasureSlideUpModal extends React.Component {


	constructor(props) {
		super(props);
	    this.closeModal = this.closeModal.bind(this);

		this.state = {
			measure: null,
			amount: null
		}
	}

	componentDidMount(){
		this.setState({measure:this.props.experience.consumed[0].measure,amount:this.props.experience.consumed[0].amount})
    	Analytics.log('/experience/measure');
	}

	closeModal(){
		this.props.parent.closeModal();
	}

	selectMeasure(measure_id,measure_name){
		//console.log('selectMeasure',measure_id,measure_name)
		this.setState({measure:[measure_id,measure_name]});
	}
	setMeasure(e){
		if(e)
			e.preventDefault();
		let amount = document.getElementById('measure_amount').value;
		amount = fractionToDecimal(amount);
		amount = parseFloat(amount);
		let measure_id = this.state.measure[0]
		//console.log(amount,measure_id);
		this.props.parent.setMeasure(measure_id,amount);
	}

	render() {
	    return (
			<div className={"modal-slideup slidingUp"}>
				<div className="modal-guts p-4 text-dark">
					<span onClick={this.closeModal.bind(this)} className="float-right">Close</span>
					<h5>How Much Are You Consuming?</h5>
					<br/><br/>
					<div className="text-center d-block">
	                  	{ this.props.measures && this.state.measure &&
			              	<div className="d-inline-block">
								<input type="text" id="measure_amount" className="text-center mb-2" defaultValue={parseFloat(this.state.amount)} autoComplete="off" style={{width:"120px",fontSize:"42px",borderWidth:"0 0 1px"}} /><br/>
			                	<button className={"dropdown-toggle text-right btn btn-outline-secondary "+(!this.state.measure[0] ? "" : "text-dark")} type="button" data-toggle="dropdown" aria-expanded="false" style={{fontSize:"18px",minWidth:"120px",borderWidth:0}}>{this.state.measure[1]}</button>
			                	<div className="dropdown-menu">
			                    	{ this.props.measures.experience.map(
			                      		(measure,i)=>{
			                        		return (
			                          			<Link key={i} to={"#"} onClick={this.selectMeasure.bind(this,measure[0],measure[2])} className="dropdown-item" href="#">{measure[2]}</Link>
			                        		);
			                      		}
			                    	)}
			                	</div>
			                	<br/><br/>
			                	<button className="btn btn-success btn-lg" onClick={this.setMeasure.bind(this)}>Save</button><br/>
			                	<button className="btn  btn-lg" onClick={this.closeModal.bind(this)}>cancel</button><br/>
			                	<br/><br/>
			              </div>
	                  	}
					</div>
				</div>
			</div>
	    );
	}
}

export default connect(mapStateToProps)(MeasureSlideUpModal);
