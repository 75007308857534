import axios from 'axios';
//import LaravelEcho from 'laravel-echo';
import Echo from '../echo.js';

import { apiUrl, defaultParams, pusherParams } from '../vars.js';
import cordovaHealth from '../cordova/cordovaHealth.js';
import cordovaPush from '../cordova/cordovaPush.js';

import { actionAuthenticate, actionSetUserOptions, actionSetNotificationTypes } from '../redux/actions/account';

export const checkApiToken = (store,full=true) => {
  axios.post(apiUrl+"/account/check_token",{...defaultParams()})
    .then(
      response => { 
        if(response.data.user){
          //console.log(response.data)
          store.dispatch(actionAuthenticate(response.data.user)); 
          store.dispatch(actionSetUserOptions(response.data.options)); 
          store.dispatch(actionSetNotificationTypes(response.data.notification_types)); 
          localStorage.setItem('logged_in_user',JSON.stringify(response.data.user));

          localStorage.setItem('pusher_id',response.data.pusher.id);
          localStorage.setItem('pusher_key',response.data.pusher.key);
          localStorage.setItem('pusher_cluster',response.data.pusher.cluster);

          if(full){
            //Echo.connectGeneral();
            //Echo.connectUser(response.data.user.id);

            if(store.getState().cordova.initialized){
              //console.log('cordova initialized response');
              if(response.data.options['health_sync'])
                cordovaHealth.initialize(true);
              if(response.data.options['push_sessions'] || response.data.options['push_announcements'])
                cordovaPush.initialize(true);
            }
          }
        }
        else{
          localStorage.removeItem('logged_in_user');
          localStorage.removeItem('api_token');
          window.location.reload();
        }
      }
    );
}
export const userLogin = (that, email, password) => {
  that.setState({loading:true, error:null});
  axios.post(apiUrl+"/account/authenticate",{...defaultParams(), 'email':email, 'password':password})
    .then(
      response => { 
        if(response.data.user){
          //console.log('userLogin',response.data);
          that.setState({loading:false, error:null});
          that.props.dispatch(actionAuthenticate(response.data.user)); 
          localStorage.setItem('api_token',response.data.api_token);
          localStorage.setItem('logged_in_user',JSON.stringify(response.data.user));
          window.location.hash = "/";
          window.location.reload();
        }
        else{
          that.setState({loading:false, error:"Cannot log you in"});
          that.props.dispatch(actionAuthenticate(null)); 
          localStorage.removeItem('api_token');
          localStorage.removeItem('logged_in_user');
        }
      }
    )
    .catch( 
      error => {
        //console.log('error',error);
        that.setState({loading:false, error:"Connection error. Try again later."});
      }
    );
}
export const userLogout = (that,logout="device") => {
  //console.log('userLogout');
  that.setState({logging_out:true});
  axios.post(apiUrl+"/account/logout",{...defaultParams(), 'logout':logout})
    .then(
      response => { 
        that.props.dispatch(actionAuthenticate(null)); 
        localStorage.removeItem('api_token');
        localStorage.removeItem('logged_in_user');
        that.props.history.push('/');
        window.location.reload();
      }
    );

}
export const sendResetCode = (that,email) => {
  that.setState({success:null, error:null});
  axios.post(apiUrl+"/password/request-code",{...defaultParams(), 'email':email})
    .then(
      response => { 
        that.setState({success:response.data.success,error:response.data.error,sending:false,fields_populated:false});
        if(response.data.success)
          that.setState({code_received:true});
      }
    );
}
export const sendPasswordChange = (that,email,code,password) => {
  that.setState({success:null, error:null});
  axios.post(apiUrl+"/password/change-via-code",{...defaultParams(), email:email, code:code, password:password})
    .then(
      response => { 
        that.setState({success:response.data.success,error:response.data.error,sending:false});
        if(response.data.reset_form)
          that.setState({code_received:false});
      }
    );
}
export const createAccount = (that,data) => {
  that.setState({error:null});
  axios.post(apiUrl+"/account/register",{...defaultParams(), ...data})
    .then(
      response => { 
        if(response.data.error){
          that.setState({error:response.data.error});
        }
        else if(response.data.success){
          //console.log('createAccount',response.data);
          that.props.dispatch(actionAuthenticate(response.data.user)); 
          localStorage.setItem('api_token',response.data.user.api_token);
          localStorage.setItem('logged_in_user',JSON.stringify(response.data.user));
          window.location.reload();          
        }
      }
    );
}
export const changePassword = (that,password,password_confirm) => {
  axios.post(apiUrl+"/account/password",{...defaultParams(), password:password, password_confirm:password_confirm})
    .then(
      response => { 
        //console.log('changePassword',response.data);
        that.setState({success:response.data.success,error:response.data.error})
        if(response.data.api_token){
          localStorage.setItem('api_token',response.data.api_token);
        }
        if(response.data.success){
          document.getElementById("changePasswordControls").style.display = 'none';
          that.props.dispatch(actionAuthenticate(response.data.user)); 
          localStorage.setItem('logged_in_user',JSON.stringify(response.data.user));
          //that.props.parent.closeModal();
        }
      }
    );
}
export const changeProfile = (that,data) => {
  //console.log({...defaultParams(), ...data});
  axios.post(apiUrl+"/account/profile",{...defaultParams(), ...data})
    .then(
      response => { 
        //console.log('changeProfile',response.data);
        that.setState({success:response.data.success,error:response.data.error})
        if(response.data.success){
          document.getElementById("changeProfileControls").style.display = 'none';
          that.props.dispatch(actionAuthenticate(response.data.user)); 
          localStorage.setItem('logged_in_user',JSON.stringify(response.data.user));
          //that.props.parent.closeModal();
        }
      }
    );
}
export const changeEmail = (that,email) => {
  axios.post(apiUrl+"/account/email",{...defaultParams(), email:email})
    .then(
      response => { 
        //console.log('changeEmail',response.data);
        that.setState({success:response.data.success,error:response.data.error})
        if(response.data.success){
          document.getElementById("changeEmailControls").style.display = 'none';
          that.props.dispatch(actionAuthenticate(response.data.user)); 
          localStorage.setItem('logged_in_user',JSON.stringify(response.data.user));
          //that.props.parent.closeModal();
        }
      }
    );
}
export const getVerificationEmail = (that,user,hash) => {
  axios.get(apiUrl+"/account/verify/email",{params:{...defaultParams()} })
    .then(
      response => { 
        //console.log('getVerificationEmail',response.data);
        that.setState({verification_sent:true,error:null,success:null})
      }
    );
}
export const verifyEmail = (that,user,hash) => {
  that.setState({verifying:true});
  let verify_user = that.props.match.params.id;
  let verify_hash = that.props.match.params.hash;
  //console.log(verify_user,verify_hash);
  axios.post(apiUrl+"/account/verify/email",{...defaultParams(), verify_user:verify_user, verify_hash:verify_hash})
    .then(
      response => { 
        //console.log('verifyEmail',response.data);
        let user_data = null;
        if(that.props.logged_in_user)
          user_data = {...that.props.logged_in_user,email_verified_at:response.data.verified_at}
        that.props.dispatch(actionAuthenticate(user_data)); 
        that.setState({verifying:false,success:response.data.success,error:response.data.error})
        if(response.data.success)
          that.setState({verified:true})
        else
          that.setState({verified:false})
      }
    );
}
export const saveOption = (that,key,value) => {
  //console.log(that.props.user_options);
  let options = {...that.props.user_options};
  options[key] = value;
  //console.log(options);
  that.props.dispatch(actionSetUserOptions(options));
  axios.post(apiUrl+"/account/option",{...defaultParams(), key:key, value:value})
    .then(
      response => { 
        //that.forceUpdate();
        console.log('saveOption',response.data);
      }
    );
}
