import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, BrowserRouter, Route, Switch } from 'react-router-dom'

import LoggedInLayout from './layouts/loggedin.jsx';
import LoggedOutLayout from './layouts/loggedout.jsx';

import Home from './pages/home/Home';
import Start from './pages/home/Start';
//import Error404 from './pages/errors/Error404';
import appRoutes from './routes.js';
import cordovaApp from './cordova/cordovaApp.js';

import { createStore } from 'redux';
import { Provider } from 'react-redux';
import reducer from './redux/reducer.js';
import { actionSetViewport } from './redux/actions/main';
import { actionSetCordovaInitialize } from './redux/actions/cordova';

import Loading from './elements/Loading'
import { postValidateDevice, checkEmailTracking } from './api/main';
import { checkApiToken } from './api/account';

import Analytics from './analytics';
import Echo from './echo';

import * as serviceWorker from './serviceWorker';

// let the env vars determine the routing type
var Router = BrowserRouter; // default to use web url routes in Wave
if(process.env.REACT_APP_ROUTER === 'HashRouter') // use hash routing in apps
  Router = HashRouter;

const store = createStore(reducer);
Echo.store = store;

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: null
    };

  }

  validateDevice(store=null, device){
    device.id = localStorage.getItem('device_id');
    localStorage.setItem('device_uuid',device.uuid);
    postValidateDevice(this, store, device);
  }

  onResize(){
    let currentViewport = null;
    let that = this;

    var x;
    x = document.getElementById("viewportXS");
    if(x && window.getComputedStyle(x).display !== "none")
      currentViewport = 'xs';
    x = document.getElementById("viewportSM");
    if(x && window.getComputedStyle(x).display !== "none")
      currentViewport = 'sm';
    x = document.getElementById("viewportMD");
    if(x && window.getComputedStyle(x).display !== "none")
      currentViewport = 'md';
    x = document.getElementById("viewportLG");
    if(x && window.getComputedStyle(x).display !== "none")
      currentViewport = 'lg';
    x = document.getElementById("viewportXL");
    if(x && window.getComputedStyle(x).display !== "none")
      currentViewport = 'xl';

    if(!currentViewport){
      setTimeout(function(){ that.onResize()},100);
      return;
    }
    store.dispatch(actionSetViewport(currentViewport));  

  }

  /* CORDOVA READY FUNCTIONS */
  onDeviceReady(){
    //console.log('index onDeviceReady');
    cordovaApp.onDeviceReady(this,store);
  }

  /* NON-CORDOVA READY FUNCTIONS */
  onDocumentReady(){
    this.setState({loaded:true});
    var device_uuid = localStorage.getItem('device_uuid');
    if(!device_uuid || device_uuid === ""){
      device_uuid = Math.random()+'-'+Math.random();
    }
    this.validateDevice(store, {uuid:device_uuid, hash:localStorage.getItem('device_hash'), platform:'Browser', manufacturer:'Browser', model:'Browser'} );
  }

  componentDidMount(){
    let that = this;

    checkEmailTracking((process.env.REACT_APP_ROUTER === 'HashRouter' || typeof(window.cordova) !== 'undefined'));

    this.onResize();
    window.addEventListener('resize',function(){ setTimeout(function(){that.onResize()},100); });

    if(typeof(window.cordova) !== 'undefined'){ // is cordova app
      Analytics.ga_event_category='app';
      store.dispatch(actionSetCordovaInitialize(true));  
      document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);
    }
    else{ // not cordova app
      Analytics.ga_event_category='website';
      store.dispatch(actionSetCordovaInitialize(false));
      this.onDocumentReady();  
    }

    if(localStorage.getItem('api_token')){
      checkApiToken(store);
    }


  }

  render() {
    if(!this.state.loaded)
      return ( <Loading/> );

    if(!store.getState().account.user){
      //console.log('loggedout')
      return (
          <Provider store={store}>
            <Router>
              <LoggedOutLayout path={store.getState().main.path}>
                <Switch>
                {
                  appRoutes.map(function(route, index) {
                    if(!route.logged_in)
                      return <Route key={100+index} path={route.path} exact component={route.component}></Route>;
                    else
                      return '';
                  })
                }
                <Route key={404} component={Start}></Route>;
                </Switch>
              </LoggedOutLayout>
            </Router>
          </Provider>
      );

    }

    return (
        <Provider store={store}>
          <Router>
            <LoggedInLayout path={store.getState().main.path}>
              <Switch>
                {
                  appRoutes.map(function(route, index) {
                    if(route.logged_in)
                      return <Route key={100+index} path={route.path} exact component={route.component}></Route>;
                    else
                      return '';
                  })
                }
                <Route key={404} component={Home}></Route>;
              </Switch>
            </LoggedInLayout>
          </Router>
        </Provider>
    );
  }

}

export default App;

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
