import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { elapsedTime, getCultivarImage, getStrain, getMeasure, getForm } from '../../helpers';
//import Rating from '../../elements/Rating.jsx';
import Icon from '../../elements/Icon.jsx';

function mapStateToProps(state) {
  return {
    current_experience: state.experience.experience,
    strains: state.cultivar.strains,
    forms: state.cultivar.forms,
    measures: state.cultivar.measures,    
  };
}

class CollectionCultivarCard extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      strain: null,
      measure: null,
      form:null    
    }
  }

  componentDidMount(){
    if(this.props.collection){
      getStrain(this,this.props.strains,this.state.strain,this.props.collection.cultivar.strain_id)
      getMeasure(this, this.props.measures.collection, this.state.measure, this.props.collection.measure_id, this.props.collection.amount)
      getForm(this, this.props.forms, this.state.form, this.props.collection.cultivar.form_id)
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // clicked a new ad, or entered the ad view directly and ads array isn't loaded yet
    if(this.props.collection){
      getStrain(this,this.props.strains,this.state.strain,this.props.collection.cultivar.strain_id)
      getMeasure(this, this.props.measures.collection, this.state.measure, this.props.collection.measure_id, this.props.collection.amount)
      getForm(this, this.props.forms, this.state.form, this.props.collection.cultivar.form_id)
    }

    if(prevProps.strains !== this.props.strains){
      this.setState({strain:null})
      //getStrain(this,this.props.strains,this.state.strain,this.props.collection.cultivar.strain_id)
    }

  }


  render() {
    if(!this.state.strain || !this.state.measure || !this.state.form)
      return <React.Fragment/>;

    let collection = this.props.collection;
    let elapsed = (this.props.collection.last_used ? elapsedTime(this.props.collection.last_used,null,false) : null);
    //console.log(collection);
    if(collection){
      collection.cultivar.strain = this.state.strain;
      collection.cultivar.form = this.state.form;
      collection.measure = this.state.measure;
    }
    let intents = {"total":0,"intents":{}};
    for(var i=0;i<collection.experiences.length;i++){
      intents.total++;
      if(collection.experiences[i].intents.length > 0){
        if(typeof(intents.intents[collection.experiences[i].intents[0].id]) === 'undefined')
          intents.intents[collection.experiences[i].intents[0].id] = collection.experiences[i].intents[0];
        if(typeof(intents.intents[collection.experiences[i].intents[0].id].total) === 'undefined')
          intents.intents[collection.experiences[i].intents[0].id].total = 0;
        intents.intents[collection.experiences[i].intents[0].id].total++;
      }
    }
    return (
      <div className="col-6 col-md-4 p-1">
        <div className={"card cultivar_card "+(!this.props.current_experience ? 'no_current_experience' : 'has_current_experience' )} id={"cultivar"+collection.cultivar.id}>
          <Link to={"/collection/"+collection.id}><div className="cultivar_beauty_image py-4 text-center text-white" style={{backgroundImage:'url('+getCultivarImage(collection)+')'}}></div></Link>
          <div className="card-body p-1">
            <Link to={"/collection/"+collection.id}><h5 className="card-title cultivar_title text-uppercase mb-0 mt-1">{collection.cultivar.strain.name}</h5></Link>
            <div className="card-text">
              <Link to={"/collection/"+collection.id} className="small">
                <div>{collection.cultivar.form.name}</div>
                <p className="my-0 small text-left thc_amount">THC: { collection.thc_percent*1 }% &nbsp; CBD: { collection.cbd_percent*1 }%</p>
              </Link>
              <div className="d-none">
                {collection.status === 1
                  ?
                    <p className="my-0 py-0 text-center d-block">{ collection.amount*1 } {collection.measure[1]}</p>
                  :  
                    <p className="my-0 py-0 text-center text-danger d-block">You're out of it</p>
                }
              </div>
              <div className="pt-2">
              {
                Object.entries(intents.intents).map(
                  (intent,id) => {
                    return <Icon key={id} icon={intent[1].icon} title={intent[1].name+" Intent"} tiny={true} active={false} transparent={true} size={40} />
                  }
                )
              }
              </div>
              { elapsed &&
                <div className="small my-1 text-center d-none">last used {elapsed} ago</div>
              }
            </div>
            { !this.props.current_experience &&
              <div className="col text-center px-1" style={{position:"absolute",bottom:"5px",left:0}}>
                { collection.status === 1 &&
                  <Link to={"/experience/create/collection/"+collection.id} className="btn btn-sm btn-block small btn-success py-0">start session</Link>
                }
              </div>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(CollectionCultivarCard);
