import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { getCultivarImage } from '../../helpers.js';
import Rating from '../../elements/Rating.jsx';

function mapStateToProps(state) {
  return {
    current_experience: state.experience.experience
  };
}

class ExploreCultivarCard extends React.Component {

  render() {
    let cultivar = this.props.cultivar;
    return (
      <div className="col-6 col-md-4 col-lg-3 p-1">
        <div className={"card cultivar_card "+(!this.props.current_experience ? 'no_current_experience' : 'has_current_experience' )} id={"cultivar"+cultivar.id}>
          <div className="cultivar_beauty_image py-4 text-center text-white" style={{backgroundImage:'url('+getCultivarImage(cultivar)+')'}}>{cultivar.form.name}</div>
          <div className="card-body p-1">
            <h5 className="card-title cultivar_title my-1">{cultivar.strain.name}</h5>
            <div className="card-text">
              <p className="thc_amount">THC: { cultivar.thc_percent*1 }% CBD: { cultivar.cbd_percent*1 }%</p>
              <hr className="p-0 my-2"/>
              { cultivar.rating

                ?
                  <div className="d-flex justify-content-between">
                    <Rating rating={cultivar.rating} base={5} />
                  </div>
                :
                  <div className="text-center">No rating yet</div>
              }

            </div>
            { !this.props.current_experience &&
              <div className="col text-center" style={{position:"absolute",bottom:"5px",left:0}}>
                <Link to={"/experience/create/"+cultivar.id} className="btn btn-sm btn-wide small btn-success">Start new session</Link>
              </div>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(ExploreCultivarCard);
