import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { validateInputs, bindLinks, isTwentyOne, isEmail } from '../../helpers';
import { createAccount } from '../../api/account';

function mapStateToProps(state) {
  return {
    cordova_initialized: state.cordova.initialized,
  };
}

class SignupBox extends React.Component {

	constructor(props) {
		super(props);
		this.showPassword = this.showPassword.bind(this);
	    this.closeModal = this.closeModal.bind(this);
	    this.processSignup = this.processSignup.bind(this);

		this.state = {
			password_hidden:true,
			error: null,
			terms_checked: false,
			can_submit: false,
			submitting: false,
		}
	}

	componentDidMount(){
		if(this.props.cordova_initialized)
			bindLinks();
	}

	closeModal(){
		this.setState({'error':null});
		this.props.parent.setState({modal:null});
	}

	showPassword(e){
		e.preventDefault();
		this.setState({password_hidden:(!this.state.password_hidden) });
		
	}

	check(field,e){
		e.preventDefault();
		let that = this;
		let state = {};
		state[field] = (this.state[field] === false)
		this.setState(state,function(){
			that.validate();
		})
	}

	validate(e){
	    if(e)
	      e.preventDefault();
	    let required = ['signup_first_name','signup_last_name','signup_email','signup_password','signup_password_confirm','signup_birth_year','signup_birth_month','signup_birth_day','signup_terms_accepted'];
	    let validated = validateInputs(this,required);
	    if(validated){
			if(!isEmail(document.getElementById('signup_email').value) ){
			    this.setState({can_submit:false});
			    validated = false;	
			}
			else if(document.getElementById('signup_password').value !== document.getElementById('signup_password_confirm').value || document.getElementById('signup_password').value.length < 8){
			    this.setState({can_submit:false});
			    validated = false;	
			}
			else if(!isTwentyOne(document.getElementById('signup_birth_year').value + '-' + document.getElementById('signup_birth_month').value + '-' + document.getElementById('signup_birth_day').value)){
			    this.setState({can_submit:false});
			    validated = false;	
			}
		}
		return validated
	}

	processSignup(e){
		this.setState({'error':null});
		e.preventDefault();
	    if(this.state.submitting || !this.validate())
	      return false;
		let inputs = {};
		inputs['first_name'] = document.getElementById('signup_first_name').value;
		inputs['last_name'] = document.getElementById('signup_last_name').value;
		inputs['email'] = document.getElementById('signup_email').value;
		inputs['password'] = document.getElementById('signup_password').value;
		inputs['password_confirmation'] = document.getElementById('signup_password_confirm').value;
		inputs['birth_date'] = document.getElementById('signup_birth_year').value + '-' + document.getElementById('signup_birth_month').value + '-' + document.getElementById('signup_birth_day').value;
		if(document.getElementById('signup_terms_accepted').value == 1)
			inputs['terms_accepted'] = true;
		//console.log(inputs);
		createAccount(this,inputs);
	}

	render() {
		let years = [];
		let nowyear = (new Date()).getFullYear() - 21;
		let thenyear = nowyear-100;
		for(var i=nowyear;i>=thenyear;i--)
			years.push(i);
	    return (
            <div className={"modal-slideout slidingOut"}>
              <div className="modal-guts p-4 text-dark">
                <Link to={"#"} className="float-right" onClick={this.closeModal}>X</Link>
                <h3>Signup</h3>
                <p>Enter your information to create your account</p>

	            <form className="form" method="POST" onSubmit={this.processSignup}>
	                <div className="form-group">
		                <label htmlFor="signup_first_name">First Name</label>
						<input id="signup_first_name" name="first_name" type="text" className="form-control" required="" autoFocus onChange={this.validate.bind(this)} />
	                </div>
	                <div className="form-group">
		                <label htmlFor="signup_last_name">Last Name</label>
						<input id="signup_last_name" name="last_name" type="text" className="form-control" required="" onChange={this.validate.bind(this)}  />
	                </div>
	                <hr/>
	                <div className="form-group">
		                <label htmlFor="signup_email">Email</label>
						<input id="signup_email" name="email" type="email" className="form-control" required="" onChange={this.validate.bind(this)} />
	                </div>
	                <div className="form-group">
	                    <label htmlFor="login_password">Password <small>(min 8 chars)</small></label>
	                    <div className="input-group">
	                        <input name="password" id="signup_password" type={this.state.password_hidden ? "password" : "text"} className="form-control" required="" style={{borderRight:'none'}} onChange={this.validate.bind(this)} />
							<div className="input-group-append" style={{border:'none'}}>
		                        <span className="input-group-text bg-white" style={{borderLeft:'none'}}><Link to={"#"} onClick={this.showPassword} title="show/hide password" className="showPassword">{this.state.password_hidden ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</Link></span>
		                    </div>
	                    </div>
	                </div>
	                <div className="form-group">
	                    <label htmlFor="login_password_confirm">Confirm Password</label>
	                    <div className="input-group">
	                        <input name="password_confirm" id="signup_password_confirm" type={this.state.password_hidden ? "password" : "text"} className="form-control" required="" style={{borderRight:'none'}} onChange={this.validate.bind(this)} />
							<div className="input-group-append" style={{border:'none'}}>
		                        <span className="input-group-text bg-white" style={{borderLeft:'none'}}><Link to={"#"} onClick={this.showPassword} title="show/hide password" className="showPassword">{this.state.password_hidden ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</Link></span>
		                    </div>
	                    </div>
	                </div>
	                <hr/>
	                <div className="row">
	                	<div className="col-12">
			                <label htmlFor="signup_birth_month">Birthdate</label>
	    				</div>
	                	<div className="col-4">
			                <div className="form-group">
								<select id="signup_birth_month" name="birth_month" className="form-control" required="" onChange={this.validate.bind(this)} >
									<option value="">MM</option>
	                          		<option value="01">Jan</option>
	                          		<option value="02">Feb</option>
	                          		<option value="03">Mar</option>
	                          		<option value="04">Apr</option>
	                          		<option value="05">May</option>
	                          		<option value="06">Jun</option>
	                          		<option value="07">Jul</option>
	                          		<option value="08">Aug</option>
	                          		<option value="09">Sep</option>
	                          		<option value="10">Oct</option>
	                          		<option value="11">Nov</option>
	                          		<option value="12">Dec</option>
								</select>
			                </div>
		                </div>
	                	<div className="col-4">
			                <div className="form-group">
								<select id="signup_birth_day" name="birth_day" className="form-control" required="" onChange={this.validate.bind(this)} >
									<option value="">DD</option>
									{ 
										['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'].map(
					                        (value,i) => {  
					                          	return (
					                          		<option key={i} value={value}>{value}</option>
				                          		)
				                          	}
				                        )
									}
								</select>
			                </div>
		                </div>
	                	<div className="col-4">
			                <div className="form-group">
								<select id="signup_birth_year" name="birth_year" className="form-control" required="" onChange={this.validate.bind(this)} >
									<option value="">YYYY</option>
									{ 
										years.map(
					                        (value,i) => {  
					                          	return (
					                          		<option key={i} value={value}>{value}</option>
				                          		)
				                          	}
				                        )
									}
								</select>
			                </div>
		                </div>
	                	<div className="col-12">
	                		You must be 21+ to sign up for Eighthnote
			            </div>
		            </div>
	                <div className="form-check my-4">
						<input id="signup_terms_accepted" name="terms_accepted" type="hidden" value={this.state.terms_checked ? 1 : 0} required="" />
	                	<span onClick={this.check.bind(this,'terms_checked')}>
							<i className={"mr-2 " + (this.state.terms_checked ? "fas fa-check-square" : "far fa-square")}></i> I accept the
						</span> <a href="https://eighthnote.co/terms-of-service" target="_blank"><u>terms and conditions</u></a>
	                </div>

	                { this.state.error &&
		                <div className="text-danger text-center my-3">
		                	{ this.state.error }
		                </div>
		            }

		            <div className="text-center">
		              	<div className="py-2 px-0">
		                	<button type="submit"  disabled={this.state.submitting || !this.state.can_submit} className="btn btn-lg btn-success btn-block text-uppercase">Create Account</button>
		              	</div>
		              	<div className="py-2 px-4">
		                	<Link to={'#'} onClick={this.closeModal} className="text-dark text-uppercase">Cancel</Link>
		              	</div>        
	            	</div>        
	            </form>

              </div>
            </div>
	    );
	}
}

export default connect(mapStateToProps)(SignupBox);
