import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
//import { Link } from 'react-router-dom'

import { getCollection } from '../api/collection';
import { getCultivarOptions } from '../api/cultivar';
import { getCurrentExperience, getExperienceOptions, getExperiences } from '../api/experience';

import Loading from '../elements/Loading.jsx';
import PageHeader from '../elements/headers/PageHeader.jsx';

import Sidebar from '../elements/navs/Sidebar';
import Dock from '../elements/navs/Dock';
import FeedbackModal from '../elements/feedback/FeedbackModal';


function mapStateToProps(state) {
  return {
    initialized: state.main.initialized,
    viewport: state.main.viewport,
    device_id: state.main.device_id,
    device_uuid: state.main.device_uuid,
    logged_in_user: state.account.user,
    collection: state.collection.collection,
    current_experience: state.experience.experience,
    has_internet: state.main.has_internet,
    no_scroll: state.main.no_scroll,
    feedback_modal: state.main.feedback_modal,
  };
}

class LoggedInLayout extends React.Component {

  goBack() {
    window.history.back();
  }

  componentDidMount() {
    window.scrollTo(0,0);
    window.jQuery('.navbar-collapse.in').collapse('hide');
    let last_pathname = null;

    if(localStorage.getItem('last_pathname')){
      last_pathname = localStorage.getItem('last_pathname');
      localStorage.removeItem('last_pathname');
      this.props.history.push(last_pathname);
    }

    this.unlisten = this.props.history.listen((location, action) => {
      //console.log(location.pathname);
      window.scrollTo(0,0);
      window.jQuery('.navbar-collapse.in').collapse('hide');
      localStorage.setItem('last_pathname',location.pathname);
    });

    getCultivarOptions(this);
    getCollection(this);
    getExperiences(this);
    getExperienceOptions(this);
    getCurrentExperience(this);
  }

  componentWillUnmount() {
      this.unlisten();
  }

  render() {
    let notice_count = 0;
    if(this.props.logged_in_user && !this.props.logged_in_user.email_verified_at)
      notice_count++;
    if(this.props.current_experience)
      notice_count++;
    if(!this.props.has_internet)
      notice_count++;

    return (
      <div className={"app loggedin viewport-"+this.props.viewport+" "+(this.props.logged_in_user && !this.props.logged_in_user.email_verified_at ? "unverified_email" : "")+" "+(this.props.current_experience ? "active_experience" : "")+" "+(this.props.has_internet ? "has_internet has_internet_"+this.props.has_internet : "has_no_internet")+" notice_count_"+notice_count} >
        <PageHeader />
        <div id="pageContainer" className="container">
          <div id="pageContent" className="row">
            <div id="sidebar" className="col-lg-3 d-none d-lg-block">
              { (this.props.viewport === 'lg' || this.props.viewport === 'xl') &&
                <Sidebar />
              }
            </div>
            <div className="col-12 col-lg-9 pl-lg-4">
              <div className="row">
              {
                !this.props.initialized

                ?
                  <Loading/>
                :
                  this.props.children
              }
              </div>
            </div>
          </div>
        </div>

        { this.props.feedback_modal &&
          <FeedbackModal location={this.props.location} />
        }
        
        <Dock/>

        <div id="viewportXS" className="d-block d-sm-none" />
        <div id="viewportSM" className="d-none d-sm-block d-md-none" />
        <div id="viewportMD" className="d-none d-md-block d-lg-none" />
        <div id="viewportLG" className="d-none d-lg-block d-xl-none" />
        <div id="viewportXL" className="d-none d-xl-block" />
      </div>
    );
  }
}

export default connect(mapStateToProps)(withRouter(LoggedInLayout));
