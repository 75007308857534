import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

function mapStateToProps(state) {
  return {
    strains: state.cultivar.strains,
    forms: state.cultivar.forms,
  };
}

class CultivarBatch extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      autocomplete_search: false,
      selfgrown: (this.props.selectedBrand && this.props.selectedBrand === 'Self-Grown' ? true : false)
    }

  }

  setSelfGrown(e){
    let selfgrown = this.state.selfgrown ? false : true;
    this.setState({selfgrown:selfgrown},function(){
      if(!selfgrown && document.getElementById('cultivar_brand').value === 'Self-Grown')
        document.getElementById('cultivar_brand').value = "";      
    });

  }  

  render() {
    return (
      <div className="row">
        { !this.state.selfgrown

          ?

            <React.Fragment>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Brand</label>
                  <input type="text" id="cultivar_brand" className="form-control" defaultValue={this.props.selectedBrand} autoComplete="off" />
                  <div className="small mt-2">Who is the grower (or brand) of this cultivar?</div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-group">
                  <label>Batch #</label>
                  <input type="text" id="cultivar_batch" className="form-control" defaultValue={this.props.selectedBatch} autoComplete="off" />
                  <div className="small mt-2">Batch # can be found as a barcode on the packaging</div>
                </div>
              </div>
            </React.Fragment>

          :

            <React.Fragment>
              <input type="hidden" id="cultivar_brand" defaultValue="Self-Grown" />
              <input type="hidden" id="cultivar_batch" defaultValue="" />
            </React.Fragment>
        }

        <div className="col-12 pt-0 pb-4">
          <Link to={"#"} onClick={this.setSelfGrown.bind(this)} style={{fontWeight:"100"}}><i className={"far fa"+(this.state.selfgrown ? '-check' : '')+"-square"}></i> This is a self-grown cultivar</Link>
        </div>

      </div>
    );
  }

}

export default connect(mapStateToProps)(CultivarBatch);
