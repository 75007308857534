
var cordovaLinks = {

	onDeviceReady: function(){
		//console.log('cordovaLinks deviceready');
		cordovaLinks.initialize();
	},

	initialize: function(){
	    if(typeof(window.universalLinks) !== 'undefined'){
	      window.universalLinks.subscribe('eighthNoteEvent', function(eventData){
	        //console.log('Did launch application from the link: ' + eventData.path);
	        window.location.hash = eventData.path;
	      });
	    }			
	}
	
}

export default cordovaLinks;