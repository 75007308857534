import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Loading from '../Loading';
import { getQuestion, saveResponse } from '../../api/question.js';

function mapStateToProps(state) {
  return {

  };
}

class Question extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      question: null,
      show_answer: false,
      submitting: false
    }
  }

  answerQuestion(e){
    e.preventDefault();
    this.setState({show_answer:true});
  }

  submitResponse(e){
    e.preventDefault();
    saveResponse(this,document.getElementById('answer_input').value);
  }

  componentDidMount(){
    getQuestion(this);
  }

  render() {
    return (

      <React.Fragment>

        { this.state.question
          
          ?

            <React.Fragment>
              <hr/>
              <div className="row">
                <div className="col-12 pb-2">

                  <p>{ this.state.question.question }</p>
                  { this.state.show_answer

                    ?

                      <div>
                        <textarea id="answer_input" className="form-control my-3" rows="3" defaultValue={this.state.question.response ? this.state.question.response.response : ""} disabled={this.state.submitting} />

                          <button onClick={this.submitResponse.bind(this)} className="btn btn-sm btn-success text-uppercase"  disabled={this.state.submitting}>{this.state.question.response ? 'Update' : 'Submit'} Answer</button>
                      </div>

                    :

                      <div>
                        <Link to={'#'} onClick={this.answerQuestion.bind(this)} className="btn btn-sm btn-success text-uppercase">Answer</Link>
                      </div>
                  }
                </div>
              </div>
            </React.Fragment>

          :

            <Loading text="Getting question" />

        }

      </React.Fragment>

    );
  }
}

export default connect(mapStateToProps)(Question);
