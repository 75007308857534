import React from 'react';

class Loading extends React.Component {

  render() {
    return (
	    	<div className="col-12 text-center" key={this.props.key_id}>
	        	<div className="loading" style={ {paddingTop:"10px"} }>
	        		{ !this.props.text 
	        			?
			        		<i className="fas fa-circle-notch fa-spin"></i>
			        	:
			        		<span>{this.props.text}...</span>
	        		}
	        	</div>
	      	</div>
    );
  }
}

export default Loading;
