import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { sendResetCode, sendPasswordChange } from '../../api/account';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
  };
}

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);

    this.sendResetCode = this.sendResetCode.bind(this);
    this.sendPasswordChange = this.sendPasswordChange.bind(this);
    this.checkIsEmailPopulated = this.checkIsEmailPopulated.bind(this);
    this.checkIsNewPasswordPopulated = this.checkIsNewPasswordPopulated.bind(this);

    this.state = {
      error:null,
      success:false,
      sending:false,
      fields_populated: false,
      email:null,
      code_received:false
    };

  }

  sendResetCode(e){
    e.preventDefault();
    this.setState({sending:true});
    let email     = document.getElementById("login_email").value;
    this.setState({email:email});
    sendResetCode(this,email);
  }

  checkIsEmailPopulated(e){
    if(!this.state.fields_populated){
      if(document.getElementById("login_email").value !== '')
        this.setState({fields_populated:true});     
    }
    else if(this.state.fields_populated){
      if(document.getElementById("login_email").value === '')
        this.setState({fields_populated:false});      
    }
  }

  sendPasswordChange(e){
    e.preventDefault();
    this.setState({sending:true});
    let email     = document.getElementById("login_email").value;
    let code      = document.getElementById("reset_code").value;
    let password  = document.getElementById("new_password").value;
    sendPasswordChange(this,email,code,password);
  }

  checkIsNewPasswordPopulated(e){
    let email     = document.getElementById("login_email").value;
    let code      = document.getElementById("reset_code").value;
    let new_password  = document.getElementById("new_password").value;
    let confirm_password  = document.getElementById("confirm_password").value;
    if(email !== '' && code.length === 6 && new_password === confirm_password && new_password !== ""){
      if(!this.state.fields_populated)
        this.setState({fields_populated:true});     
    }
    else{
      if(this.state.fields_populated)
        this.setState({fields_populated:false});      
    }
    document.getElementById("reset_code").value = code.toUpperCase().replace(" ","");
  }


  componentDidMount(){

  }

  render() {
    return (
        <main className="col-12 col-md-8 offset-md-2">

        <br/><br/>

            <div className="row">
              <div className="col-lg-12">
                <h2 className="text-uppercase">Password Reset</h2>
              </div>
              {this.state.code_received 

                ?

                  <div className="col-lg-12">
                    <p className="text-success">Check your email and enter the 6-character reset code below</p>
                    <input id="login_email" name="email" type="hidden" required="" value={this.state.email} />
                    <div className="form-group">
                      <input id="reset_code" name="reset_code" type="text" autoComplete="off" className="form-control input-lg" placeholder="CODE" required="" style={{border:'none'}} onKeyUp={this.checkIsNewPasswordPopulated} />
                    </div>
                    <hr/>
                    <div className="form-group">
                      <input id="new_password" name="new_password" type="password" autoComplete="off" className="form-control" placeholder="New Password" required="" style={{border:'none'}} onKeyUp={this.checkIsNewPasswordPopulated} />
                    </div>
                    <div className="form-group">
                      <input id="confirm_password" name="confirm_password" type="password" autoComplete="off" className="form-control" placeholder="Confirm Password" required="" style={{border:'none'}} onKeyUp={this.checkIsNewPasswordPopulated} />
                    </div>
                    <div>
                      <button className="btn btn-success btn-block" disabled={this.state.sending || this.state.fields_populated !== true}  onClick={this.sendPasswordChange} >Update Password</button> &nbsp; 
                      { this.state.sending &&
                          <i className="fa fa-spinner fa-spin text-primary"></i>
                      }
                    </div>
                  </div>

                :

                  <div className="col-lg-12">
                    <p className="text-success">Enter your email here and we will send a password reset code to your inbox.</p>
                    <div className="form-group">
                      <div className="input-group">
                        <div className="input-group-prepend" style={{border:'none'}}>
                          <span className="input-group-text bg-white" style={{border:'none'}}><span className="fa fa-envelope"></span></span>
                        </div>
                        <input id="login_email" name="email" type="email" autoComplete="off" className="form-control" placeholder="Email" required="" defaultValue={this.state.email} autoFocus style={{border:'none'}} onKeyUp={this.checkIsEmailPopulated} />
                      </div>
                    </div>
                    <div>
                      <button className="btn btn-success btn-block" disabled={this.state.sending || this.state.fields_populated !== true}  onClick={this.sendResetCode} >Send Reset Code</button> &nbsp; 
                      { this.state.sending &&
                          <i className="fa fa-spinner fa-spin text-primary"></i>
                      }
                    </div>
                  </div>

              }
              <div className="col-lg-12">
                <div>
                  <div className="form-group">
                    {
                      this.state.error &&
                        <div className="text-danger mt-2 py-1 px-2">{ this.state.error }</div>
                    }
                    {
                      this.state.success &&
                        <div className="text-success mt-2 py-1 px-2">{ this.state.success }</div>
                    }
                  </div>
                </div>

                <div className="text-center p-2">
                  <Link to="/login" className="text-white">Return to login</Link>
                </div>

              </div>
            </div>
          
        </main>
    );
  }
}

export default connect(mapStateToProps)(ForgotPassword);
