import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
  	moods: state.experience.moods
  };
}

class MoodsModal extends React.Component {

	constructor(props) {
		super(props);
	    this.closeModal = this.closeModal.bind(this);

		this.state = {
			moods: {}
		}
	}

	componentDidMount(){
		//console.log('componentDidMount');
		let moods = {}
		let that = this;
	    this.props.moods.map(
	      (mood,i) => {  
	      	moods[mood.id] = (that.props.parent.state.moods && typeof(that.props.parent.state.moods[mood.id]) !== 'undefined' ? true : false);
	      	return "";
	      }
	    )
	    this.setState({moods:moods});

	    Analytics.log('/experience/moods');
	}

	closeModal(){
		this.setState({'error':null});
		this.props.parent.setState({modal:null});
	}

	selectMood(id,e){
		e.preventDefault();
		let moods = {...this.state.moods};
		if(moods[id])
			moods[id]=false;
		else
			moods[id]=true;
	    this.setState({moods:moods});
	    let selectedMoods = {};
		Object.entries(moods).map(
			(value,id) => {
				if(value[1])
					selectedMoods[parseInt(value[0])] = parseInt(value[0]);
				return "";
			}
		);
		if(Object.keys(selectedMoods).length === 0)
			selectedMoods = null;
	    this.props.parent.setState({moods:selectedMoods});
//		this.props.parent.setState({'error':null});
	}

	setNotification(e){
		this.setState({'error':null});
		e.preventDefault();
	}

	render() {
	    return (
            <div className={"modal-slideout slidingOut"}>
              <div className="modal-guts p-4 text-dark">
                <Link to={"#"} className="float-right" onClick={this.closeModal}>X</Link>
                <h4 className="text-uppercase">Current Mood</h4>
                <p>How are you feeling right now as you begin your session?</p>
                <br/>
                <h5>Negative Associations</h5>
                  { 
                    this.props.moods.map(
                      (mood,i) => {  
                      	if(mood.type === 'negative'){
	                        return (
	                          <span key={i} className={"mood badge m-1 py-2 px-3 "+(this.state.moods[mood.id] ? 'bg-success selected' : 'bg-light')} onClick={this.selectMood.bind(this,mood.id)}>{mood.name}</span>
	                        );
	                    }
	                    return "";
                      }
                    )
                  }
                <br/><br/>
                <h5>Positive Associations</h5>
                  { 
                    this.props.moods.map(
                      (mood,i) => {  
                      	if(mood.type === 'positive'){
	                        return (
	                          <span key={i} className={"mood badge m-1 py-2 px-3 "+(this.state.moods[mood.id] ? 'bg-success selected' : 'bg-light')} onClick={this.selectMood.bind(this,mood.id)}>{mood.name}</span>
	                        );
	                    }
	                    return "";
                      }
                    )
                  }

	            <br/><br/>
				<button onClick={this.closeModal} className="btn btn-sm btn-success btn-block text-uppercase">Done</button>

              </div>
            </div>
	    );
	}
}

export default connect(mapStateToProps)(MoodsModal);
