import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'
import Icon from '../../elements/Icon.jsx';

function mapStateToProps(state) {
  return {
    viewport: state.main.viewport,
    no_scroll: state.main.no_scroll,
    intents: state.experience.intents,
    measures: state.cultivar.measures,
  };
}

class CultivarHeader extends React.Component {

  componentDidMount(){

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
  }

  componentWillUnmount(){
  }

  render() {
    return (
      <React.Fragment>
          <div className="cultivar_beauty_image top_image text-center py-4" style={{backgroundImage:'url('+this.props.cultivar.image+')'}}>
            <div className="d-inline-block">
              { this.props.cultivar.method &&
                <Icon icon={this.props.cultivar.method.icon} title={this.props.cultivar.method.name+" Method"} padded={true} active={true} size={150} />
              }
            </div>
          </div>
          <h3 className="mt-2 mb-0 p-0">{this.props.cultivar.strain}</h3>
          <div className="mb-2">
            <div>
              { this.props.cultivar.brand &&
                <span className="mr-3">{this.props.cultivar.brand}</span>
              }
              { this.props.cultivar.batch &&
                <span className="mr-3">#{this.props.cultivar.batch}</span>
              }
            </div>
            <div>
              { this.props.cultivar.thc_percent && 
                <span className="mr-2">THC: {parseFloat(this.props.cultivar.thc_percent)}%</span>
              }
              { this.props.cultivar.cbd_percent && 
                <span className="mr-2">CBD: {parseFloat(this.props.cultivar.cbd_percent)}%</span>
              }
            </div>
            <div className="">
               <h6 className="my-2 mb-4 text-uppercase">{ this.props.cultivar.amount }</h6>
            </div>
          </div>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(CultivarHeader);
