import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import CultivarExperiencesList from '../../elements/cultivar/CultivarExperiencesList.jsx'
import Icon from '../../elements/Icon.jsx';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    viewport: state.main.viewport,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
    current_experience: state.experience.experience,
    experiences: state.experience.experiences
  };
}

class Sidebar extends React.Component {

  render() {
    return (
          <div id="sidebar" className="row">
            <div className="col-12 text-left">
                  <hr/>
                  <Link to={"/"} className="d-block my-0">
                    <Icon icon="home.svg" title="Go Home" transparent={true} tiny={true} size="28" />
                    <span className="ml-2">Home</span>
                  </Link>
                  <hr/>
                  <Link to={"/experience"+(this.props.current_experience ? '/active' : '/create')} className="d-block my-0">
                    { this.props.current_experience 

                      ?

                        <Icon icon="record.svg" title="Go Home" transparent={true} tiny={true} record={this.props.current_experience} size="28" />

                      :

                        <Icon icon="plus.svg" title="Go Home" transparent={true} tiny={true} size="28" />

                    }
                    <span className="ml-2">Session</span>
                  </Link>
                  <hr/>
                  <Link to={"/account"} className="d-block my-0">
                    <Icon icon="profile.svg" title="Manage Profile" transparent={true} tiny={true} size="28" />
                    <span className="ml-2">Profile</span>
                  </Link>
                  <hr/>

                  <br/><br/>

                  {this.props.experiences && this.props.experiences.length > 0 &&
                    <React.Fragment>
                      <strong>Your Recent Sessions</strong>
                      <CultivarExperiencesList experiences={this.props.experiences} limit={10} condensed={true} />
                    </React.Fragment>
                  }
            </div>
          </div>
    );
  }
}

export default connect(mapStateToProps)(Sidebar);
