import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { actionSetFeedbackModal } from '../../redux/actions/main';
import Icon from '../../elements/Icon.jsx';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    viewport: state.main.viewport,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
    current_experience: state.experience.experience,
    feedback_modal: state.main.feedback_modal,
  };
}

class Dock extends React.Component {

  toggleFeedback(e){
    e.preventDefault()
    this.props.dispatch(actionSetFeedbackModal(true))
  }

  render() {
    return (
      <div id="dock">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <div className="row text-center">

                <div className="col text-center">
                  <Link to={"/"} className="text-center d-block">
                    <Icon icon="home.svg" title="Go Home" transparent={true} tiny={true} size="34" />
                    <br/>
                    Home
                  </Link>
                </div>

                <div className="col text-center">
                  <Link to={"/experience"+(this.props.current_experience ? '/active' : '/create')} className="text-center d-block">
                    { this.props.current_experience 

                      ?

                        <Icon icon="record.svg" title="Active Session" transparent={true} tiny={true} record={this.props.current_experience} size="34" />

                      :

                        <Icon icon="plus.svg" title="Start Session" transparent={true} tiny={true} size="34" />

                    }
                    <br/>
                    Session
                  </Link>
                </div>

                <div className="col text-center d-none">
                  <Link to={"/explore"} className="text-center d-block">
                    <div className="icon">
                      <i className="fas fa-search"></i>
                    </div><br/>
                    Explore
                  </Link>
                </div>

                <div className="col text-center">
                  <Link to={"/account"} className="text-center d-block">
                    <Icon icon="profile.svg" title="Manage Profile" transparent={true} tiny={true} size="34" />
                    <br/>
                    Profile
                  </Link>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Dock);
