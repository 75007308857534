import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
  	pairings: state.experience.pairings
  };
}

class PairingsModal extends React.Component {

	constructor(props) {
		super(props);
	    this.closeModal = this.closeModal.bind(this);

		this.state = {
			pairings: {}
		}
	}

	componentDidMount(){
		//console.log('componentDidMount');
		let pairings = {}
		let that = this;
	    this.props.pairings.map(
	      (pairing,i) => {  
	      	pairings[pairing.id] = (that.props.parent.state.pairings && typeof(that.props.parent.state.pairings[pairing.id]) !== 'undefined' ? true : false);
	      	return "";
	      }
	    )
	    this.setState({pairings:pairings});

	    Analytics.log('/experience/pairings');
	}

	closeModal(){
		this.setState({'error':null});
		this.props.parent.setState({modal:null});
	}

	selectPairing(id,e){
		e.preventDefault();
		let pairings = {...this.state.pairings};
		if(pairings[id])
			pairings[id]=false;
		else
			pairings[id]=true;
	    this.setState({pairings:pairings});
	    let selectedPairings = {};
		Object.entries(pairings).map(
			(value,id) => {
				if(value[1])
					selectedPairings[parseInt(value[0])] = parseInt(value[0]);
				return "";
			}
		);
		if(Object.keys(selectedPairings).length === 0)
			selectedPairings = null;
	    this.props.parent.setState({pairings:selectedPairings});
//		this.props.parent.setState({'error':null});
	}

	setNotification(e){
		this.setState({'error':null});
		e.preventDefault();
	}

	render() {
	    return (
            <div className={"modal-slideout slidingOut"}>
              <div className="modal-guts p-4 text-dark">
                <Link to={"#"} className="float-right" onClick={this.closeModal}>X</Link>
                <h4 className="text-uppercase">Pairings</h4>
                <br/>
                  { 
                    this.props.pairings.map(
                      (pairing,i) => {  
                        return (
                          <div key={i} className={"col-12 text-left my-2 py-2 px-3 "+(this.state.pairings[pairing.id] ? 'bg-info selected' : 'bg-light')}>
                            <div className={"pairing"} onClick={this.selectPairing.bind(this,pairing.id)}>
                            	<h6 className="p-0 m-0 text-uppercase">{pairing.name}</h6>
                            	{pairing.description}
                            </div>
                          </div>
                        );
                      }
                    )
                  }
                <br/><br/>
				<button onClick={this.closeModal} className="btn btn-sm btn-success btn-block text-uppercase">Done</button>

              </div>
            </div>
	    );
	}
}

export default connect(mapStateToProps)(PairingsModal);
