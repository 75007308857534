import React from 'react';
import { connect } from 'react-redux';

function mapStateToProps(state) {
  return {
    is_app: state.cordova.initialized,
    viewport: state.main.viewport
  };
}

class Icon extends React.Component {

  render() {
    let size = (this.props.size ? this.props.size : 48)+"px";
    let type = (this.props.type ? " type" : "");
    let solid = (this.props.solid ? " solid" : "");
    let transparent = (this.props.transparent ? " transparent" : "");
    let active = (this.props.active ? " active" : "");
    let inactive = (this.props.inactive ? " inactive" : "");
    let record = (this.props.record ? " record" : "");
    let padded = (this.props.tiny ? " tiny" : "");
    let tiny = (this.props.padded ? " padded" : "");
    let title = (this.props.title ? this.props.title : this.props.icon);
    let url = (!this.props.is_app ? "/" : "")+"sprites/"+this.props.icon;
    return (
    	<div className={"method_icon"+type+solid+transparent+active+inactive+padded+tiny+record} title={title} style={{width:size,height:size}}>
    		<div className="border_box">
    			<div className="icon_box" style={{WebkitMaskImage:"url("+url+")",maskImage:"url("+url+")"}} />
			</div>
		</div>
    );
  }
}

export default connect(mapStateToProps)(Icon);
