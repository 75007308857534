import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

function mapStateToProps(state) {
  return {
    strains: state.cultivar.strains,
    forms: state.cultivar.forms,
  };
}

class CultivarSelector extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      form: (this.props.selectedForm ? this.props.selectedForm : ['','choose form']),
      autocomplete_search: false,
      search_strain: false,
      search_form: false,
      unknown_checked: false
    }

  }

  searchStrains(e){
    e.preventDefault();
    let autocomplete_search = document.getElementById('cultivar_name').value;
    if(autocomplete_search.length > 1 && autocomplete_search !== " " && autocomplete_search !== "  ")
      this.setState({autocomplete_search:autocomplete_search,search_strain:autocomplete_search},function(){this.props.parent.validate(null)});
    else
      this.setState({autocomplete_search:null,search_strain:false},function(){this.props.parent.validate(null)});
  }

  setUnknown(e){
    let unknown_checked = this.state.unknown_checked ? false : true;
    this.setState({unknown_checked:unknown_checked});
    if(unknown_checked)
      this.setStrainName('Unknown Strain',e);
    else
      this.setStrainName('',e);
    document.getElementById('cultivar_name').readOnly = unknown_checked;
  }

  setBlur(e){
    e.preventDefault();
    let that = this;
    setTimeout(function(){
      that.setState({autocomplete_search:null},function(){this.props.parent.validate(null)});
    },150)
  }

  setStrainName(name,e){
    e.preventDefault();
    document.getElementById('cultivar_name').value = name;
    this.setState({autocomplete_search:null,search_strain:name},function(){this.props.parent.validate(null)});
  }  

  setForm(id,name,e){
    e.preventDefault();
    if(id && name && parseInt(id) > 0){
      this.setState({search_form:name,form:[id,name]},function(){this.props.parent.validate(null)});
    }
    else
      this.setState({search_form:false,form:['','choose form']},function(){this.props.parent.validate(null)});
  }  

  render() {
    let that = this;

    return (
      <div className="row">
        <div className="col-12">
          <div className="form-group">
            <label>Cultivar Strain &amp; Form *</label>
            <div className="input-group">
              <input type="hidden" id="cultivar_form_id" value={this.state.form[0]} />
              <input type="text" id="cultivar_name" className="form-control" placeholder="strain/cultivar name" defaultValue={this.props.selectedStrain ? this.props.selectedStrain : ''} autoComplete="off" onKeyUp={this.searchStrains.bind(this)} onBlur={this.setBlur.bind(this)} />
              <div className="input-group-append">
                <button className={"dropdown-toggle text-right btn "+(!this.state.form[0] ? "btn-outline-secondary" : "btn-secondary")} style={{fontSize:"12px",minWidth:"90px"}} type="button" data-toggle="dropdown" aria-expanded="false">{this.state.form[1]}</button>
                <div className="dropdown-menu">
                  { this.props.forms &&
                    this.props.forms.map(
                      (form,i)=>{
                        return (
                          <Link key={i} to={"#"} onClick={this.setForm.bind(this,form.id,form.name)} className="dropdown-item" href="#">{form.name}</Link>
                        );
                      }
                    )
                  }
                </div>
              </div>
            </div>
            <div style={{position:"relative"}}>
              { !this.props.hideUnknown && 
                <p className="pt-2 px-2"><Link to={"#"} onClick={this.setUnknown.bind(this)}><i className={"far fa"+(this.state.unknown_checked ? '-check' : '')+"-square"}></i> I don't know the strain name</Link></p>
              }
              { this.state.autocomplete_search &&
                <div id="strainNameSuggest">
                  { this.props.strains &&
                    <React.Fragment>
                      <Link to={"#"} className="suggested pl-0" onClick={this.setStrainName.bind(this,this.state.autocomplete_search)}>Start Session with <em>{this.state.autocomplete_search}</em></Link>
                      <div className="small">From Search</div>
                      { 
                        this.props.strains.map(
                          (strain,i)=>{
                            if(that.state.autocomplete_search && strain.name.toLowerCase().includes(that.state.autocomplete_search.toLowerCase()) && strain.id > 1000 ){
                              return (
                                <Link to={"#"} className="suggested" key={i} onClick={this.setStrainName.bind(this,strain.name)}>{strain.name}</Link>
                              );
                            }
                            else{
                              return ("");
                            }
                          }
                        )
                      }
                    </React.Fragment>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

}

export default connect(mapStateToProps)(CultivarSelector);
