import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { actionSetHeaderTitle } from '../../redux/actions/main';

import Loading from '../../elements/Loading.jsx';
import CultivarHeader from '../../elements/headers/CultivarHeader.jsx';
import ExperienceSubHeader from '../../elements/headers/ExperienceSubHeader.jsx';
import FeelingsCirclePack from '../../elements/experience/FeelingsCirclePack.jsx';
import Notes from '../../elements/experience/Notes.jsx';

import { endExperience } from '../../api/experience';

import { getCultivarImage, getStrain, getMeasure, getForm, getMethod } from '../../helpers';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    logged_in_user: state.account.user,
    display_data: state.main.display_data,
    strains: state.cultivar.strains,
    measures: state.cultivar.measures,
    forms: state.cultivar.forms,
    feelings: state.experience.feelings,
    methods: state.experience.methods,
    experiences: state.experience.experiences,
    current_experience: state.experience.experience,
  };
}

class ExperienceFinalize extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      rating: null,
      strain: null,
      form: null,
      measure: null,
      method:null
    }
  }

  componentDidMount(){

    if(this.props.current_experience && this.props.strains && this.props.measures && this.props.forms && this.props.methods){
      Analytics.log('/experience/finalize');
      this.props.dispatch(actionSetHeaderTitle('Finalize Session')); 
      getMethod(this,this.props.methods,this.state.method,this.props.current_experience.consumed[0].method_id)
      getStrain(this,this.props.strains,this.state.strain,this.props.current_experience.consumed[0].cultivar.strain_id)
      getForm(this,this.props.forms,this.state.form,this.props.current_experience.consumed[0].cultivar.form_id)
      getMeasure(this, this.props.measures.experience, this.state.measure, this.props.current_experience.consumed[0].measure_id, this.props.current_experience.consumed[0].amount)
    }
    else
      this.props.history.push('/experience/active');
  }

  setRating(rating,e){
    e.preventDefault();
    this.setState({rating:rating});
    //console.log('setRating',rating);
  }

  endExperience(e){
    e.preventDefault();
    let data = {
      rating: this.state.rating,
      experience_id: this.props.current_experience.id,
    }
    //console.log('endExperience',data);
    endExperience(this,data);
  }

  render() {

    if(!this.props.current_experience || !this.state.strain || !this.state.measure || !this.state.form || !this.state.method)
      return (<Loading/>);

    let that = this;
    let consumed = this.props.current_experience.consumed[0];
    if(consumed){
      consumed.cultivar.strain = this.state.strain;
      consumed.cultivar.form = this.state.form;
      consumed.measure = this.state.measure;
      consumed.method = this.state.method;
    }

    let experience = this.props.current_experience
    let i;
    let feelings = {colors:experience.current_feelings.colors, data:[]};
    for(i=0;i<experience.current_feelings.data.length;i++){
      if(experience.current_feelings.data[i].color_index > 0)
        feelings.data.push(experience.current_feelings.data[i])
    }

    let notes_check_ins = [];
    for(i=0;i<experience.check_ins.length;i++){
      if(typeof(experience.check_ins[i].notes) !== 'undefined' && experience.check_ins[i].notes && experience.check_ins[i].notes !== "")
        notes_check_ins.push(experience.check_ins[i]);
    }

    return (
      <React.Fragment>


        <main className="col-12 bg-white">

          { !this.props.feelings

            ?

              <Loading/>

            :  

              <React.Fragment>
                <CultivarHeader cultivar={{
                  strain:consumed.cultivar.strain.name,
                  form:consumed.cultivar.form.name,
                  image:getCultivarImage(consumed),
                  method:consumed.method,
                  brand:(consumed.brand ? consumed.brand.name : null),
                  batch:(consumed.batch ? consumed.batch.identifier : null),
                  thc_percent:consumed.thc_percent,
                  cbd_percent:consumed.cbd_percent,
                }} parent={this} />

                <ExperienceSubHeader experience={experience} parent={this} />

                <hr className="wide" />

                <h4 className="text-uppercase text-no-weight">Your Final Experience</h4>

                { feelings.data.length > 0

                  ?

                    <FeelingsCirclePack feelings={feelings} editable={false} />
                  :

                    <div className="text-center text-lowercase py-4"><em>You did not update how you were feelings during this session</em></div>
                }

                <hr className="wide" />

                <h4 className="text-uppercase text-no-weight">Rate Your Experience</h4>
                <p>How well did this help you {experience.intents[0].name.toUpperCase()}?</p>

                <div className="d-flex justify-content-between">
                  { 
                    [1,2,3,4,5,6,7,8,9,10].map(
                      (value,i) => {
                        return (
                          <div key={i} style={{margin:"0 2px"}} className={"ranking-box "+(that.state.rating >= value ? 'bg-success' : '')} onClick={that.setRating.bind(that,value)}></div>
                        )
                      }
                    )

                  }
                </div>

                { notes_check_ins.length > 0 &&
                  <React.Fragment>
                    <hr className="wide" />
                    <Notes parent={this} experience={experience} editable={false} />
                  </React.Fragment>
                }

                <hr className="wide" />

                <div className="row">
                  <div className="col-8 text-center py-4">
                    <Link to={"#"} className="btn btn-lg btn-block btn-success text-uppercase" onClick={this.endExperience.bind(this)}>
                      Save
                    </Link>
                  </div>
                  <div className="col-4 text-center py-4">
                    <Link to={"/experience/active"} className="btn btn-lg btn-block btn-success btn-cancel px-0 text-uppercase">
                      Cancel
                    </Link>
                  </div>
                </div>

              </React.Fragment>
          }
          <br/>

        </main>


      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ExperienceFinalize);
