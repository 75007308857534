import cordovaApp from './cordovaApp';
import { actionSetHasInternet } from '../redux/actions/main.js';

var cordovaNetwork = {

	onDeviceReady: function(){
		//console.log('cordovaNetwork deviceready');
		cordovaNetwork.initialize();
	},

    states: {},

	initialize: function(){

	    cordovaNetwork.states[window.Connection.ETHERNET] = 'wifi';
	    cordovaNetwork.states[window.Connection.WIFI]     = 'wifi';
	    cordovaNetwork.states[window.Connection.CELL_2G]  = 'data';
	    cordovaNetwork.states[window.Connection.CELL_3G]  = 'data';
	    cordovaNetwork.states[window.Connection.CELL_4G]  = 'data';
	    cordovaNetwork.states[window.Connection.CELL]     = 'data';
	    cordovaNetwork.states[window.Connection.UNKNOWN]  = 'data';
	    cordovaNetwork.states[window.Connection.NONE]     = false;

	    if( typeof(window.navigator.connection) !== 'undefined'){
	        document.addEventListener("offline", function(){
	            cordovaApp.store.dispatch(actionSetHasInternet(false));
	        }, false);
	        document.addEventListener("online", function(){
	            cordovaNetwork.checkInternetConnection();
	        }, false);
	        this.checkInternetConnection();
	    }
	},

    checkInternetConnection: function(){
	    let networkState = window.navigator.connection.type;

        if( typeof(cordovaNetwork.states[networkState]) === 'undefined' )
            cordovaApp.store.dispatch(actionSetHasInternet(false));
        else 
            cordovaApp.store.dispatch(actionSetHasInternet(cordovaNetwork.states[networkState]));
    },
	
}

export default cordovaNetwork;