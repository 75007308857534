import { isJsonString, getUrlParameter } from './helpers.js'
import axios from 'axios';

var Analytics = {

	ga_event_category:'app',
	ga_event_action:'load',
	ga_event_label: null,

	ga_trackers: ['UA-48328262-2'],
	ga_initialized: false,
	ga_event_logged: false,


	ga_mp: {
		universal: {
			v: 1,
			cid: localStorage.getItem('device_id'),
			uid: ( isJsonString(localStorage.getItem('logged_in_user')) && JSON.parse(localStorage.getItem('logged_in_user')) ? JSON.parse(localStorage.getItem('logged_in_user')).id : null),
			ua: window.navigator.userAgent,
			sr: (window.screen.width+"x"+window.screen.height),
			vp: (window.innerWidth+"x"+window.innerHeight),
			sd: window.screen.colorDepth,
			de: document.charset,
			ul: window.navigator.language
		},

		website: {
			ds: 'web', // document source
			dr: document.referrer, // document referrer
			dh: window.location.hostname // document host
		},

		app: {
			ds: 'app',
			an: 'EighthNote',
			aid: 'app.eighthnote.prod',
			av: '1.0'
		},

		utm: {
			cn: getUrlParameter('utm_campaign'),
			cs: getUrlParameter('utm_source'),
			cm: getUrlParameter('utm_medium'),
			ck: getUrlParameter('utm_term'),
			cc: getUrlParameter('utm_content'),
			ci: getUrlParameter('utm_id')
		},

		pageview: function(path){
			return {t: 'pageview', dp: path, cd: path}
		},

		event: function(){
			return {t: 'event', ec: Analytics.ga_event_category, ea: Analytics.ga_event_action, el: Analytics.ga_event_label, ni: 1}
		},

	},

	ga_assemble: function(property,hittype,payload){
		let params = {...{ tid: property}, ...this.ga_mp.universal, ...this.ga_mp.utm};
		if(this.ga_event_category === 'app')
			params = {...params, ...this.ga_mp.app};
		else
			params = {...params, ...this.ga_mp.website};
		if(hittype === 'pageview')
			params = {...params, ...this.ga_mp.pageview(payload)};
		else if(hittype === 'event')
			params = {...params, ...this.ga_mp.event()};

		let string = "";
		for (const [key, value] of Object.entries(params)){
			if(value && value !== ""){
				string += (key+"="+escape(value)+"&");
			}
		}
		string += ("z="+Date.now());
		this.ga_post(string);
	},
	ga_post: function(string){
		axios.post('https://www.google-analytics.com/collect',string)
	},
	ga_initialize: function(){
		this.ga_initialized = true;
	},
	ga_set_utm: function(utm){
		let new_utm = {...this.ga_mp.utm, ...utm};
		this.ga_mp.utm = new_utm;
	},
	ga_event: function(){
		this.ga_event_logged=true;
		for(let i=0; i<this.ga_trackers.length; i++)
			this.ga_assemble(this.ga_trackers[i],'event');
	},
	ga_log: function(path){
		if(!this.ga_initialized)
			this.ga_initialize(path);

		if(this.ga_trackers){
			for(let i=0; i<this.ga_trackers.length; i++){
				this.ga_assemble(this.ga_trackers[i],'pageview',path);
			}
			if(!this.ga_event_logged)
				this.ga_event();
		}
	},

	log: function(path){
		//console.log('log',path);
		if(path){
			this.ga_log(path);
		}
	},
}

export default Analytics;