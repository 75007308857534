import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Icon from '../../elements/Icon.jsx';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    viewport: state.main.viewport,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.initialized,
    header_title: state.main.header_title,
    header_edit: state.main.header_edit,
    has_internet: state.main.has_internet,
    current_experience: state.experience.experience,
  };
}

class PageHeader extends React.Component {

  goBack(e){
    e.preventDefault();
    window.history.back()
  }

  render() {

    let checkin_notice = false;

    if(this.props.current_experience){
      let checkin_cutoff = 30;

      // Using Date objects
      let start = (new Date(this.props.current_experience.updated_at));
      let end = Date.now()
      let elapsed = Math.floor( (end - start) / 1000 /60 )// elapsed time in milliseconds
      if(elapsed > checkin_cutoff)
        checkin_notice = true;
    }


    return (
      <header>
        <nav className="navbar navbar-default">
          <div className="container-fluid text-center py-0 d-block">
            <div className="row">
              <div className="col-12 text-center text-white">
              { this.props.header_title

                ?
                  <div className="row">
                    <div className="col-1 text-left px-0 mx-0">
                      <Link className="navbar-brand text-center px-0 text-white" style={{fontSize:"20px"}} to={"#"} onClick={this.goBack.bind(this)}>
                        <Icon icon="chevron-left.svg" title="Go Home" size="28" />
                      </Link>
                    </div>
                    <div className="col-10 text-center px-0 mx-0">
                      <Link className="navbar-brand text-center py-2 px-0 m-0 text-white" to={"/"}>{ this.props.header_title}</Link>
                    </div>
                    <div className="col-1 text-right px-0 mx-0">
                      { this.props.header_edit &&
                        <Link style={{fontSize:"14px"}} to={this.props.header_edit} className="text-white font-weight-light px-0"><i className="fas fa-pen"></i></Link>
                      }
                      &nbsp;
                    </div>
                  </div>
                :

                  <Link className="navbar-brand text-center py-2" to={"/"}><img src={(this.props.cordova_initialized ? "" : "/" )+"images/logo_text.png"} className="brand-logo" alt="Brand Logo" /></Link>
              }
              </div>
            </div>
          </div>
        </nav>
        <div id="internetNotice" className="header_notice">
          No Internet Connection
        </div>
        <div id="activeExperience" className="header_notice">
          { checkin_notice 
            ?
              <Link to="/experience/active">Check In on Your Current Session &gt;</Link>
            :
              <Link to="/experience/active">Currently Tracking Session &gt;</Link>
          }
        </div>
        { (this.props.logged_in_user && !this.props.logged_in_user.email_verified_at && (!this.props.header_title || this.props.header_title.toLowerCase() !== 'verify email') ) &&
          <div id="unverifiedEmail" className="header_notice">
            <Link to={"/account/verify/email"}>Click to verify email address &gt;</Link>
          </div>
        }
      </header>
    );
  }
}

export default connect(mapStateToProps)(PageHeader);
