
var cordovaNotifications = {

	onDeviceReady: function(){
		//console.log('cordovaNotifications deviceready');
		cordovaNotifications.enable();
	},

	enable: function(){


	},

	disable: function(){

	}
	
}

export default cordovaNotifications;