import React from 'react';
import { connect } from 'react-redux';

import { validateInputs } from '../../helpers';

import { addToCollection } from '../../api/collection';

import { actionSetHeaderTitle } from '../../redux/actions/main';

import Loading from '../../elements/Loading.jsx';
import CultivarSelector from '../../elements/cultivar/CultivarSelector.jsx';
import CultivarMeasures from '../../elements/cultivar/CultivarMeasures.jsx';
import CultivarBatch from '../../elements/cultivar/CultivarBatch.jsx';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    device_id: state.main.device_id,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
    forms: state.cultivar.forms,
    strains: state.cultivar.strains,
    measures: state.cultivar.measures,
    collection: state.collection.collection,
  };
}

class CollectionAdd extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      can_submit: false,
      submitting: false,
    }
  }

  add(e){
    e.preventDefault();
    if(this.validate(e)){
      let inputs = {};
      inputs['strain'] = document.getElementById('cultivar_name').value;
      inputs['form_id'] = document.getElementById('cultivar_form_id').value;
      inputs['amount'] = document.getElementById('cultivar_amount').value;
      inputs['measure_id'] = document.getElementById('cultivar_measure_id').value;
      inputs['thc_percent'] = document.getElementById('cultivar_thc_percent').value;
      inputs['cbd_percent'] = document.getElementById('cultivar_cbd_percent').value;
      inputs['brand'] = document.getElementById('cultivar_brand').value;
      inputs['batch'] = document.getElementById('cultivar_batch').value;
      //console.log('add inputs',inputs);
      addToCollection(this,inputs);
    }
  }

  validate(e){
    if(e)
      e.preventDefault();
    let required = ['cultivar_name','cultivar_form_id','cultivar_amount','cultivar_measure_id'];
    return validateInputs(this,required);
  }

  componentDidMount(){
    this.props.dispatch(actionSetHeaderTitle('Add to Collection'));
    Analytics.log('/collection/add');
  }

  render() {
    return (
      <main className="col-12 bg-white">
        { !this.props.forms || !this.props.strains || !this.props.measures || !this.props.collection

          ? 

            <Loading/>

          :

            <form onSubmit={this.add.bind(this)}>
              <br/>
              <CultivarSelector parent={this} hideUnknown={true} />
              <CultivarMeasures parent={this} measures={this.props.measures.collection} measureLabel="How much do you have?" />
              <CultivarBatch />

              <div className="row">
                <div className="col-12">
                  <hr className="wide my-0" />
                </div>
                <div className="col-12 text-center py-4">
                  <button className="btn btn-lg btn-success btn-block text-uppercase" disabled={this.state.submitting || !this.state.can_submit}>{this.state.submitting ? 'please wait...' : 'Save to Collection'}</button>
                </div>
              </div>
            </form>
          }

      </main>
    );
  }
}

export default connect(mapStateToProps)(CollectionAdd);
