import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Calendar from 'react-calendar'

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
  };
}

class Usage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      experienceDates: null,
      experienceChoices: null
    }
  }

  componentDidMount(){
    let that = this;
    let experienceDates = {}
    this.props.experiences.map(
      (experience,i)=>{
        let date = new Date(experience.created_at_offset);
        let index = date.toLocaleDateString();
        if(typeof(experienceDates[index]) === 'undefined')
          experienceDates[index] = [];
        experienceDates[index].push(experience);
        return "";
      }
    )
    that.setState({experienceDates:experienceDates});
  }

  selectExperience(id){
    this.props.parent.props.history.push("/experience/"+id);
  }

  selectDay(date,event){
    let index = date.toLocaleDateString();
//    console.log(index,event.target);
    if(typeof(this.state.experienceDates[index]) !== 'undefined'){
      if(this.state.experienceDates[index].length > 0){
        this.setState({experienceChoices:this.state.experienceDates[index]});
        Analytics.log('/usage/selected_date');
      }
      else{
        this.setState({experienceChoices:null});
        this.props.parent.props.history.push("/experience/"+this.state.experienceDates[index][0].id);
      }
    }
    else
      this.setState({experienceChoices:null});
  }

  dayClass({activeStartDate, date, view}){
    let index = date.toLocaleDateString();
    return typeof(this.state.experienceDates[index]) !== 'undefined' ? 'bg-success' : 'bg-light'
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <h4>Usage Calendar</h4>
          <div className="row">
            <div className="col-12">
              <div className="d-block" style={{position:"relative"}}>
              { this.state.experienceDates &&
                <Calendar
                  view="month"
                  onClickDay={this.selectDay.bind(this)}
                  value={new Date()}
                  calendarType="US"
                  tileClassName={this.dayClass.bind(this)}
                />
              }
              { this.state.experienceChoices &&
                <div className="text-dark mt-3" style={{width:"100%",background:"white"}}>
                { this.state.experienceChoices.map(
                    (experience,i)=>{
                      return (
                        <Link to={"/experience/"+experience.id} key={i} className="bg-light p-1 my-1 d-block">
                          <div className="row">
                          <div className="col-7">
                            { experience.consumed[0].cultivar.strain.name } - { experience.consumed[0].amount * 1 } { experience.consumed[0].measure.measure }
                          </div>
                          <div className="d-flex col-5 mt-1 float-right justify-content-between">
                            { experience.ended_at 

                              ?

                                [1,2,3,4,5].map(
                                    (rating,i) => {  
                                      let width = "100%";
                                      if(Math.ceil(experience.rating)<rating)
                                        width = "0%";
                                      else if(Math.floor(experience.rating)<rating)
                                        width = Math.round(( (experience.rating-rating+1)%1 )*100)+"%";
                                      return (
                                        <div key={i} className={"ranking-box"}>
                                          <div className="rating bg-success" style={{width:width}}></div>
                                        </div>
                                      );
                                    }
                                )

                              :

                                <small className="text-danger">Active Session</small>                              
                            }
                          </div>
                          </div>
                        </Link>
                      );
                    }
                  )
                }
                </div>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(Usage);
