import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'

import { actionSetHeaderTitle } from '../../redux/actions/main';
import { getCultivarImage, getStrain, getMeasure, getForm, getMethod } from '../../helpers';

//import Icon from '../../elements/Icon.jsx';
import Loading from '../../elements/Loading.jsx';
import CultivarHeader from '../../elements/headers/CultivarHeader.jsx';
import ExperienceSubHeader from '../../elements/headers/ExperienceSubHeader.jsx';
//import CultivarMeasures from '../../elements/cultivar/CultivarMeasures.jsx';
//import CultivarBatch from '../../elements/cultivar/CultivarBatch.jsx';
import PairingsModal from '../../elements/experience/PairingsModal.jsx';
//import IntentsModal from '../../elements/experience/IntentsModal.jsx';
import MoodsModal from '../../elements/experience/MoodsModal.jsx';

import { getCultivar } from '../../api/cultivar';
import { getExperienceOptions, createExperience, getCollectionExperience, getCultivarExperience } from '../../api/experience';
//import { actionSetExperience } from '../../redux/actions/experience';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    logged_in_user: state.account.user,
    display_data: state.main.display_data,
    feelings: state.experience.feelings,
    methods: state.experience.methods,
    pairings: state.experience.pairings,
    moods: state.experience.moods,
    intents: state.experience.intents,
    current_experience: state.experience.experience,
    strains: state.cultivar.strains,
    measures: state.cultivar.measures,
    forms: state.cultivar.forms,
    collection: state.collection.collection
  };
}

class ExperienceCreateFromPrevious extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cultivar: null,
      method: null,
      pairings: [],
      intents: null,
      moods: [],
      modal: null,
      strain: null,
      form: null,
      measure: null,
      can_submit: false,
      submitting: false,
      collection: null,
      past_experience: null,
    }

    this.axiosRequest = null; 
  }

  parseCollection(){
    let is_record = false;
    let that = this;
    this.props.collection.map((collection,i)=>{
      if(collection.id === parseInt(that.props.match.params.id) && collection.cultivar){
        that.setState({collection:collection});
        getCultivar(this,collection.cultivar.id);
        getCollectionExperience(that,collection.id);
        is_record = true;
      }
      return true;
    });
    if(!is_record)
      this.props.history.push('/experience/select');
  }

  componentDidMount(){
    if(this.props.current_experience)
      this.props.history.push('/experience/active');

    this.props.dispatch(actionSetHeaderTitle('New Session'));

    Analytics.log('/experience/create/'+this.props.match.params.type);

    if(this.props.match.params.type === 'select'){
      getCultivar(this,this.props.match.params.id);
      getCultivarExperience(this,this.props.match.params.id)
    }
    else if(this.props.match.params.type === 'collection' && this.props.collection)
      this.parseCollection()

    getExperienceOptions(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.current_experience)
      this.props.history.push('/experience/active');

    if(prevProps.collection !== this.props.collection){
      if(this.props.match.params.type === 'collection' && this.props.collection)
        this.parseCollection()
    }

    if( (prevProps.cultivar !== this.props.cultivar || this.props.cultivar) && !this.state.past_experience ){
      if(this.props.match.params.type === 'select')
        getCultivarExperience(this,this.props.match.params.id)
    }

    if(this.state.past_experience && !this.state.intents){
      if(this.state.past_experience.intents.length > 0 ){
        //console.log('this.state.past_experience.intents',this.state.past_experience.intents);
        this.setState({intents:this.state.past_experience.intents})
      }
      else{
        this.setState({intents:[]})        
      }
    }

    if(this.state.past_experience && this.props.measures && this.props.methods && this.props.strains && this.props.forms){
      getMethod(this,this.props.methods,this.state.method,this.state.past_experience.consumed[0].method_id)
      getStrain(this,this.props.strains,this.state.strain,this.state.past_experience.consumed[0].cultivar.strain_id)
      getForm(this,this.props.forms,this.state.form,this.state.past_experience.consumed[0].cultivar.form_id)
      getMeasure(this, this.props.measures.experience, this.state.measure, this.state.past_experience.consumed[0].measure_id, this.state.past_experience.consumed[0].amount)
    }

    if(this.state.past_experience && this.state.past_experience.consumed[0].amount > 0 && this.state.method && this.state.measure && this.state.intents){
      if(!this.state.can_submit){
        this.setState({can_submit:true});
        //console.log('can submit');
      }
    }
    else if(this.state.can_submit){
      this.setState({can_submit:null});
    }

  }

  componentWillUnmount(){
    if(this.ajaxRequest)
      this.ajaxRequest.cancel(); 
  }

  setIntent(id){
    //console.log('setIntent',id);
    for(var i=0;i<this.props.intents.length;i++){
      if(this.props.intents[i].id === id){
        let experience = {...this.state.past_experience};
        experience.intents = []
        experience.intents.push(this.props.intents[i])
        this.setState({past_experience:experience, intents:experience.intents})
      }
    }

  }

  setMethod(id){
    //console.log('setMethod',id);
    for(var i=0;i<this.props.methods.length;i++){
      if(this.props.methods[i].id === id){
        let experience = {...this.state.past_experience};
        experience.consumed[0].method_id = id;
        this.setState({past_experience:experience, method:null})
      }
    }
  }

  setMeasure(id,amount){
    //console.log('setMeasure',id,amount);
    for(var i=0;i<this.props.measures.experience.length;i++){
      if(this.props.measures.experience[i][0] === id){
        let experience = {...this.state.past_experience};
        experience.consumed[0].measure_id = id;
        experience.consumed[0].amount = parseFloat(amount);
        experience.consumed[0].measure = null;
        let that = this;
        this.setState({past_experience:experience, measure:null},function(){
          that.forceUpdate();
        })
      }
    }
  }

  setModal(modal,that){
    //console.log(modal);
    this.setState({modal:modal});
  }

  createExperience(e){
    e.preventDefault();
    if(this.state.submitting)
      return true;
    let data = {
      consumed: this.state.past_experience.consumed[0],
      pairings: this.state.pairings,
      intents: this.state.intents,
      moods: this.state.moods,
      from_collection: (this.props.match.params.type === 'collection' ? 1 : 0),
    }
    //console.log('createExperience',data);
    //return;
    createExperience(this,data);
  }

  render() {

    if( !this.state.cultivar || !this.props.methods || !this.props.collection || !this.state.method || !this.state.past_experience )
      return (<Loading />)

    let that = this;
    /*
    let measure_source = this.state.cultivar;
    if(this.state.collection)
      measure_source = this.state.collection;
    */
    let consumed = this.state.past_experience.consumed[0];
    if(consumed){
      consumed.cultivar.strain = this.state.strain;
      consumed.cultivar.form = this.state.form;
      consumed.measure = this.state.measure;
      consumed.method = this.state.method;
    }

    return (
      <React.Fragment>
        {this.state.modal === 'Pairings' &&
          <PairingsModal parent={this} />
        }
        {this.state.modal === 'Moods' &&
          <MoodsModal parent={this} />
        }
        <main className="col-12 bg-white">


              <React.Fragment>
                <CultivarHeader cultivar={{
                  strain:consumed.cultivar.strain.name,
                  form:consumed.cultivar.form.name,
                  amount:null,
                  image:getCultivarImage(consumed),
                  method:consumed.method,
                  brand:null,
                  batch:null,
                  thc_percent:consumed.cultivar.thc_percent,
                  cbd_percent:consumed.cultivar.cbd_percent,
                  pairings:null,
                }} />

                <ExperienceSubHeader experience={ this.state.past_experience } parent={this} hideMoods={true} />

                <div className="row">
                  <div className="col-12">
                    <hr />
                  </div>
                </div>

                <input type="hidden" id="cultivar_id" value={this.state.cultivar ? this.state.cultivar.id : ""} />
                <input type="hidden" id="cultivar_method_id" value={this.state.method ? this.state.method : ""} />

                <div className="form-group row">
                  <div className="col-12">
                    <h5 className="text-uppercase">Baseline</h5>
                    <p className="small mt-1">Defines levels to get a more accurate reading</p>
                    <div className="setting-option" onClick={this.setModal.bind(this,'Moods')}>
                      <div><i className="fas fa-chevron-right float-right mt-1"></i>
                        Current Mood<br/>
                        { this.state.moods

                          ?

                            this.props.moods.map(
                              (mood,i) => {  
                                if(typeof(that.state.moods[mood.id]) === 'undefined')
                                  return (<span key={i} ></span>);

                                return (
                                  <span className="badge badge-success mx-1" key={i}>{mood.name} </span>
                                );
                              }
                            )

                          :

                            <span>---</span>

                        }
                      </div>
                    </div>
                    <div className="setting-option" onClick={this.setModal.bind(this,'Pairings')}>
                      <div><i className="fas fa-chevron-right float-right mt-1"></i>
                        Pairings<br/>
                        { this.state.pairings

                          ?

                            this.props.pairings.map(
                              (pairing,i) => {  
                                if(typeof(that.state.pairings[pairing.id]) === 'undefined')
                                  return (<span key={i} ></span>);

                                return (
                                  <span className="badge badge-success mx-1" key={i}>{pairing.name} </span>
                                );
                              }
                            )

                          :

                            <span>---</span>

                        }
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="wide" />
                <div className="form-group row">
                  <div className="col-12 text-center py-4">
                    <button onClick={this.createExperience.bind(this)} disabled={this.state.submitting || !this.state.can_submit} className="btn btn-lg btn-success btn-block text-uppercase">{this.state.submitting ? 'please wait...' : 'Begin'}</button>
                  </div>
                </div>
              </React.Fragment>

          <br/>

        </main>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ExperienceCreateFromPrevious);
