import { SET_LOGGEDIN_USER, SET_USER_OPTIONS, SET_NOTIFICATION_TYPES } from '../actions/account';
import { isJsonString } from '../../helpers.js'

const initialState = {
	user: ( isJsonString(localStorage.getItem('logged_in_user')) ? JSON.parse(localStorage.getItem('logged_in_user')) : null),
	options: null,
	notification_types: null,
	api_token: localStorage.getItem('api_token'),
};

export default function(state = initialState, action){
	switch(action.type) {
	    case SET_LOGGEDIN_USER:
			return { ...state, user: action.user };
	    case SET_USER_OPTIONS:
			return { ...state, options: action.options };
	    case SET_NOTIFICATION_TYPES:
			return { ...state, notification_types: action.types };
		default:
			return state;
	}
}