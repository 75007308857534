import axios from 'axios';
import { apiUrl, defaultParams } from '../vars.js';

export const saveHealthData = (health_data,source=null) => {
  //console.log(JSON.stringify(health_data));
  axios.post(apiUrl+"/health/save",{...defaultParams(),health_data:health_data,source:source})
    .then(response => { 
      console.log('saveHealthData',response.data);
    });
}
