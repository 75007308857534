import axios from 'axios';
import { apiUrl, defaultParams } from '../vars.js';

export const getQuestion = (that) => {
    axios.get(apiUrl+"/question/get",{params:{...defaultParams()}})
      .then(response => { 
        //console.log(response.data);
        that.setState({question:response.data});
      });
}

export const saveResponse = (that,answer) => {
    that.setState({submitting:true});
    axios.post(apiUrl+'/question/response',{...defaultParams(), response: answer, question_id: that.state.question.id})
      .then(function(response){
      	let question = that.state.question;
      	question.response = response.data;
      	that.setState({question:question,submitting:false});
      });
}
