import LaravelEcho from 'laravel-echo';
//import Pusher from 'pusher-js';
import { pusherParams } from './vars.js';

var Echo = {

	store: null,
	connection: null,

	makeConnection: ()=>{
		if(!Echo.connection)
			Echo.connection = new LaravelEcho(pusherParams());
	},

	connectUser: (id) => {
		Echo.makeConnection();

		Echo.connection.private('App.User.'+id)
		.listen('.new-message', (data) => {
		  //console.log('app.user',data);
		});
	},

	connectExperience: (id) => {
		Echo.makeConnection();

		Echo.connection.private('App.Experience.'+id)
		.listen('.new-message', (data) => {
		  //console.log('app.experience',data);
		});
	},

	connectGeneral: () => {
		Echo.makeConnection();

		Echo.connection.channel('App.General')
		.listen('.new-message', (data) => {
		  //console.log('app.general',data);
		});
	},

	leave: (channel) => {
		if(Echo.connection)
			Echo.connection.leave(channel)
	}

}

export default Echo;