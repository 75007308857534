import React from 'react';
import { connect } from 'react-redux';

import { actionSetHeaderTitle } from '../../redux/actions/main';
import { validateInputs } from '../../helpers';

import Icon from '../../elements/Icon.jsx';
import Loading from '../../elements/Loading.jsx';

import { getExperienceOptions, updateExperience, getExperience, deleteExperience } from '../../api/experience';
import { getStrain, getMeasure, getForm, getMethod  } from '../../helpers';

import CultivarSelector from '../../elements/cultivar/CultivarSelector.jsx';
import CultivarBatch from '../../elements/cultivar/CultivarBatch.jsx';
import CultivarMeasures from '../../elements/cultivar/CultivarMeasures.jsx';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    logged_in_user: state.account.user,
    display_data: state.main.display_data,
    strains: state.cultivar.strains,
    forms: state.cultivar.forms,
    feelings: state.experience.feelings,
    methods: state.experience.methods,
    pairings: state.experience.pairings,
    moods: state.experience.moods,
    intents: state.experience.intents,
    current_experience: state.experience.experience,
    measures: state.cultivar.measures,
    collection: state.collection.collection
  };
}

class ExperienceEdit extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      experience: null,
      cultivar: null,
      method: null,
      can_submit: false,
      submitting: false,
      delete_submitting:false,
      delete_token:false,
      strain:null,
      measure:null,
      form:null,
      initial_validate:false
    }
  }

  componentDidMount(){
    
    getExperienceOptions(this);
    getExperience(this,this.props.match.params.id,true);

    Analytics.log('/experience/edit');
    this.props.dispatch(actionSetHeaderTitle('Edit Session'));

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.experience !== this.state.experience && this.state.experience)
      this.setState({cultivar:this.state.experience.consumed[0].cultivar})

    if(this.state.experience && this.props.strains && this.props.measures && this.props.forms){
      getMethod(this,this.props.methods,this.state.method,this.state.experience.consumed[0].method_id)
      getStrain(this,this.props.strains,this.state.strain,this.state.experience.consumed[0].cultivar.strain_id)
      getForm(this,this.props.forms,this.state.form,this.state.experience.consumed[0].cultivar.form_id)
      getMeasure(this, this.props.measures.experience, this.state.measure, this.state.experience.consumed[0].measure_id, this.state.experience.consumed[0].amount)
    }

    if(!this.state.initial_validate && this.state.experience && this.state.form && this.state.strain && this.state.measure && this.state.method){
      let that = this;
      this.setState({initial_validate:true},function(){that.validate(null);});
    }

  }

  validate(e){
    if(e)
      e.preventDefault();
    let required = ['cultivar_name','cultivar_form_id','cultivar_amount','cultivar_measure_id'];
    return validateInputs(this,required);
  }

  confirmDelete(confirm,e){
    e.preventDefault();
    //console.log('confirmDelete',confirm);
    if(confirm)
      this.setState({delete_token:this.state.experience.action_token},()=>{ window.scrollTo(0,window.innerHeight) });
    else
      this.setState({delete_token:null});
  }

  deleteExperience(e){
    e.preventDefault();
    //console.log('deleteExperience',this.state.experience);
    deleteExperience(this,this.state.experience.id,this.state.delete_token);
    Analytics.log('/experience/delete');
  }

  updateExperience(e){
    e.preventDefault();

    let data = {
      strain: document.getElementById('cultivar_name').value,
      form_id: document.getElementById('cultivar_form_id').value,
      amount: document.getElementById('cultivar_amount').value,
      measure_id: document.getElementById('cultivar_measure_id').value,
      thc_percent: document.getElementById('cultivar_thc_percent').value,
      cbd_percent: document.getElementById('cultivar_cbd_percent').value,
      brand: document.getElementById('cultivar_brand').value,
      batch: document.getElementById('cultivar_batch').value,
      method_id: this.state.method.id,
//      pairings: this.state.pairings,
//      intents: this.state.intents,
//      moods: this.state.moods,
    }
    //console.log(data);
    if(this.validate(null))
      updateExperience(this,this.state.experience.id,data);
  }

  selectMethod(id){
//    let that = this;
    //console.log('selectMethod',id)
    for(var i=0;i<this.props.methods.length;i++){
      console.log(this.props.methods[i])
      if(this.props.methods[i].id === id){
        //console.log('method found',this.props.methods[i])
        this.setState({method:this.props.methods[i]});
      }
    }
  }

  render() {
    let that = this;
    if(!this.state.strain || !this.state.measure || !this.state.form || !this.state.method)
      return (<Loading/>);

    let consumed = this.state.experience.consumed[0];
    if(consumed){
      consumed.cultivar.strain = this.state.strain;
      consumed.cultivar.form = this.state.form;
      consumed.measure = this.state.measure;
      consumed.method = this.state.method;
    }

    return (
      <React.Fragment>
        <main className="col-12 bg-white">

              <React.Fragment>
                <br/>
                <CultivarSelector parent={this} selectedStrain={consumed.cultivar.strain.name} selectedForm={[consumed.cultivar.form.id, consumed.cultivar.form.name]}  />

                <CultivarMeasures parent={this} measures={this.props.measures.experience} selectedTHC={consumed.thc_percent} selectedCBD={consumed.cbd_percent} selectedAmount={consumed.amount} selectedMeasure={[consumed.measure[0],consumed.measure[1]]} />

                <CultivarBatch parent={this} selectedBrand={consumed.brand ? consumed.brand.name : ""} selectedBatch={consumed.batch ? consumed.batch.identifier : ""} />

                <div className="form-group row px-3">
                  <div className="col-12 text-uppercase mb-1 pl-0">Method*</div>
                  {
                    this.props.methods.map(
                      (method,i) => {  
                        return (
                          <div key={i} className="text-center p-1" onClick={this.selectMethod.bind(this,method.id)}>
                              <Icon icon={method.icon} title={method.name+" Method"} size={48} active={that.state.method && method.id === that.state.method.id} />
                          </div>
                        );
                      }
                    )
                  }
                </div>


                <div className="form-group row">
                  <div className="col-12 text-center py-4">
                      <button onClick={this.updateExperience.bind(this)} disabled={this.state.submitting || !this.state.can_submit} className="btn btn-lg btn-success btn-block text-uppercase">{this.state.submitting ? 'please wait...' : 'Update'}</button>
                  </div>
                </div>

                <hr className="wide" />

                <div className="row">
                  <div className="col-12 text-center py-4">
                    <button onClick={this.confirmDelete.bind(this,true)} className="btn btn-lg btn-outline-danger btn-block text-uppercase" disabled={this.state.delete_token}>{this.state.delete_token ? 'Confirm Delete' : 'Delete Session'}</button>
                  </div>

                  { this.state.delete_token &&
                    <React.Fragment>
                      <div className="col-6 text-center">
                        <button onClick={this.deleteExperience.bind(this)} className="btn btn-lg btn-danger btn-block text-uppercase" disabled={this.state.delete_submitting}>{this.state.delete_submitting ? 'please wait...' : 'Yes, Delete'}</button>
                      </div>
                      <div className="col-6 text-center">
                        <button onClick={this.confirmDelete.bind(this,false)} className="btn btn-lg btn-outline-secondary btn-block text-uppercase">Cancel</button>
                      </div>
                    </React.Fragment>
                  }
                </div>
              </React.Fragment>

        </main>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ExperienceEdit);
