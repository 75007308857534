import axios from 'axios';
import { apiUrl, defaultParams } from '../vars.js';

import { actionSetPushAvailable } from '../redux/actions/cordova.js';

export const savePushToken = (store,token,action) => {
  axios.post(apiUrl+"/account/pushtoken",{...defaultParams(),fcm_token:token})
      .then(function(response){
        //console.log('save push'.response.data);
      });
  store.dispatch(actionSetPushAvailable(action));
}

export const checkContentSync = (data) => {
  axios.get(apiUrl+'/checksync', {params:data})
  .then(function(response){
    //console.log('checkContentSync',response.data);
    if(response.data.syncZipFile !== localStorage.getItem('syncZipFile')){
      navigator.splashscreen.show();
      window.location.href = localStorage.getItem('www_dir')+"boot.html";
    }
  });
}