import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import Loading from '../../elements/Loading.jsx';

import Question from '../../elements/question/Question.jsx';
import ProfileSnapshotHeader from '../../elements/headers/ProfileSnapshotHeader.jsx';
import MyCollection from '../../elements/collection/MyCollection.jsx';

import FirstLaunchModal from '../../elements/home/FirstLaunchModal.jsx';

import { actionSetHeaderTitle } from '../../redux/actions/main';

//import { getExperiences } from '../../api/experience';

import { elapsedTime } from '../../helpers';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
    experiences: state.experience.experiences,
    current_experience: state.experience.experience,
    collection: state.collection.collection,
  };
}

class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      app_been_launched: localStorage.getItem('app_been_launched')
    }
  }

  componentDidMount(){
    this.props.dispatch(actionSetHeaderTitle(null));

    Analytics.log("/");
  }

  render() {
    //console.log(this.props.experiences)
    if(!this.props.logged_in_user)
      return (<Loading />)
    return (
        <main className="col-12 bg-white">

          { typeof(this.props.logged_in_user.profile) !== 'undefined' 
            ?
              <h2 className="mt-4">Hi, { this.props.logged_in_user.profile.first_name }</h2>
            :
              <h2 className="mt-4">Hi</h2>
          }

          <div className="row">
            <div className="col-12 py-1">
              { this.props.experiences && 

                <React.Fragment>
                  { this.props.experiences.length > 0 

                    ?

                      <React.Fragment>
                        { !this.props.current_experience &&
                            <p>It’s been {elapsedTime(this.props.experiences[0].ended_at,null)} since you last tracked a session.</p>
                        }
                        <ProfileSnapshotHeader />
                      </React.Fragment>

                    :

                      <React.Fragment>
                        <p>Start your first session by tapping the <strong>"session"</strong> icon below.</p>
                      </React.Fragment>

                  }
                </React.Fragment>
              }

            </div>
          </div>

          <hr className="wide mt-2" />
          <h4 className="text-uppercase my-3">Your Collection</h4>

          { this.props.collection 

            ?

              <MyCollection collection={this.props.collection} showAdd={true} />

            :
              <Loading />
          }

          <br/>

          { !this.state.app_been_launched &&
            <FirstLaunchModal />
          }

        </main>
    );
  }
}

export default connect(mapStateToProps)(Home);
