import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import ChangePassword from './ChangePassword';
import ChangeEmail from './ChangeEmail';
import ChangeProfile from './ChangeProfile';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    logged_in_user: state.account.user,

  };
}

class ProfileModal extends React.Component {

	constructor(props) {
		super(props);
	    this.closeModal = this.closeModal.bind(this);

		this.state = {
			password_hidden:true,
			error: null,
			fields_populated: false,
		}
	}

	closeModal(){
		this.setState({'error':null});
		this.props.parent.setState({modal:null});
	}

	componentDidMount(){
	    Analytics.log('/account/profile');
	}

	render() {
	    return (
            <div className={"modal-slideout slidingOut"}>
              <div className="modal-guts p-4 text-dark">
                <Link to={"#"} className="float-right" onClick={this.closeModal}>X</Link>
                <h4 className="text-uppercase">Profile</h4>

                <hr/>
                <ChangeEmail parent={this} />
                <hr/>
                <ChangeProfile parent={this} />
                <hr/>
                <ChangePassword parent={this} />

              </div>
            </div>
	    );
	}
}

export default connect(mapStateToProps)(ProfileModal);
