import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'

function mapStateToProps(state) {
  return {

  };
}

class Rating extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    }
  }

  render() {
    let base = 10;
    let counts = [];
    for(var i=1;i<=base;i++)
      counts.push(i);
    return (
      <React.Fragment>
        { 
          counts.map(
              (rating,i) => {  
                let width = "100%";
                let source_rating = this.props.rating;
                if(Math.ceil(source_rating)<rating)
                  width = "0%";
                else if(Math.floor(source_rating)<rating)
                  width = Math.round(( (source_rating-rating+1)%1 )*100)+"%";
                return (
                  <div key={i} className={"ranking-box"} style={{margin:"0 1px"}}>
                    <div className="rating bg-success" style={{width:width}}></div>
                  </div>
                );
              }
          )
        }
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Rating);
