import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { validateInputs, getStrain, getMeasure, getForm } from '../../helpers';

import { updateCollection } from '../../api/collection';

import { actionSetHeaderTitle } from '../../redux/actions/main';
import CultivarSelector from '../../elements/cultivar/CultivarSelector.jsx';
import CultivarMeasures from '../../elements/cultivar/CultivarMeasures.jsx';
import CultivarBatch from '../../elements/cultivar/CultivarBatch.jsx';

import Loading from '../../elements/Loading.jsx';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    device_id: state.main.device_id,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
    strains: state.cultivar.strains,
    forms: state.cultivar.forms,
    measures: state.cultivar.measures,
    collection: state.collection.collection,
  };
}

class CollectionUpdate extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      can_submit: false,
      submitting: false,
      collection: null,
      strain:null,
      measure:null,
      form:null,
      initial_validate:false
    }

    this.required = ['cultivar_name','cultivar_form_id','cultivar_amount','cultivar_measure_id'];

  }

  update(e){
    e.preventDefault();
    if(this.validate(e)){
      let inputs = {};
      inputs['strain'] = document.getElementById('cultivar_name').value;
      inputs['form_id'] = document.getElementById('cultivar_form_id').value;
      inputs['amount'] = document.getElementById('cultivar_amount').value;
      inputs['measure_id'] = document.getElementById('cultivar_measure_id').value;
      inputs['thc_percent'] = document.getElementById('cultivar_thc_percent').value;
      inputs['cbd_percent'] = document.getElementById('cultivar_cbd_percent').value;
      inputs['batch'] = document.getElementById('cultivar_batch').value;
      inputs['brand'] = document.getElementById('cultivar_brand').value;
      //console.log('update inputs',inputs);
      updateCollection(this,this.props.match.params.id,inputs);
    }
  }

  validate(e){
    if(e)
      e.preventDefault();
    return validateInputs(this,this.required,true);
  }

  componentDidMount(){
    let that = this;

    if(!this.props.collection){
      this.props.history.push('/collection/'+that.props.match.params.id);
    }
    else{
      this.props.dispatch(actionSetHeaderTitle('Update Collection'));
      this.props.collection.map((collection,i)=>{
        if(collection.id === parseInt(that.props.match.params.id)){
          //console.log(collection);
          that.setState({collection:collection});
          Analytics.log('/collection/edit');
        }
        return true;
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let that = this;

    if(this.state.collection && this.props.measures){
      getStrain(this,this.props.strains,this.state.strain,this.state.collection.cultivar.strain_id)
      getMeasure(this, this.props.measures.collection, this.state.measure, this.state.collection.measure_id, this.state.collection.amount)
      getForm(this,this.props.forms,this.state.form,this.state.collection.cultivar.form_id)
    }

    if(!this.state.initial_validate && this.state.collection && this.state.form && this.state.strain && this.state.measure)
      this.setState({initial_validate:true},function(){that.validate(null);});

  }

  render() {
    let collection = this.state.collection;
    return (
      <main className="col-12 bg-white">
        { !collection || !this.state.strain || !this.state.measure || !this.state.form

          ? 

            <Loading/>

          :

            <form onSubmit={this.update.bind(this)}>
              <br/>
              <CultivarSelector parent={this} selectedStrain={this.state.strain.name} selectedForm={[this.state.form.id,this.state.form.name]} hideUnknown={true} />
              <CultivarMeasures parent={this} measures={this.props.measures.collection} measureLabel="How much do you have?" selectedTHC={collection.thc_percent} selectedCBD={collection.cbd_percent} selectedAmount={collection.amount} selectedMeasure={this.state.measure} />
              <CultivarBatch selectedBatch={collection.batch ? collection.batch.identifier : null} selectedBrand={collection.brand ? collection.brand.name : null} />

              <div className="row">
                <div className="col-12">
                  <hr className="wide my-0" />
                </div>
                <div className="col-12 text-center py-4">
                  <button className="btn btn-lg btn-success btn-block text-uppercase" disabled={this.state.submitting || !this.state.can_submit}>{this.state.submitting ? 'please wait...' : 'Update Collection'}</button>
                  <Link to={"/collection/"+this.props.match.params.id} className="btn btn-lg btn-outline-secondary btn-block text-uppercase">cancel</Link>
                </div>
              </div>
            </form>
          }

      </main>
    );
  }
}

export default connect(mapStateToProps)(CollectionUpdate);
