import axios from 'axios';
import { apiUrl, defaultParams } from '../vars.js';

export const saveFeedback = (that,data) => {
    axios.post(apiUrl+"/feedback",{...defaultParams(),...data})
      .then(response => { 
        //console.log(response.data)
        if(response.data.success)
          that.closeModal();
      });
}

export const getFeedback = (that,data) => {

}
