import { combineReducers } from 'redux';

// import each individual reducer file
import main from './reducers/main';
import cordova from './reducers/cordova';
import account from './reducers/account';
import experience from './reducers/experience';
import collection from './reducers/collection';
import cultivar from './reducers/cultivar';

// compile all the reducers together
export default combineReducers({
  main,
  cordova,
  account,
  experience,
  collection,
  cultivar
});