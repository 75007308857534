import React from 'react';
import { connect } from 'react-redux';
import { actionSetFeedbackModal } from '../../redux/actions/main';
import { saveFeedback } from '../../api/feedback';

function mapStateToProps(state) {
  return {
    cordova_initialized: state.cordova.initialized,
  };
}

class FeedbackModal extends React.Component {

  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.state = {
      did_it_work: true,
    }
  }

  didItWorkChanged(e){
    e.preventDefault();
    if(e.target.value === 'yes')
      this.setState({did_it_work:true})
    else
      this.setState({did_it_work:false})
  }

  closeModal(){
    window.jQuery('#feedbackModal').modal('hide');
    this.props.dispatch(actionSetFeedbackModal(false))
  }

  saveFeedback(e){
    e.preventDefault();
    let data = {
      path: this.props.location.pathname,
      feedback: document.getElementById('feedback_notes').value,
      did_it_work: document.getElementById('feedback_did_it_work').value,
      created_at: (new Date()).toISOString()
    }
    saveFeedback(this,data);
  }

  componentDidMount(){
    window.jQuery('#feedbackModal').modal('show');
  }  

  render() {
    return (
        <div className="modal fade" style={{marginTop:"75px"}}  data-backdrop="static" data-keyboard="false" id="feedbackModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title">Feedback</h2>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label className="small">Did this screen seem to work as designed?</label>
                  <select id="feedback_did_it_work" className="form-control" onChange={this.didItWorkChanged.bind(this)}>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                <div className="form-group">
                  { this.state.did_it_work

                    ?
                      <label className="small">What suggestions/feedback do you have?</label> 

                    :
                      <label className="small">What isn't working?</label> 
                  }
                  <textarea id="feedback_notes" className="form-control" placeholder="notes..."></textarea>
                </div>
                <div className="mt-3 text-uppercase small text-right">
                  { ("home"+this.props.location.pathname).replace("/"," > ").replace("/"," > ").replace("/"," > ").replace("/"," > ") }
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-outline-secondary" onClick={this.closeModal.bind(this)}>cancel</button>
                <button type="button" className="btn btn-success" onClick={this.saveFeedback.bind(this)}>Submit</button>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default connect(mapStateToProps)(FeedbackModal);
