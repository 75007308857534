import React from 'react';
import { connect } from 'react-redux';

import {changePassword} from '../../api/account';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    logged_in_user: state.account.user
  };
}

class ChangePassword extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			success: null
		}
		this.changed = this.changed.bind(this);
		this.cancelled = this.cancelled.bind(this);
		this.submitted = this.submitted.bind(this);
	}

	componentDidMount(){
		document.getElementById("changePasswordControls").style.display = 'none';
	}

	changed(e){
		if(this.state.error || this.state.success)
			this.setState({error:null,success:null});
		let value1 = document.getElementById('new-password').value;
		let value2 = document.getElementById('new-password-confirm').value;
		if(value1 !== "" && value2 !== "" && value1 === value2)
			document.getElementById("changePasswordControls").style.display = 'block';
		else
			document.getElementById("changePasswordControls").style.display = 'none';
	}

	cancelled(e){
		document.getElementById('new-password').value = "";
		document.getElementById('new-password-confirm').value = "";
		document.getElementById("changePasswordControls").style.display = 'none';
	}

	submitted(e){
		//console.log('submitted',e)
		changePassword(this,document.getElementById('new-password').value,document.getElementById('new-password-confirm').value);
	}

	render() {
	    return (

			<section className="my-account-section well normal">
		        <div className="sub-controller-view">
			        <div className="row">
				        <div className="col-md-6 col-sm-12">
					        <div className="form-group ">
					            <label htmlFor="new-password">Update Password</label>
					            <input type="password" name="password" id="new-password" onChange={this.changed} className="form-control" autoComplete="off" />
					        </div>
					    </div>
					    <div className="col-md-6 col-sm-12">
					        <div className="form-group">
					            <label htmlFor="new-password-confirm">Confirm Password</label>
					            <input type="password" name="password_confirmation" id="new-password-confirm" onChange={this.changed} className="form-control" autoComplete="off" />
					        </div>
					    </div>
		                { this.state.success &&
		                	<div className="col-12">
				                <div className="text-success text-center">
				                	{ this.state.success }
				                </div>
			                </div>
			            }
		                { this.state.error &&
		                	<div className="col-12">
				                <div className="text-danger text-center">
				                	{ this.state.error }
				                </div>
			                </div>
			            }
						<div className="col-sm-12 controls-container" id="changePasswordControls">
				            <button type="button" className="btn btn-danger float-left" id="changePasswordCancel" onClick={this.cancelled}>Cancel</button>
				            <button type="button" className="btn btn-success float-right" id="changePasswordSubmit" onClick={this.submitted}>Update Password</button>
			            </div>
			        </div>
			    </div>
			</section>

	    );
	}
}

export default connect(mapStateToProps)(ChangePassword);
