import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { actionSetHeaderTitle, actionSetHeaderEdit } from '../../redux/actions/main';
//import { actionSetExperience } from '../../redux/actions/experience';

import Loading from '../../elements/Loading.jsx';
import CultivarHeader from '../../elements/headers/CultivarHeader.jsx';
import ExperienceSubHeader from '../../elements/headers/ExperienceSubHeader.jsx';
import FeelingsCirclePack from '../../elements/experience/FeelingsCirclePack.jsx';
import Notes from '../../elements/experience/Notes.jsx';
//import HealthDataChart from '../../elements/experience/HealthDataChart.jsx';

import { getExperienceOptions, createCheckin, endExperience, quickUpdateExperience } from '../../api/experience';

import { formatDate, getCultivarImage, getStrain, getMeasure, getForm, getMethod } from '../../helpers';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    logged_in_user: state.account.user,
    display_data: state.main.display_data,
    strains: state.cultivar.strains,
    measures: state.cultivar.measures,
    forms: state.cultivar.forms,
    feelings: state.experience.feelings,
    methods: state.experience.methods,
    intents: state.experience.intents,
    experiences: state.experience.experiences,
    current_experience: state.experience.experience,
  };
}

class ExperienceActive extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      feeling_id: null,
      feeling_strength: null,
      can_submit: true,
      strain: null,
      form: null,
      measure: null,
      method:null
    }

    this.timeout = null;
  }

  componentDidMount(){
    if(!this.props.current_experience){
      this.props.history.push('/');
      return;
    }

    getExperienceOptions(this);

    Analytics.log('/experience/active');
    this.props.dispatch(actionSetHeaderEdit('/experience/'+this.props.current_experience.id+'/edit'));
    this.props.dispatch(actionSetHeaderTitle('Current Session')); 
    let that = this;
    this.timeout = setInterval(function(){that.forceUpdate()},60000);
  }

  componentWillUnmount(){
    clearInterval(this.timeout);
    this.props.dispatch(actionSetHeaderEdit(null));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // clicked a new ad, or entered the ad view directly and ads array isn't loaded yet
    if(prevProps.current_experience !== this.props.current_experience){
      if(!this.props.current_experience){
        this.props.history.push('/');
        return;
      }
      if(this.props.current_experience){
        this.setState({
          feeling_id: this.props.current_experience.check_ins[this.props.current_experience.check_ins.length-1].feeling_id,
          feeling_strength: this.props.current_experience.check_ins[this.props.current_experience.check_ins.length-1].feeling_strength
        });
      }
      //console.log('current experience update',this.props.current_experience)
    }

    if(this.props.current_experience && this.props.strains && this.props.measures && this.props.forms && this.props.methods){
      getMethod(this,this.props.methods,this.state.method,this.props.current_experience.consumed[0].method_id)
      getStrain(this,this.props.strains,this.state.strain,this.props.current_experience.consumed[0].cultivar.strain_id)
      getForm(this,this.props.forms,this.state.form,this.props.current_experience.consumed[0].cultivar.form_id)
      getMeasure(this, this.props.measures.experience, this.state.measure, this.props.current_experience.consumed[0].measure_id, this.props.current_experience.consumed[0].amount)
    }
  }

  createCheckin(e){
    e.preventDefault();
    let data = {
      feeling_id: this.state.feeling_id,
      feeling_strength: this.state.feeling_strength,
      experience_id: this.props.current_experience.id,
      notes: null
    }
    createCheckin(this,data);
  }


  endExperience(rating,e){
    e.preventDefault();
    let data = {
      rating: rating,
      experience_id: this.props.current_experience.id,
    }
    //console.log('endExperience',data);
    window.jQuery('#endExperienceModal').modal('hide');
    endExperience(this,data);
  }

  setIntent(id){
    //console.log('setIntent',id);
    for(var i=0;i<this.props.intents.length;i++){
      if(this.props.intents[i].id === id){
        let experience = {...this.props.current_experience};
        experience.intents = [this.props.intents[i]]
        quickUpdateExperience(this,experience,{intent_id:this.props.intents[i].id})
      }
    }

  }

  setMethod(id){
    //console.log('setMethod',id);
    for(var i=0;i<this.props.methods.length;i++){
      if(this.props.methods[i].id === id){
        let experience = {...this.props.current_experience};
        experience.consumed[0].method_id = id;
        quickUpdateExperience(this,experience,{method_id:this.props.methods[i].id})
        this.setState({method:null})
      }
    }
  }

  setMeasure(id,amount){
    //console.log('setMeasure',id,amount);
    for(var i=0;i<this.props.measures.experience.length;i++){
      if(this.props.measures.experience[i][0] === id){
        let experience = {...this.props.current_experience};
        experience.consumed[0].measure_id = id;
        experience.consumed[0].amount = amount;
        quickUpdateExperience(this,experience,{measure_id:this.props.measures.experience[i][0],amount:amount})
        this.setState({measure:null})
      }
    }
  }

  render() {
    if(!this.props.current_experience || !this.state.strain || !this.state.measure || !this.state.form || !this.state.method || !this.props.feelings)
      return (<Loading/>);

    let consumed = this.props.current_experience.consumed[0];
    if(consumed){
      consumed.cultivar.strain = this.state.strain;
      consumed.cultivar.form = this.state.form;
      consumed.measure = this.state.measure;
      consumed.method = this.state.method;
    }

    let checkin_cutoff = 30;

    // Using Date objects
    let start = (new Date(this.props.current_experience.updated_at));
    let end = Date.now()
    let elapsed = Math.floor( (end - start) / 1000 /60 )// elapsed time in milliseconds

    let experience = this.props.current_experience
    return (
      <React.Fragment>

            <main className="col-12 bg-white">

              <React.Fragment>
                { elapsed > checkin_cutoff && 1===0 &&

                    <div className="row p-0">
                      <div className="col-12 text-center bg-danger">
                        { this.state.can_submit &&
                          <div>
                            <span>You haven't checked in in over {checkin_cutoff} minutes. Are you done with this session?</span>
                            <Link to={"/experience/finalize"} className="btn btn-wide btn-danger float-right">Yes, End Session</Link>
                          </div>
                        }
                      </div>
                    </div>
                }

                <CultivarHeader cultivar={{
                  strain:consumed.cultivar.strain.name,
                  form:consumed.cultivar.form.name,
                  image:getCultivarImage(consumed),
                  method:consumed.method,
                  brand:(consumed.brand ? consumed.brand.name : null),
                  batch:(consumed.batch ? consumed.batch.identifier : null),
                  thc_percent:consumed.thc_percent,
                  cbd_percent:consumed.cbd_percent,
                }} parent={this} />

                <ExperienceSubHeader experience={experience} parent={this} />

                <div>{formatDate(experience.created_at)}</div>


                  <React.Fragment>
                    <hr className="wide" />
                    <h4 className="text-uppercase text-no-weight">Your Experience</h4>
                    <div className="mb-3">Update how you're feeling throughout your session. Simply touch a bubble to adjust.</div>
                    <FeelingsCirclePack parent={this} feelings={experience.current_feelings} editable={true} />

                    <hr className="wide" />

                    <Notes parent={this} experience={experience} editable={true} intro="Jot down anything memorable as it happens throughout your session" />
                  </React.Fragment>

                <hr className="wide" />

                {

                  elapsed > checkin_cutoff

                  ?

                    <div className="row">
                      <div className="col-12 text-center">
                        { this.state.can_submit &&
                          <div>
                            <h6>You haven't checked in in over {checkin_cutoff} minutes. Are you done with this session?</h6>
                            <Link to={"/experience/finalize"} className="btn btn-block btn-danger text-uppercase">Yes, End Session</Link>
                          </div>
                        }
                      </div>
                    </div>

                  :

                    <div className="row">
                      <div className="col-12 text-center py-4">
                        { this.state.can_submit &&
                          <div>
                            <Link to={"/experience/finalize"} className="btn btn-lg btn-block btn-success text-uppercase">
                              Finish
                            </Link>
                          </div>
                        }
                      </div>
                    </div>

                }

              </React.Fragment>

          <br/>

        </main>


      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(ExperienceActive);
