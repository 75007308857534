
export const baseUrl = (window.location.host.includes('local') ? 'v1.pottracker.local' : 'v1.eighthnote.app');
export const apiUrl  = "https://" + baseUrl + "/api";

export const defaultParams = () => {
	return {
		device_id: localStorage.getItem('device_id'),
		device_hash: localStorage.getItem('device_hash'),
		device_uuid: localStorage.getItem('device_uuid'),
		api_token: localStorage.getItem('api_token'),
		utc_offset: ((new Date()).getTimezoneOffset() / 60)
	}
}

export const pusherParams = () => {
	return {
		broadcaster: 'pusher',
		key: localStorage.getItem('pusher_key'),
		cluster: localStorage.getItem('pusher_cluster'),
		forceTLS: true,
		authEndpoint: apiUrl + "/broadcasting/auth",
		auth: {
		  headers: {
		    Authorization: "Bearer "+defaultParams().api_token,
		  },
		},
	}
}
