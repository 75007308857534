import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { actionSetHeaderTitle } from '../../redux/actions/main';

import { userLogout } from '../../api/account';
import { getExperiences } from '../../api/experience';
import { bindLinks } from '../../helpers';

import Loading from '../../elements/Loading.jsx';
import ProfileModal from '../../elements/account/ProfileModal.jsx';
import NotificationModal from '../../elements/account/NotificationModal.jsx';
import HealthDataModal from '../../elements/account/HealthDataModal.jsx';
import ProfileSnapshotHeader from '../../elements/headers/ProfileSnapshotHeader.jsx';

import MyCollection from '../../elements/collection/MyCollection.jsx';
import Usage from '../../elements/experience/Usage.jsx';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    device_id: state.main.device_id,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.initialized,
    experiences: state.experience.experiences,
    collection: state.collection.collection
  };
}

class Account extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      modal: null,
      logging_out:false
    }

  }

  setModal(modal,that){
    //console.log(modal);
    this.setState({modal:modal});
  }

  logout(e){
    e.preventDefault();
    let logout = "device";
    if(document.getElementById('logoutAll').checked)
      logout = "all";
    console.log(logout);
    this.setState({logging_out:true});
    userLogout(this,logout);
    window.location.hash = "/";
  }

  componentDidMount(){
    this.props.dispatch(actionSetHeaderTitle('Profile'));

    if(!this.props.experiences)
      getExperiences(this);

    if(this.props.cordova_initialized){
      //console.log('will bind links');
      bindLinks();
    }

    Analytics.log('/account');
  }


  render() {
    if(!this.props.logged_in_user)
      return (<Loading />)
    return (
      <React.Fragment>
        {this.state.modal === 'Profile' &&
          <ProfileModal parent={this} />
        }
        {this.state.modal === 'Notification' &&
          <NotificationModal parent={this} />
        }
        {this.state.modal === 'HealthData' &&
          <HealthDataModal parent={this} />
        }
        <main className="col-12 bg-white">
          <br/>
          { typeof(this.props.logged_in_user.profile) !== 'undefined' &&
            <h2>{ this.props.logged_in_user.profile.first_name }</h2>
          }
          <br/>

          <ProfileSnapshotHeader />
          <hr className="wide" />

          { this.props.experiences 
            ?
              <Usage parent={this} experiences={this.props.experiences} />
            :
              <Loading/>
          }
          <hr className="wide" />

          { this.props.collection 

            ?

              <React.Fragment>
                <MyCollection collection={this.props.collection} showAdd={true} />
                <hr className="wide" />
              </React.Fragment>

            :
              <Loading />
          }
          <div className="form-group row">
            <div className="col-12">
              <h3>Settings</h3>
              <div className="setting-option" onClick={this.setModal.bind(this,'Profile')}>
                <div><i className="fas fa-user px-1"></i> Profile <i className="fas fa-chevron-right float-right mt-1"></i></div>
              </div>
              <div className="setting-option" onClick={this.setModal.bind(this,'Notification')}>
                <div><i className="fas fa-bell px-1"></i> Notifications <i className="fas fa-chevron-right float-right mt-1"></i></div>
              </div>
              { this.props.cordova_initialized && 
                <div className="setting-option" onClick={this.setModal.bind(this,'HealthData')}>
                  <div><i className="fab fa-gratipay px-1"></i> Health Data <i className="fas fa-chevron-right float-right mt-1"></i></div>
                </div>
              }
            </div>
          </div>

          <br/><br/>

          <div className="text-center">
            { this.state.logging_out 

              ?
                <div className="text-success">logging out...</div>
              :
                <div>
                  <div className="mb-2"><Link to={"#"} onClick={this.logout.bind(this)} className="text-darkgrey">Logout <i className="fas fa-sign-out-alt"></i></Link></div>
                  <input type="checkbox" id="logoutAll" /> all devices
                </div>
            }
          </div>

          <div className="text-center mt-4 small">
            <a href="https://eighthnote.co/terms-of-service" target="_blank">terms and conditions</a> | <a href="https://eighthnote.co/privacy-policy" target="_blank">privacy policy</a>
          </div>

        </main>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Account);
