import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

//import LoginBox from '../../elements/account/LoginBox.jsx';
import SignupBox from '../../elements/account/SignupBox.jsx';

import { userLogin } from '../../api/account';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.cordova_initialized,
  };
}

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
    this.showPassword = this.showPassword.bind(this);
    this.checkIsPopulated = this.checkIsPopulated.bind(this);
    this.setModal = this.setModal.bind(this);

    this.state={
      password_hidden:true,
      error: null,
      fields_populated: false,
      view: "start",
      modal: null
    }

  }

  setModal(){
    this.setState({modal:'SignUp'});
    //console.log('setModal');
  }

  login(e){
    e.preventDefault();
    let email     = document.getElementById("login_email").value;
    let password  = document.getElementById("login_password").value;
    userLogin(this, email, password);
  }

  showPassword(e){
    e.preventDefault();
    this.setState({password_hidden:(!this.state.password_hidden) });
    
  }

  checkIsPopulated(e){
    if(!this.state.fields_populated){
      let email     = document.getElementById("login_email").value;
      let password  = document.getElementById("login_password").value;
      if(email !== '' && password !== '')
        this.setState({fields_populated:true});     
    }
    else if(this.state.fields_populated){
      let email     = document.getElementById("login_email").value;
      let password  = document.getElementById("login_password").value;
      if(email === '' || password === '')
        this.setState({fields_populated:false});      
    }
  }

  componentDidMount(){

  }

  render() {
    return (
      <React.Fragment>
        {this.state.modal === 'SignUp' &&
          <SignupBox parent={this} />
        }
        <main className="col-12 col-md-8 offset-md-2">
          <br/><br/><br/><br/>

          <div className="row">
            <div className="col-12 col-md-8 offset-md-2">
              <form method="POST" onSubmit={this.login}>
                <div className="form-group">
                  <label htmlFor="login_email">Email</label>
                  <div className="input-group">
                    <input id="login_email" ref="login_email" name="email" type="email" className="form-control" required="" autoFocus onKeyUp={this.checkIsPopulated} />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="login_password">Password</label>
                  <div className="input-group">
                    <input name="password" ref="password" id="login_password" type={this.state.password_hidden ? "password" : "text"} className="form-control" required="" style={{border:'none'}} onKeyUp={this.checkIsPopulated} />
                    <div className="input-group-append" style={{border:'none'}}>
                      <span className="input-group-text bg-white" style={{border:'none'}}><Link to={"#"} onClick={this.showPassword} title="show/hide password" className="showPassword">{this.state.password_hidden ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}</Link></span>
                    </div>
                  </div>
                </div>
                { this.state.error &&
                    <div className="alert alert-danger">
                      { this.state.error }
                    </div>
                }
                <div className="py-4">
                  <input className="btn btn-success btn-block text-uppercase" disabled={this.state.fields_populated !== true} type="submit" value="Login" />
                </div>
                <div className="py-0 text-uppercase">
                  <Link to="/password/email-request" className="text-light d-block text-center my-2">Forgot Password</Link>
                  <Link to={'#'} onClick={this.setModal} className="text-light d-block text-center my-2">Sign Up</Link>
                </div>
              </form>
            </div>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(Login);
