import React from 'react';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom'

import { actionSetHeaderTitle } from '../../redux/actions/main';
import { validateInputs } from '../../helpers';

import Loading from '../../elements/Loading.jsx';
import MyCollection from '../../elements/collection/MyCollection.jsx';
import CultivarSelector from '../../elements/cultivar/CultivarSelector.jsx';

import { getCultivarOptions, getCultivar, searchCultivar } from '../../api/cultivar';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    logged_in_user: state.account.user,
    display_data: state.main.display_data,
    strains: state.cultivar.strains,
    forms: state.cultivar.forms,
    current_experience: state.experience.experience,
    collection: state.collection.collection
  };
}

class ExperienceSelect extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      cultivar_set: false,
      can_submit: false,
      submitting: false
    }

    this.axiosRequest = null; 
  }


  componentDidMount(){
    if(this.props.current_experience)
      this.props.history.push('/experience/active');

    getCultivar(this,this.props.match.params.id);
    getCultivarOptions(this);

    this.props.dispatch(actionSetHeaderTitle('New Session'));

    Analytics.log('/experience/select');
  }

  componentDidUpdate(){
    if(this.props.current_experience)
      this.props.history.push('/experience/active');
  }

  componentWillUnmount(){
    if(this.ajaxRequest)
      this.ajaxRequest.cancel(); 
  }

  validate(e){
    if(e)
      e.preventDefault();
    let required = ['cultivar_name','cultivar_form_id'];
    return validateInputs(this,required);
  }

  addCultivar(e){
    //console.log('addCultivar');
    e.preventDefault();
    if(this.validate(e)){
      let strain = document.getElementById('cultivar_name').value;
      let form_id = document.getElementById('cultivar_form_id').value;
      if(strain !== "" && form_id && parseInt(form_id) > 0 )
        searchCultivar(this,strain,form_id);
    }
  }

  render() {
      return (
        <main className="col-12 bg-white">
            <br/>
            <form onSubmit={this.addCultivar.bind(this)}>
              <div className="row">
                <div className="col-12">
                    <p  className="mb-4 mb-0">Select from your collection or search by keyword</p>
                    <CultivarSelector parent={this} />
                </div>

                { this.state.can_submit &&
                  <div className="col-12 text-center py-0">
                    <button onClick={this.addCultivar.bind(this)} disabled={this.state.submitting || !this.state.can_submit} className="btn btn-lg btn-success btn-block text-uppercase">{this.state.submitting ? 'please wait...' : 'Next Step'} <i className="fas fa-chevron-right"></i></button>
                  </div>
                }
              </div>
            </form>

          <hr className="wide" />
          <h4 className="text-uppercase my-3">Your Collection</h4>
          { this.props.collection 

            ?

              <MyCollection collection={this.props.collection} showAdd={false} />

            :
              <Loading />
          }

        </main>
      );
    }

}

export default connect(mapStateToProps)(ExperienceSelect);
