import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import { actionSetHeaderTitle } from '../../redux/actions/main';

import { verifyEmail, getVerificationEmail, checkApiToken } from '../../api/account';

import Loading from '../../elements/Loading.jsx';

import Analytics from '../../analytics';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    device_id: state.main.device_id,
    logged_in_user: state.account.user,
    cordova_initialized: state.cordova.initialized,
    experiences: state.experience.experiences,
    collection: state.collection.collection
  };
}

class VerifyEmail extends React.Component {

  constructor(props) {
    super(props);

    this.state={
      verification_sent: false,
      verifying: false,
      verified: false,
      success: null,
      error: null,
    }

    this.timeout = null;

  }

  sendVerification(e){
    e.preventDefault();
    //console.log('sendVerification');
    getVerificationEmail(this);
  }

  componentDidMount(){
    this.props.dispatch(actionSetHeaderTitle('Verify Email'));
    if(this.props.match.params.id && this.props.match.params.hash && (!this.props.logged_in_user || !this.props.logged_in_user.email_verified_at))
      verifyEmail(this);
    if(this.props.logged_in_user && this.props.logged_in_user.email_verified_at)
      this.setState({success:'Email verified'})
    Analytics.log('/verify/email');
    if(this.props.logged_in_user)
      checkApiToken(this.props,false);
    let that = this;
    if(this.timeout)
      clearInterval(this.timeout);
    if(this.props.logged_in_user){
      this.timeout = setInterval(function(){
        if(that.props.logged_in_user && that.props.logged_in_user.email_verified_at)
          clearInterval(that.timeout);
        checkApiToken(that.props,false)
      }, 10000);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.logged_in_user!==this.props.logged_in_user && this.props.logged_in_user && this.props.logged_in_user.email_verified_at)
      this.setState({success:'Email verified'})
    if(prevProps.logged_in_user!==this.props.logged_in_user && (!this.props.logged_in_user || !this.props.logged_in_user.email_verified_at) )
      this.setState({success:null})
  }

  componentWillUnmount(){
    clearInterval(this.timeout);
  }


  render() {
    return (
      <React.Fragment>
        <main className="col-12">
          <br/><br/><br/>
          { !this.state.verified && this.state.verifying
            ?
              <div className="text-center">
                <p className="text-center">Verifying Email</p>
                <Loading />
              </div>
            :
              <div className="text-center">
                { this.state.error &&
                  <p className="alert alert-danger text-uppercase text-white">{ this.state.error }</p>
                }
                { this.state.success 
                  ?
                    <p className="alert alert-success text-uppercase text-black">{ this.state.success }</p>
                  :
                    <div>
                      { this.state.verification_sent
                        ?
                          <div className="my-4">Check your inbox for the verification email.</div>
                        :
                          <div className="my-4">
                            <div className="mb-4">
                              In order to use all the features of the app, you must first have a verified email address.<br/>
                              Click the button below to receive a verification email.
                            </div>
                            <button className="btn btn-success" onClick={this.sendVerification.bind(this)}>Get verification email</button>
                          </div>
                      }
                    </div>
                }
              </div>
          }

        </main>
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(VerifyEmail);
