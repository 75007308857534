import axios from 'axios';
import { apiUrl, defaultParams } from '../vars.js';

import { getUrlParameter } from '../helpers.js';
import { actionSetInitialized, actionDisplayData, actionSetDevice } from '../redux/actions/main';

export const getDisplayData = (that,store) => {
    axios.get(apiUrl+"/display_data",{params:{...defaultParams()}})
      .then(response => { 
        store.dispatch(actionDisplayData(response.data)); 
        that.setState({loaded:true});
      });
}

export const postValidateDevice = (that,store,device) => {
    var data = {
      device: device
    };
    axios.post(apiUrl+'/validate/device',data)
      .then(function(response){
        //console.log('validate response',response.data);
        store.dispatch(actionSetDevice(response.data));
        store.dispatch(actionSetInitialized(true));
        localStorage.setItem('device_id',response.data.id);
        localStorage.setItem('device_hash',response.data.hash);
      });
}


export const checkEmailTracking = (is_app) => {

    let fields = ['user_id','token','notification_id','track_type','content_element'];

    let data = {};

    let has_tracking = true;

    for(var i=0; i<fields.length; i++){
      data[fields[i]] = getUrlParameter(fields[i]);
      if(!data[fields[i]])
        has_tracking = false;
    }

    //console.log('getEmailTracking',data);

    if(has_tracking)
      postNotificationTracking(is_app,data)
}

export const postNotificationTracking = (is_app,data) => {

    //console.log('postNotificationTracking',data);
    axios.post(apiUrl+'/notification/track',{...defaultParams(),...data})
      .then(function(response){
        //console.log('postNotificationTracking response',response.data);
        if(is_app)
          window.location.href=("index.html#"+window.location.hash);
        else
          window.location.href=window.location.pathname;      
      });
}


// https://pottracker.local/?user_id=500001&email=blaine@brikk.co&token=d6e1bd34f41177a4ad6f197beeeb4bf2&notification_id=900001&track_type=test_click&content_element=header-logo
