import React from 'react';
import { connect } from 'react-redux';

import {changeEmail} from '../../api/account';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    logged_in_user: state.account.user
  };
}

class ChangeEmail extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			error:null,
			success:null,
		}
		this.changed = this.changed.bind(this);
		this.cancelled = this.cancelled.bind(this);
		this.submitted = this.submitted.bind(this);
	}

	componentDidMount(){
		document.getElementById("changeEmailControls").style.display = 'none';
		document.getElementById('new-email').value = this.props.logged_in_user.profile.email;
	}

  	componentDidUpdate(prevProps, prevState, snapshot) {
  		if(this.props.logged_in_user !== prevProps.logged_in_user)
			document.getElementById('new-email').value = this.props.logged_in_user.profile.email;
	}

	changed(e){
		if(this.state.error || this.state.success)
			this.setState({error:null,success:null});
		if(e.target.value !== this.props.logged_in_user.profile.email)
			document.getElementById("changeEmailControls").style.display = 'block';
		else
			document.getElementById("changeEmailControls").style.display = 'none';
	}

	cancelled(e){
		document.getElementById('new-email').value = this.props.logged_in_user.profile.email;
		document.getElementById("changeEmailControls").style.display = 'none';
	}

	submitted(e){
		//console.log('submitted',e)
		changeEmail(this,document.getElementById('new-email').value);
	}

	render() {
	    return (

			<section className="my-account-section well">
			    <div className="sub-controller-view text-left">
			        <div className="row">
	                	<div className="col-12">
					        <div className="form-group ">
					            <label htmlFor="new-email">Change Email Address</label>
					            <input type="text" name="email_address" id="new-email" autoComplete="off" onChange={this.changed} className="form-control" />
					        </div>
					    </div>

		                { this.state.success &&
		                	<div className="col-12">
				                <div className="text-success text-center">
				                	{ this.state.success }
				                </div>
			                </div>
			            }
		                { this.state.error &&
		                	<div className="col-12">
				                <div className="text-danger text-center">
				                	{ this.state.error }
				                </div>
			                </div>
			            }

						<div className="col-sm-12 controls-container" id="changeEmailControls">
				            <button type="button" className="btn btn-danger float-left" id="changeEmailCancel" onClick={this.cancelled}>Cancel</button>
				            <button type="button" className="btn btn-success float-right" id="changeEmailSubmit" onClick={this.submitted}>Update Email</button>
				        </div>
					</div>
			    </div>
			</section>

	    );
	}
}

export default connect(mapStateToProps)(ChangeEmail);
