import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
//import ReactBootstrapSlider from 'react-bootstrap-slider';

import CollectionCultivarCard from '../cultivar/CollectionCultivarCard.jsx';
import Icon from '../../elements/Icon.jsx';

function mapStateToProps(state) {
  return {
    measures: state.cultivar.measures,    
  };
}

class MyCollection extends React.Component {

  render() {
    if(!this.props.measures || !this.props.collection)
      return (<React.Fragment></React.Fragment>);

    return (
      <div className="row mx-0">
        { this.props.collection.length > 0

          ?
            this.props.collection.map(
              (collection,i) => {  
                return (
                  <CollectionCultivarCard key={i} collection={collection} />
                );
              }
            )
          :

            <div className="col-12 px-0 text-center text-grey">
              <p className="mb-2 mt-0 text-left">Add any products you have on-hand to your collection.</p>
              <div className="py-4 text-center">
                <Icon icon="collection-jar.svg" title="Go Home" transparent={true} tiny={true} inactive={true} size="180" />
              </div>
            </div>
        }
        { this.props.showAdd &&
          <div className={"col-12 pt-3 text-left px-1"}>
            <Link to={"/collection/add"} className="btn btn-sm btn-success btn-block text-uppercase">+ Add New Product</Link>
          </div>
        }        
      </div>
    );
  }
}

export default connect(mapStateToProps)(MyCollection);
