import { SET_STRAINS, SET_FORMS, SET_MEASURES, SET_CULTIVARS } from '../actions/cultivar';

const initialState = {
	strains: JSON.parse(localStorage.getItem('strains')),
	forms: JSON.parse(localStorage.getItem('forms')),
	measures: JSON.parse(localStorage.getItem('measures')),
	cultivars: JSON.parse(localStorage.getItem('cultivars')),
};

export default function(state = initialState, action){
	switch(action.type) {
	    case SET_STRAINS:
			return { ...state, strains: action.strains };
	    case SET_FORMS:
			return { ...state, forms: action.forms };
	    case SET_MEASURES:
			return { ...state, measures: action.measures };
	    case SET_CULTIVARS:
			return { ...state, cultivars: action.cultivars };
		default:
			return state;
	}
}