export const SET_EXPERIENCE		= "SET_EXPERIENCE";
export const SET_EXPERIENCES	= "SET_EXPERIENCES";
export const SET_FEELINGS		= "SET_FEELINGS";
export const SET_METHODS		= "SET_METHODS";
export const SET_PAIRINGS		= "SET_PAIRINGS";
export const SET_INTENTS		= "SET_INTENTS";
export const SET_MOODS			= "SET_MOODS";

export const actionSetExperience	= experience => ({ type: SET_EXPERIENCE, experience:experience });
export const actionSetExperiences	= experiences => ({ type: SET_EXPERIENCES, experiences:experiences });
export const actionSetFeelings	= feelings => ({ type: SET_FEELINGS, feelings:feelings });
export const actionSetMethods	= methods => ({ type: SET_METHODS, methods:methods });
export const actionSetPairings	= pairings => ({ type: SET_PAIRINGS, pairings:pairings });
export const actionSetIntents	= intents => ({ type: SET_INTENTS, intents:intents });
export const actionSetMoods		= moods => ({ type: SET_MOODS, moods:moods });

