import React from 'react';
import { connect } from 'react-redux';

import {changeProfile} from '../../api/account';

function mapStateToProps(state) {
  return {
    display_data: state.main.display_data,
    logged_in_user: state.account.user
  };
}

class ChangeProfile extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			error: null,
			success: null
		}
		this.changed = this.changed.bind(this);
		this.cancelled = this.cancelled.bind(this);
		this.submitted = this.submitted.bind(this);
	}

	componentDidMount(){
		document.getElementById("changeProfileControls").style.display = 'none';

		let birthday = this.props.logged_in_user.profile.birth_date.split('-');

		document.getElementById('profile_first_name').value = this.props.logged_in_user.profile.first_name;
		document.getElementById('profile_last_name').value = this.props.logged_in_user.profile.last_name;
		document.getElementById('profile_birth_month').value = birthday[1];
		document.getElementById('profile_birth_day').value = birthday[2];
		document.getElementById('profile_birth_year').value = birthday[0];
	}

  	componentDidUpdate(prevProps, prevState, snapshot) {
  		if(this.props.logged_in_user !== prevProps.logged_in_user)
			document.getElementById('new-email').value = this.props.logged_in_user.profile.email;
	}

	changed(e){
		if(this.state.error || this.state.success)
			this.setState({error:null,success:null});
		let birthday = this.props.logged_in_user.profile.birth_date.split('-');
		if(
			document.getElementById('profile_first_name').value !== this.props.logged_in_user.profile.first_name ||
			document.getElementById('profile_last_name').value !== this.props.logged_in_user.profile.last_name ||
			document.getElementById('profile_birth_month').value !== birthday[1] ||
			document.getElementById('profile_birth_day').value !== birthday[2] ||
			document.getElementById('profile_birth_year').value !== birthday[0]
		)
			document.getElementById("changeProfileControls").style.display = 'block';
		else
			document.getElementById("changeProfileControls").style.display = 'none';
	}

	cancelled(e){
		let birthday = this.props.logged_in_user.profile.birth_date.split('-');

		document.getElementById('profile_first_name').value = this.props.logged_in_user.profile.first_name;
		document.getElementById('profile_last_name').value = this.props.logged_in_user.profile.last_name;
		document.getElementById('profile_birth_month').value = birthday[1];
		document.getElementById('profile_birth_day').value = birthday[2];
		document.getElementById('profile_birth_year').value = birthday[0];
		document.getElementById("changeProfileControls").style.display = 'none';
	}

	submitted(e){
		let data = {
			first_name: document.getElementById('profile_first_name').value,
			last_name: document.getElementById('profile_last_name').value,
			birth_date: (document.getElementById('profile_birth_year').value+"-"+document.getElementById('profile_birth_month').value+"-"+document.getElementById('profile_birth_day').value)
		};
		//console.log('submitted',e);
		changeProfile(this,data);
	}

	render() {
		let years = [];
		let nowyear = (new Date()).getFullYear() - 21;
		let thenyear = nowyear-100;
		for(var i=nowyear;i>=thenyear;i--)
			years.push(i);
	    return (

			<section className="my-account-section well">
			    <div className="sub-controller-view text-left">

	                <div className="row">

	                	<div className="col-12">
			                <div className="form-group">
				                <label htmlFor="profile_first_name">First Name</label>
								<input id="profile_first_name" name="first_name" type="text" className="form-control" required="" autoComplete="no" autoFocus onChange={this.changed} />
			                </div>
			                <div className="form-group">
				                <label htmlFor="profile_last_name">Last Name</label>
								<input id="profile_last_name" name="last_name" type="text" className="form-control" autoComplete="no" required="" onChange={this.changed} />
			                </div>
			            </div>

	                	<div className="col-12">
			                <label htmlFor="profile_birth_month">Birthdate</label>
	    				</div>
	                	<div className="col-4">
			                <div className="form-group">
								<select id="profile_birth_month" name="birth_month" className="form-control" required="" onChange={this.changed} >
									<option>MM</option>
	                          		<option value="01">Jan</option>
	                          		<option value="02">Feb</option>
	                          		<option value="03">Mar</option>
	                          		<option value="04">Apr</option>
	                          		<option value="05">May</option>
	                          		<option value="06">Jun</option>
	                          		<option value="07">Jul</option>
	                          		<option value="08">Aug</option>
	                          		<option value="09">Sep</option>
	                          		<option value="10">Oct</option>
	                          		<option value="11">Nov</option>
	                          		<option value="12">Dec</option>
								</select>
			                </div>
		                </div>
	                	<div className="col-4">
			                <div className="form-group">
								<select id="profile_birth_day" name="birth_day" className="form-control" required="" onChange={this.changed} >
									<option>DD</option>
									{ 
										['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'].map(
					                        (value,i) => {  
					                          	return (
					                          		<option key={i} value={value}>{value}</option>
				                          		)
				                          	}
				                        )
									}
								</select>
			                </div>
		                </div>
	                	<div className="col-4">
			                <div className="form-group">
								<select id="profile_birth_year" name="birth_year" className="form-control" required="" onChange={this.changed} >
									<option>YYYY</option>
									{ 
										years.map(
					                        (value,i) => {  
					                          	return (
					                          		<option key={i} value={value}>{value}</option>
				                          		)
				                          	}
				                        )
									}
								</select>
			                </div>
		                </div>
	                	<div className="col-12 mb-2">
	                		You must be 21+ to use Eighthnote
			            </div>

		                { this.state.success &&
		                	<div className="col-12">
				                <div className="text-success text-center">
				                	{ this.state.success }
				                </div>
			                </div>
			            }
		                { this.state.error &&
		                	<div className="col-12">
				                <div className="text-danger text-center">
				                	{ this.state.error }
				                </div>
			                </div>
			            }

						<div className="col-sm-12 controls-container" id="changeProfileControls">
				            <button type="button" className="btn btn-danger float-left" id="changeProfileCancel" onClick={this.cancelled}>Cancel</button>
				            <button type="button" className="btn btn-success float-right" id="changeProfileSubmit" onClick={this.submitted}>Update Profile</button>
				        </div>
					</div>
			    </div>
			</section>

	    );
	}
}

export default connect(mapStateToProps)(ChangeProfile);
