export const SET_INITIALIZED			= "SET_INITIALIZED";
export const SET_DISPLAY_DATA			= "SET_DISPLAY_DATA";
export const SET_VIEWPORT				= "SET_VIEWPORT";
export const SET_DEVICE					= "SET_DEVICE";
export const SET_NAME					= "SET_NAME";
export const SET_BODY_CLASS				= "SET_BODY_CLASS";
export const SET_HEADER_TITLE			= "SET_HEADER_TITLE";
export const SET_HEADER_EDIT			= "SET_HEADER_EDIT";
export const SET_HAS_INTERNET			= "SET_HAS_INTERNET";
export const SET_NO_SCROLL				= "SET_NO_SCROLL";
export const SET_FEEDBACK_MODAL			= "SET_FEEDBACK_MODAL";

export const actionSetInitialized		= initialized => ({ type: SET_INITIALIZED, initialized:initialized });
export const actionDisplayData			= display_data => ({ type: SET_DISPLAY_DATA, display_data:display_data });
export const actionSetViewport			= viewport => ({ type:SET_VIEWPORT, viewport:viewport });
export const actionSetDevice			= device => ({type:SET_DEVICE, device:device});
export const actionSetName				= name => ({type:SET_NAME, device_name:name});
export const actionSetBodyClass			= body_class => ({type:SET_BODY_CLASS, body_class:body_class});
export const actionSetHeaderTitle		= header_title => ({type:SET_HEADER_TITLE, header_title:header_title});
export const actionSetHeaderEdit		= header_edit => ({type:SET_HEADER_EDIT, header_edit:header_edit});
export const actionSetHasInternet		= has_internet => ({type:SET_HAS_INTERNET, has_internet:has_internet});
export const actionSetNoScroll			= no_scroll => ({type:SET_NO_SCROLL, no_scroll:no_scroll});
export const actionSetFeedbackModal		= feedback_modal => ({type:SET_FEEDBACK_MODAL, feedback_modal:feedback_modal});
