import cordovaApp from './cordovaApp';

import { savePushToken } from '../api/cordova.js';
import { postNotificationTracking } from '../api/main.js';
import { actionSetPushAvailable } from '../redux/actions/cordova.js';

var cordovaPush = {

	onDeviceReady: function(){
		//console.log('cordovaPush deviceready');
		cordovaPush.initialize(true);
	},

	initialize: function(enable=false){
		if(enable){
			if(!cordovaApp.store.getState().cordova.push_available){
				cordovaPush.enable();
			}
		}
		else
			cordovaPush.disable();

	},

	enable: function(){
		window.FirebasePlugin.grantPermission(function(hasPermission){
		  	//console.log("Permission was " + (hasPermission ? "granted" : "denied"));
		});

		window.FirebasePlugin.hasPermission(function(hasPermission){
		  	//console.log("Permission is " + (hasPermission ? "granted" : "denied"));
		});

		window.FirebasePlugin.getToken( 
			function(token){
			  	// save this server-side and use it to push notifications to this device
			  	//console.log('getToken',token);
				savePushToken(cordovaApp.store,token,true);
			},
			function(error){
			  	//console.error('error onTokenRefresh',error);
			  	cordovaApp.store.dispatch(actionSetPushAvailable(false));
			}
		);
		
		window.FirebasePlugin.onTokenRefresh(
			function(token){
			  	// save this server-side and use it to push notifications to this device
			  	//console.log('onTokenRefresh',token);
				savePushToken(cordovaApp.store,token,true);
			},
			function(error){
			  	//console.error('error onTokenRefresh',error);
			}
		);

        window.FirebasePlugin.onMessageReceived(
            function(notification) {
                //console.log('Push notification received',notification);

                let data = {
                	user_id: notification.user_id,
                	token: notification.token,
                	notification_id: notification.notification_id,
                	track_type: "open",
                	content_element: "background"
                }
                if(typeof(notification.tap) !== 'undefined' && notification.tap === 'background'){
	                window.location.hash = notification.redirect;
                }
                else{

                }
            	postNotificationTracking(true,data);
            },
            function(error) {
                //console.error('firebase_error',error);
            }
        );

	},
	disable: function(){
		savePushToken(cordovaApp.store,null,false);
	}
	
}

export default cordovaPush;