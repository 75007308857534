import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Icon from '../../elements/Icon.jsx';
import Rating from '../../elements/Rating.jsx';

import { formatDate } from '../../helpers.js';

function mapStateToProps(state) {
  return {
    methods: state.experience.methods,
    strains: state.cultivar.strains,
  };
}

class CultivarExperiencesList extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      method:null
    }
  }

  componentDidMount(){
  }

  render() {
    if(!this.props.methods || !this.props.strains)
      return ( <React.Fragment></React.Fragment> );

    let that = this;
    let limit = (this.props.limit ? this.props.limit : 10000)
    return (
      <React.Fragment>
        { 
          this.props.experiences.map(
            (experience,i)=>{
              if(i > limit)
                return ("")
              let x;
              let method = null;
              for(x=0;x<that.props.methods.length;x++){
                if(that.props.methods[x].id === experience.consumed[0].method_id){
                  method = that.props.methods[x];
                }
              }

              let strain = null;
              for(x=0;x<that.props.strains.length;x++){
                if(that.props.strains[x].id === experience.consumed[0].cultivar.strain_id){
                  strain = that.props.strains[x];
                }
              }

              return (
                <Link key={i} to={"/experience/"+experience.id}>
                <div className="my-1 row">
                  <div className={this.props.condensed ? "col-6" : "col-7"}>
                    { !experience.ended_at
                      
                      ?
                        <span className="text-success">active session</span>
                      :
                        formatDate(experience.created_at_offset,this.props.condensed) 
                    }
                    { this.props.condensed

                      ?

                        <div className="small"><em>{ strain.name }</em></div>

                      :
                        <div className="row">
                          <div className={"d-flex justify-content-between " +(this.props.condensed ? "col-12" : "col-9")}>
                            <Rating rating={experience.rating} />
                          </div>
                        </div>

                    }
                  </div>
                  <div className={ (this.props.condensed ? "col-5" : "col-4") + " offset-1 text-right"}>
                    { experience.intents.length > 0 &&
                      <div className="d-inline-block ml-1">
                        <Icon icon={experience.intents[0].icon} title={experience.intents[0].name+" Intent"} tiny={true} active={true} size={30} />
                      </div>
                    }
                    <div className="d-inline-block ml-1">
                      <Icon icon={method.icon} title={method.name+" Method"} tiny={true} active={false} size={30} />
                    </div>
                  </div>
                  <div className="col-12 py-0">
                    <hr className="mt-3 mb-2"/>
                  </div>
                </div>
                </Link>
              )
            }
          )
        }
      </React.Fragment>
    );
  }
}

export default connect(mapStateToProps)(CultivarExperiencesList);
