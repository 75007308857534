import { checkContentSync } from '../api/cordova.js';
import { checkApiToken } from '../api/account.js';

import cordovaHealth from './cordovaHealth';
import cordovaPush from './cordovaPush';
import cordovaLinks from './cordovaLinks';
import cordovaGeolocation from './cordovaGeolocation';
import cordovaNetwork from './cordovaNetwork';
import cordovaCamera from './cordovaCamera';
import cordovaNotifications from './cordovaNotifications';

var cordovaApp = {

	that: null,
	store: null,

	onDeviceReady: function(that,store){
		cordovaApp.that = that;
		cordovaApp.store = store;
		//console.log('cordovaApp deviceready');
		window.device.hash = localStorage.getItem('device_hash');
		that.validateDevice(store,window.device);

	    window.open = window.cordova.InAppBrowser.open;

		cordovaLinks.onDeviceReady();
		cordovaHealth.onDeviceReady();
		cordovaPush.onDeviceReady();
		cordovaNetwork.onDeviceReady();
		cordovaGeolocation.onDeviceReady();
		cordovaCamera.onDeviceReady();
		cordovaNotifications.onDeviceReady();

		var date = new Date();
		var timestamp = date.getTime();
		localStorage.setItem('nextcheck',(timestamp+1200000));

		document.addEventListener('resume', this.onResume.bind(this), false);

	    that.setState({loaded:true});
		navigator.splashscreen.hide();
		//console.log("window.device.platform.toLowerCase()",window.device.platform.toLowerCase());
        if(window.device.platform.toLowerCase() == 'ios' && store.getState().account.user && typeof(window.cordova.plugins.idfa) !== 'undefined'){
            const idfaPlugin = window.cordova.plugins.idfa;
            idfaPlugin.getInfo()
                .then(info => {
                    if (!info.trackingLimited) {
                        return info.idfa || info.aaid;
                    } else if (info.trackingPermission === idfaPlugin.TRACKING_PERMISSION_NOT_DETERMINED) {
                        return idfaPlugin.requestPermission().then(result => {
                            if (result === idfaPlugin.TRACKING_PERMISSION_AUTHORIZED) {
                                return idfaPlugin.getInfo().then(info => {
                                    return info.idfa || info.aaid;
                                });
                            }
                        });
                    }
                })
                .then(idfaOrAaid => {
                    if (idfaOrAaid) {
                        //console.log(idfaOrAaid);
                    }
                });
        }

	},

	onResume: function(that,store){
		//console.log('resume');
		checkApiToken(cordovaApp.store,false);
		var date = new Date();
		var timestamp = date.getTime();
		var nextcheck = parseInt(localStorage.getItem('nextcheck'));
		if(!nextcheck || nextcheck < timestamp){
			this.checkContentSync();
		}
		localStorage.setItem('nextcheck',(timestamp+1800000));
	},

	checkContentSync: function(){
        window.cordova.getAppVersion.getVersionNumber(function(pkg_version){
        	var data = {pkg_ver:pkg_version};
        	checkContentSync(data);
        });
	},
	
}

export default cordovaApp;