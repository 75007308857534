import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'

import Analytics from '../../analytics';

import { actionSetUserOptions } from '../../redux/actions/account';
import { saveOption } from '../../api/account';

function mapStateToProps(state) {
  return {
  	notification_types: state.account.notification_types,
  	user_options: state.account.options,
  };
}

class NotificationModal extends React.Component {

	constructor(props) {
		super(props);
	    this.closeModal = this.closeModal.bind(this);

		this.state = {
			password_hidden:true,
			error: null,
			fields_populated: false,
		}
	}

	closeModal(){
		this.setState({'error':null});
		this.props.parent.setState({modal:null});
	}

	setNotification(e){
		this.setState({'error':null});
		e.preventDefault();
	}

	toggleNotificationOption(slug,e){
		e.preventDefault();
		let user_options = {...this.props.user_options};
		user_options[slug] = user_options[slug] == 1 ? 0 : 1;
		//console.log(slug,user_options[slug]);
		saveOption(this,slug,user_options[slug]);
		this.props.dispatch(actionSetUserOptions(user_options));
	}

	componentDidMount(){
	    Analytics.log('/account/notifications');
	}

	render() {
		let that = this;
		//console.log(this.props.notification_types)
	    return (
            <div className={"modal-slideout slidingOut"}>
              <div className="modal-guts p-4 text-dark">
                <Link to={"#"} className="float-right" onClick={this.closeModal}>X</Link>
                <h4 className="text-uppercase">Notifications</h4>
                <br/>
                <h5 className="text-uppercase">Email</h5>
                { this.props.notification_types &&
	                this.props.notification_types.map(
	                	(type,i) => {
	                		if(type.category === 'email')
	                			return (<div key={i} className="my-4">{type.name} <i onClick={that.toggleNotificationOption.bind(that,type.slug)} className={"fas toggle-switch float-right " + (typeof(that.props.user_options[type.slug]) === 'undefined' || that.props.user_options[type.slug] === 0 ? "text-grey fa-toggle-off" : "text-success fa-toggle-on")}></i></div>)
	                		return ('');
	                	}
                	)
                }
                <hr/>
                <h5 className="text-uppercase">Push</h5>
                { this.props.notification_types &&
	                this.props.notification_types.map(
	                	(type,i) => {
	                		if(type.category === 'push')
	                			return (<div key={i} className="my-4">{type.name} <i onClick={that.toggleNotificationOption.bind(that,type.slug)} className={"fas toggle-switch float-right " + (typeof(that.props.user_options[type.slug]) === 'undefined' || that.props.user_options[type.slug] === 0 ? "text-grey fa-toggle-off" : "text-success fa-toggle-on")}></i></div>)
	                		return ('');
	                	}
                	)
                }
              </div>
            </div>
	    );
	}
}

export default connect(mapStateToProps)(NotificationModal);
