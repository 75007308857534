
var cordovaGeolocation = {

	onDeviceReady: function(){
		//console.log('cordovaGeolocation deviceready');
		cordovaGeolocation.enable();
	},

	enable: function(){


	},

	disable: function(){

	}
	
}

export default cordovaGeolocation;