import { SET_EXPERIENCE, SET_EXPERIENCES, SET_FEELINGS, SET_METHODS, SET_PAIRINGS, SET_INTENTS, SET_MOODS } from '../actions/experience';

const initialState = {
	feelings: JSON.parse(localStorage.getItem('feelings')),
	methods: JSON.parse(localStorage.getItem('methods')),
	pairings: JSON.parse(localStorage.getItem('pairings')),
	intents: JSON.parse(localStorage.getItem('intents')),
	moods: JSON.parse(localStorage.getItem('moods')),
	experience: JSON.parse(localStorage.getItem('current_experience')),
	experiences: null,
	total: localStorage.getItem('experiences_total',0),
	streak: localStorage.getItem('day_streak',0),
	unique: localStorage.getItem('unique_cultivars',0),
};

export default function(state = initialState, action){
	switch(action.type) {
	    case SET_FEELINGS:
			return { ...state, feelings: action.feelings };
	    case SET_METHODS:
			return { ...state, methods: action.methods };
	    case SET_PAIRINGS:
			return { ...state, pairings: action.pairings };
	    case SET_INTENTS:
			return { ...state, intents: action.intents };
	    case SET_MOODS:
			return { ...state, moods: action.moods };
	    case SET_EXPERIENCES:
			return { ...state, ...action.experiences, total: action.experiences.experiences.length };
	    case SET_EXPERIENCE:
			return { ...state, experience: action.experience };
		default:
			return state;
	}
}