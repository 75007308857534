import axios from 'axios';
import { apiUrl, defaultParams } from '../vars.js';

import { actionSetStrains, actionSetForms, actionSetMeasures, actionSetCultivars } from '../redux/actions/cultivar';

export const getCultivarOptions = (that) => {
    axios.get(apiUrl+"/cultivars/options",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getCultivarOptions response',response.data);
        localStorage.setItem('measures',JSON.stringify(response.data.measures));
        localStorage.setItem('strains',JSON.stringify(response.data.strains));
        localStorage.setItem('forms',JSON.stringify(response.data.forms));
        that.props.dispatch(actionSetStrains(response.data.strains)); 
        that.props.dispatch(actionSetForms(response.data.forms)); 
        that.props.dispatch(actionSetMeasures(response.data.measures)); 
      });
}

export const getMeasures = (that) => {
    axios.get(apiUrl+"/cultivar/measures",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getMeasures',response.data);
        localStorage.setItem('measures',JSON.stringify(response.data));
        that.props.dispatch(actionSetMeasures(response.data)); 
      });
}

export const getStrains = (that) => {
    axios.get(apiUrl+"/cultivar/strains",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getStrains',response.data);
        localStorage.setItem('strains',JSON.stringify(response.data));
        that.props.dispatch(actionSetStrains(response.data)); 
      });
}

export const getForms = (that) => {
    axios.get(apiUrl+"/cultivar/forms",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getForms',response.data);
        localStorage.setItem('forms',JSON.stringify(response.data));
        that.props.dispatch(actionSetForms(response.data)); 
      });
}

export const getCultivars = (that) => {
    axios.get(apiUrl+"/cultivars/get",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getCultivars',response.data);
        localStorage.setItem('cultivars',JSON.stringify(response.data));
        that.props.dispatch(actionSetCultivars(response.data)); 
      });
}

export const getCultivar = (that,id) => {
  //console.log('getCultivar',id);
    axios.get(apiUrl+"/cultivar/"+id,{params:{...defaultParams()}})
      .then(response => { 
        //console.log('getCultivar',response.data);
        if(response.data)
          that.setState({cultivar:response.data}); 
        else
          that.props.history.push('/experience/create');
      });
}

export const searchCultivar = (that,strain,form_id) => {
    //console.log('searchCultivar',strain,form_id);
    axios.get(apiUrl+"/cultivar/search",{params:{...defaultParams(),strain:strain,form_id:form_id}})
      .then(response => { 
        //console.log('searchCultivar',response.data);
        if(response.data){
          localStorage.setItem('strains',JSON.stringify(response.data.strains));
          that.props.dispatch(actionSetStrains(response.data.strains))
          that.props.history.push('/experience/create/select/'+response.data.cultivar.id);
        }
      });
}

export const topCultivars = (that) => {
    axios.get(apiUrl+"/cultivars/top",{params:{...defaultParams()}})
      .then(response => { 
        //console.log('topCultivars',response.data);
        that.setState({cultivars:response.data});
      });
}
