//Components
import Start from './pages/home/Start.jsx';
import Home from './pages/home/Home.jsx';
import Login from './pages/account/Login.jsx';
import ExperienceSelect from './pages/experience/ExperienceSelect.jsx';
//import ExperienceCreate from './pages/experience/ExperienceCreate.jsx';
import ExperienceCreateFromPrevious from './pages/experience/ExperienceCreateFromPrevious.jsx';
import ExperienceActive from './pages/experience/ExperienceActive.jsx';
import ExperienceEdit from './pages/experience/ExperienceEdit.jsx';
import ExperienceFinalize from './pages/experience/ExperienceFinalize.jsx';
import ExperienceView from './pages/experience/ExperienceView.jsx';
import ExperienceShare from './pages/experience/ExperienceView.jsx';
import CollectionAdd from './pages/collection/CollectionAdd.jsx';
import CollectionView from './pages/collection/CollectionView.jsx';
import CollectionUpdate from './pages/collection/CollectionUpdate.jsx';
import Account from './pages/account/Account.jsx';
import Explore from './pages/explore/Explore.jsx';
import ForgotPassword from './pages/account/ForgotPassword.jsx';
import VerifyEmail from './pages/account/VerifyEmail.jsx';

var appRoutes = [
	{path: '/explore', name: 'Explore', component: Explore, logged_in:true},
	{path: '/account', name: 'Account', component: Account, logged_in:true},
	{path: '/password/email-request', name: 'Forgot Password', component: ForgotPassword, logged_in:false},
	{path: '/collection/add', name: 'CollectionAdd', component: CollectionAdd, logged_in:true},
	{path: '/collection/:id/edit', name: 'CollectionUpdate', component: CollectionUpdate, logged_in:true},
	{path: '/collection/:id', name: 'CollectionView', component: CollectionView, logged_in:true},
	{path: '/experience/create/:type/:id', name: 'Experience Create w ID', component: ExperienceCreateFromPrevious, logged_in:true},
	{path: '/experience/create', name: 'Experience Select', component: ExperienceSelect, logged_in:true},
	{path: '/experience/active', name: 'Experience Active', component: ExperienceActive, logged_in:true},
	{path: '/experience/:id/edit', name: 'Experience Edit', component: ExperienceEdit, logged_in:true},
	{path: '/experience/finalize', name: 'Experience Finalize', component: ExperienceFinalize, logged_in:true},
	{path: '/experience/share/:id/:hash', name: 'Experience Share', component: ExperienceShare, logged_in:true},
	{path: '/experience/:id', name: 'Experience View', component: ExperienceView, logged_in:true},
	{path: '/account/verify/email/:id/:hash', name: 'VerifyEmail', component: VerifyEmail, logged_in:false},
	{path: '/account/verify/email/:id/:hash', name: 'VerifyEmail', component: VerifyEmail, logged_in:true},
	{path: '/account/verify/email', name: 'VerifyEmail', component: VerifyEmail, logged_in:true},
	{path: '/login', name: 'Login', component: Login, logged_in:false},
	{path: '/', name: 'Start', component: Start, logged_in:false},
	{path: '/', name: 'Home', component: Home, logged_in:true},
];

export default appRoutes;