import cordovaApp from './cordovaApp';
import { saveHealthData } from '../api/health.js';
import { actionSetHealthAvailable } from '../redux/actions/cordova.js';

var cordovaHealth = {

	data: null,
	saveTimeout: null,
	updateTimeout: null,

	onDeviceReady: function(){
		cordovaHealth.isAvailable();
	},

	isAvailable: function(){
	    navigator.health.isAvailable(
			function(available){
			    cordovaApp.store.dispatch(actionSetHealthAvailable(available));
			}, 
			function(error){
			    cordovaApp.store.dispatch(actionSetHealthAvailable(false));
			}
		)
	},

	initialize: function(health_sync){
		//console.log('health initialize');

		if(health_sync === null)
			health_sync = cordovaApp.store.getState().account.options['health_sync'];

		if(cordovaHealth.updateTimeout)
			clearTimeout(cordovaHealth.updateTimeout);
		if(cordovaHealth.saveTimeout)
			clearTimeout(cordovaHealth.saveTimeout);

	    navigator.health.isAvailable(
			function(available){
				//console.log('isAvailable success',available);
				if(available){
				    cordovaApp.store.dispatch(actionSetHealthAvailable(true));
				    if(health_sync){
					    cordovaHealth.authorize();
				    }
				}
				else{
				    cordovaApp.store.dispatch(actionSetHealthAvailable(false));
				}
			}, 
			function(error){
				//console.log('isAvailable error',error);
			    cordovaApp.store.dispatch(actionSetHealthAvailable(false));

			    if(window.device.platform.toLowerCase() === 'android'){
			    	navigator.health.promptInstallFit( function(){}, function(error){} );
			    }
			}
		)

	},

	authorize: function(){
		//console.log('health authorize');

		navigator.health.requestAuthorization(
			[
				'calories', 'distance',   // Read and write permissions
				{
					read : ['steps','heart_rate','activity'],       // Read only permission
					write : ['height', 'weight']  // Write only permission
				}
			], 
			function(){
				//console.log('requestAuthorization success');
			    cordovaApp.store.dispatch(actionSetHealthAvailable(true));
				cordovaHealth.update();
			}, 
			function(error){
				//console.log('requestAuthorization error',error)
				if(cordovaHealth.updateTimeout)
					clearTimeout(cordovaHealth.updateTimeout);
				if(cordovaHealth.saveTimeout)
					clearTimeout(cordovaHealth.saveTimeout);
			    cordovaApp.store.dispatch(actionSetHealthAvailable(false));
			}
		);

	},

	update: function(){
		//console.log('cordovaHealth.update()')
		if(cordovaHealth.updateTimeout)
			clearTimeout(cordovaHealth.updateTimeout);
		if(cordovaHealth.saveTimeout)
			clearTimeout(cordovaHealth.saveTimeout);

		let experiences = cordovaApp.store.getState().experience.experiences;
		if(experiences && experiences.length > 0){
			experiences.map(
				(experience,i) => {  
					cordovaHealth.getData(experience);
					return true;
				}
			);
			cordovaHealth.updateTimeout = setTimeout(function(){
				cordovaHealth.update();
			},120000);
		}
		else{
			cordovaHealth.updateTimeout = setTimeout(function(){
				cordovaHealth.update();
			},10000);
		}


	},

	getData: function(experience){
		//console.log('getData');
		let metrics = ['steps','heart_rate','distance','activity'];

		let source = null;
		if(window.device.platform.toLowerCase() === 'android')
			source = 'googlefit';
		else if(window.device.platform.toLowerCase() === 'ios')
			source = 'healthkit';
		let health_data = {};
		metrics.map(
			(metric,i) => {  
				let start_date = new Date(new Date(experience.created_at).getTime() - (30 * 60 * 1000));
				let end_date;
				if(experience.ended_at)
					end_date = new Date(new Date(experience.ended_at).getTime() + (120 * 60 * 1000))
				else
					end_date = new Date(new Date(experience.created_at).getTime() + (240 * 60 * 1000))
				navigator.health.query(
					{
						startDate: start_date, // 30 minutes before experience
						endDate: end_date, // 2 hours after experience
						dataType: metric,
						descending: true,
						limit: 400
					},
					function(data){
						//console.log('query success',metric,data);
						health_data[metric] = data;
					}, 
					function(error){
						//console.log('query error',metric,error);
						health_data[metric] = null;
					}
				);

				return true;
			}
		);

		setTimeout(function(){
			//console.log('health data to be saved',health_data);
			saveHealthData(health_data,source);
		},5000);

	}
	
}

export default cordovaHealth;